import { Route, Switch } from "react-router";
import NotFound from "../NotFound/NotFound";
import AdditionalCategory from "./AdditionalCategory/AdditionalCategory";
import AdditionalCollection from "./AdditionalCollection/AdditionalCollection";
import AdditionalProduct from "./AdditionalProduct/AdditionalProduct";
import AdditionalSearch from "./AdditionalSearch/AdditionalSearch";

export const ADDITIONAL_ROUTES = [
    {
        path: "/additional/search",
        Component: AdditionalSearch,
    },
    {
        path: "/additional/collection/:category/:id",
        Component: AdditionalProduct,
    },
    {
        path: "/additional/collection/:category",
        Component: AdditionalCategory,
    },
    {
        path: "/additional",
        Component: AdditionalCollection,
    },
];

const AditionalProducts = () => {
    return (
        <Switch>
            {ADDITIONAL_ROUTES.map(({ path, Component }, index) => (
                <Route key={path} path={path} component={Component} />
            ))}
            <Route path="*" component={NotFound} />
        </Switch>
    );
};

export default AditionalProducts;

const PaymentCardIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 21.5 15.5"
      {...props}
    >
      <g transform="translate(-1201.25 -396.25)" fill="currentColor">
        <path d="M1220.593 396.25h-17.186a2.16 2.16 0 00-2.157 2.157v11.186a2.16 2.16 0 002.157 2.157h17.186a2.16 2.16 0 002.157-2.157v-11.186a2.16 2.16 0 00-2.157-2.157zm-17.186 1.5h17.186a.658.658 0 01.657.657v1.843h-18.5v-1.843a.658.658 0 01.657-.657zm17.186 12.5h-17.186a.658.658 0 01-.657-.657v-7.843h18.5v7.843a.658.658 0 01-.657.657z" />
        <rect width={3} height={2} rx={0.741} transform="translate(1204 407)" />
      </g>
    </svg>
);

export default PaymentCardIcon;

import Axios from "axios";
import { clearData } from "./utils/functions";

const axios = Axios.create({
    responseType: "json",
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        ...{
            "Content-Type": "application/json",
        },
    },
});

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response?.status === 401) {
            clearData();
            window.location.href = '/link-inactive';
        }

        if (error?.response?.status === 503 && error?.response?.data === 'Temporary unavailable') {
            window.location.href = '/maintenance';
        }

        return Promise.reject(error);
    }
);
export { axios };

const ErrorIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 101 101">
    <g id="Group_1353" data-name="Group 1353" transform="translate(-916 -467)">
      <g id="Ellipse_378" data-name="Ellipse 378" transform="translate(916 467)" fill="none" stroke="#009cd8" strokeWidth="3">
        <circle cx="50.5" cy="50.5" r="50.5" stroke="none" />
        <circle cx="50.5" cy="50.5" r="49" fill="none" />
      </g>
      <g id="Check" transform="translate(936.778 498.448)">
        <path id="Path_871" data-name="Path 871" d="M10.948,41.1,32.381,19.67,49.051,3" transform="translate(-1 -3)" fill="none" stroke="#009cd8" strokeLinecap="round" strokeLinejoin="round" stroke-miterlimit="10" strokeWidth="3" />
      </g>
      <g id="Check-2" data-name="Check" transform="translate(937.052 498)">
        <path id="Path_871-2" data-name="Path 871" d="M10.948,41.1,32.381,19.67,49.051,3" transform="translate(-1 -3)" fill="none" stroke="#009cd8" strokeLinecap="round" strokeLinejoin="round" stroke-miterlimit="10" strokeWidth="3" />
      </g>
      <g id="Check-3" data-name="Check" transform="translate(947 498)">
        <path id="Path_871-3" data-name="Path 871" d="M49.051,41.1,27.618,19.67,10.948,3" transform="translate(-10.948 -3)" fill="none" stroke="#009cd8" strokeLinecap="round" strokeLinejoin="round" stroke-miterlimit="10" strokeWidth="3" />
      </g>
    </g>
  </svg>
);

export default ErrorIcon;

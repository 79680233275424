import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { useHistory } from "react-router";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import Loader from "./components/Loader/Loader.jsx";
import { Routes } from "./Routes.jsx";
import { persistor, store } from "./store.js";
import { GlobalStyle, theme } from "./theme.js";
import NotFound from "./views/NotFound/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { selectMobile, setMobile } from "./features/appSlice.js";
import { MOBILE_WIDTH } from "./variables.js";
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_CODE
}

const GA_CONFIG = {
    trackingId: process.env.REACT_APP_GA_CODE
};

function ScrollToTop() {
    const history = useHistory();
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}

function CheckDevice() {
    const resizeListener = () => {
        const isMobile = selectMobile(store.getState());
        const _isMobile = window.innerWidth < MOBILE_WIDTH;
        if (_isMobile !== isMobile) {
            store.dispatch(setMobile(_isMobile));
        }
    };
    window.addEventListener("resize", resizeListener);

    return () => {
        window.removeEventListener("resize", resizeListener);
    };
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const TRUSTED_SHOPS_SCRIPT = (id) => `
 (function () { 
    var _tsid = '${id}'; 
    _tsConfig = { 
      'yOffset': '0', /* offset from page bottom */
      'variant': 'reviews', /* default, reviews, custom, custom_reviews */
      'customElementId': '', /* required for variants custom and custom_reviews */
      'trustcardDirection': '', /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */
      'customBadgeWidth': '', /* for custom variants: 40 - 90 (in pixels) */
      'customBadgeHeight': '', /* for custom variants: 40 - 90 (in pixels) */
      'disableResponsive': 'false', /* deactivate responsive behaviour */
      'disableTrustbadge': 'false' /* deactivate trustbadge */
    };
    var _ts = document.createElement('script');
    _ts.type = 'text/javascript'; 
    _ts.charset = 'utf-8'; 
    _ts.async = true; 
    _ts.src = '//widgets.trustedshops.com/js/' + _tsid + '.js'; 
    var __ts = document.getElementsByTagName('script')[0];
    __ts.parentNode.insertBefore(_ts, __ts);
  })();
`;

function App() {
    useEffect(CheckDevice, []);
    useEffect(() => { initReactGA(); return destroyTrustedShops(initTrustedShops()) }, []);

    const initReactGA = () => {

        if (process.env.REACT_APP_DEV_MODE === 'false') {
            ReactGA.initialize(GA_CONFIG);
            ReactGA.pageview(window.location.pathname + window.location.search);
            TagManager.initialize(tagManagerArgs);
        }

    };

    const initTrustedShops = () => {
        const script = document.createElement('script');
        script.innerText = TRUSTED_SHOPS_SCRIPT(process.env.REACT_APP_TRUSTED_SHOPS_ID);
        script.async = true;
        document.body.appendChild(script);
        return script;
    };

    const destroyTrustedShops = (script) => {
        document.body.removeChild(script);
    };


    return (
        <>
            <Router>
                <ScrollToTop />
                {/* <ScreenSizeListener /> */}
                <QueryClientProvider client={queryClient}>
                    <Provider store={store}>
                        <PersistGate
                            loading={<Loader fixed />}
                            persistor={persistor}
                        >
                            <ThemeProvider theme={theme}>
                                <GlobalStyle />
                                <Switch>
                                    {Routes.map(({ path, Component, ...rest }) => (
                                        <Route
                                            key={path}
                                            path={path}
                                            children={Component}
                                            {...rest}
                                        ></Route>
                                    ))}
                                    <Route path="*" component={NotFound}></Route>
                                </Switch>
                                <ToastContainer />
                            </ThemeProvider>
                        </PersistGate>
                    </Provider>
                </QueryClientProvider>
            </Router>
            <div id="portal"></div>
        </>
    );
}

export default App;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Buttons/Button";
import Container from "../../components/Container/Container";
import BackArrowIcon from "../../components/Icons/components/BackArrowIcon";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import LoadMore from "../../components/LoadMore/LoadMore";
import ProductBox from "../../components/ProductBox/ProductBox";
import SearchBar from "../../components/SearchBar/SearchBar";
import SearchNotFound from "../../components/SearchNotFound/SearchNotFound";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import CompanyToggle from "../../connectedComponents/CompanyToggle/CompanyToggle";
import {
    selectIsCompany, selectMainProduct,
    selectMinimalMonthsParsed,
    selectShopUuid,
} from "../../features/basketSlice";
import ProductService from "../../services/ProductsService";

/*const TitleContainer = styled.div`
    margin-top: 60px;
`;*/

const SubCategory = styled.div`
    margin-bottom: 60px;
`;

const CategoryBoxContainer = styled.div`
    margin-bottom: 10px;
`;
const Footer = styled.div`
    margin-top: 32px;
    margin-bottom: 35px;
`;
const CompanyRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 32px 0 40px;
    & > div:first-child {
        margin-right: 20px;
    }
`;
const LoadMoreContainer = styled.div`
    text-align: center;
    margin-top: 20px;
`;

const INITIAL_PAGINATION_COUNT = 10;

const AddService = () => {
    const shop_uuid = useSelector(selectShopUuid);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany);
    const mainProduct = useSelector(selectMainProduct);
    const [beforePaginationCount, setBeforePaginationCount] = useState(
        INITIAL_PAGINATION_COUNT
    );
    const [afterPaginationCount, setAfterPaginationCount] = useState(
        INITIAL_PAGINATION_COUNT
    );

    const [searchQuery, setQuery] = useState("");

    const { data: beforeServices } = useQuery(
        [
            "additional-products",
            "SERVICES_BEFORE_THE_LEASE",
            shop_uuid,
            minimal_months,
            mainProduct.id
        ],
        ProductService.getProductsByType(
            "SERVICES_BEFORE_THE_LEASE",
            shop_uuid,
            minimal_months,
            mainProduct.id
        ),
        {
            select: ({ items }) => items,
        }
    );
    const { data: afterServices } = useQuery(
        [
            "additional-products",
            "SERVICES_DURING_THE_LEASE",
            shop_uuid,
            minimal_months,
            mainProduct.id
        ],
        ProductService.getProductsByType(
            "SERVICES_DURING_THE_LEASE",
            shop_uuid,
            minimal_months,
            mainProduct.id
        ),
        {
            select: ({ items }) => items,
        }
    );
    const filterServices = useCallback(
        (service) => {
            if (searchQuery.length === 0) {
                return true;
            }
            return service.details.name
                .toLowerCase()
                .includes(searchQuery.toLowerCase());
        },
        [searchQuery]
    );

    const getInstallment = (service) => {
        if (isCompany) {
            return (
                service?.rental_options?.company_option?.option_formatted
                    ?.gross_month_cost || "0zł"
            );
        }
        return (
            service?.rental_options?.natural_person_option?.option_formatted
                ?.gross_month_cost || "0zł"
        );
    };
    useEffect(() => {
        setBeforePaginationCount(INITIAL_PAGINATION_COUNT);
        setAfterPaginationCount(INITIAL_PAGINATION_COUNT);
    }, [searchQuery]);

    const parsedBeforeServices = useMemo(() => {
        if (!beforeServices) return null;
        return beforeServices
            .slice(0, beforePaginationCount)
            .filter(filterServices);
    }, [beforeServices, beforePaginationCount, filterServices]);

    const parsedAfterServices = useMemo(() => {
        if (!afterServices) return null;

        return afterServices
            .slice(0, afterPaginationCount)
            .filter(filterServices);
    }, [afterServices, afterPaginationCount, filterServices]);

    return (
        <Layout>
            <SearchBar onSubmit={setQuery} />
            <Container>
                <CompanyRow>
                    <div style={{fontSize:"17px"}}>Najem na firmę</div>
                    <CompanyToggle />
                </CompanyRow>
                <SubCategory>
                    <SectionHeader marginBottom={35}>
                        Usługi dodatkowe przed wynajmem
                    </SectionHeader>
                    {!parsedBeforeServices && <Loader fixed />}
                    {parsedBeforeServices?.length === 0 ? (
                        <SearchNotFound />
                    ) : (
                        parsedBeforeServices?.map((service) => (
                            <CategoryBoxContainer key={service.details.id}>
                                <ProductBox
                                    hidePrice={true}
                                    name={service.details.name}
                                    price={service.details.formattedGrossPrice}
                                    quantity={null}
                                    url={`/services-collections/services_before/${service.details.id}`}
                                    imageSrc={service.details.image_url}
                                    installment={getInstallment(service)}
                                />
                            </CategoryBoxContainer>
                        ))
                    )}

                    {beforePaginationCount <
                        parsedBeforeServices?.filter(filterServices).length && (
                        <LoadMoreContainer>
                            <LoadMore
                                onClick={() =>
                                    setBeforePaginationCount((val) => val + 10)
                                }
                            />
                        </LoadMoreContainer>
                    )}
                </SubCategory>
                <SubCategory>
                    <SectionHeader marginBottom={35}>
                        Usługi w trakcie trwania najmu
                    </SectionHeader>
                    {!parsedAfterServices && <Loader fixed />}

                    {parsedAfterServices?.length === 0 ? (
                        <SearchNotFound />
                    ) : (
                        parsedAfterServices?.map((service) => (
                            <CategoryBoxContainer key={service.details.id}>
                                <ProductBox
                                    hidePrice={true}
                                    name={service.details.name}
                                    price={service.details.formattedGrossPrice}
                                    quantity={null}
                                    url={`/services-collections/services_during/${service.details.id}`}
                                    imageSrc={service.details.image_url}
                                    installment={getInstallment(service)}
                                />
                            </CategoryBoxContainer>
                        ))
                    )}
                    {parsedAfterServices <
                        afterServices?.filter(filterServices).length && (
                        <LoadMoreContainer>
                            <LoadMore
                                onClick={() =>
                                    setAfterPaginationCount((val) => val + 10)
                                }
                            />
                        </LoadMoreContainer>
                    )}
                </SubCategory>
                <Footer>
                    <Link to="/basket">
                        <Button LeftIcon={<BackArrowIcon />}>Wstecz</Button>
                    </Link>
                </Footer>
            </Container>
        </Layout>
    );
};

export default AddService;

const FilterIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24.836 21.217"
        {...props}
    >
        <defs>
            <clipPath id="prefix__a">
                <path fill="none" d="M0 0h24.836v21.217H0z" />
            </clipPath>
        </defs>
        <g data-name="Repeat Grid 25">
            <g
                data-name="Group 816"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.6}
                clipPath="url(#prefix__a)"
            >
                <path
                    data-name="Path 917"
                    d="M17.338 16.685h1.647v-.008a5.118 5.118 0 001.422-10.014A375.5 375.5 0 016.846 4.59a6.045 6.045 0 00-.043 12.09h1.33"
                />
                <path data-name="Line 19" d="M12.974 20.416V8.553" />
                <path
                    data-name="Path 918"
                    d="M9.815 11.713l3.159-3.159 3.159 3.159"
                />
            </g>
        </g>
    </svg>
);

export default FilterIcon;

import NavButton from "../../../components/Buttons/NavButton";

const FooterNav = ({ prevStep, nextStep, onBackClicked, onNextClicked }) => {
    return (
        <div>
            <NavButton
                backLink={prevStep}
                nextLink={nextStep}
                onBackClicked={onBackClicked}
                onNextClicked={onNextClicked}
            />
        </div>
    );
};

export default FooterNav;

import { useEffect, useRef } from "react";
import { reset as basketReset } from "../features/basketSlice";
import { reset as transactionReset } from "../features/transactionSlice";
import { persistor, store } from "../store";
import { QueryCache } from 'react-query'

export const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const clearData = () => {
    persistor.purge();
    store.dispatch(basketReset());
    store.dispatch(transactionReset());
};

export const polishPlural = (
    singularNominativ,
    pluralNominativ,
    pluralGenitive,
    value
) => {
    if (value === 1) {
        return singularNominativ;
    } else if (
        value % 10 >= 2 &&
        value % 10 <= 4 &&
        (value % 100 < 10 || value % 100 >= 20)
    ) {
        return pluralNominativ;
    } else {
        return pluralGenitive;
    }
};

export const useDebounce = (fun, time) => {
    const timeout = useRef(null);
    return [
        () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            timeout.current = setTimeout(() => {
                fun();
            }, time);
        },
    ];
};

export const badgeType = (availabilitySymbol) => {
    if (availabilitySymbol === "OUT_OF_STOCK") {
        return "danger";
    }

    return "primary";
};

export const getDataFromAgreementNumber = (agreement_number) => {
    if (agreement_number) {
        return ` ${agreement_number.substring(
            6,
            8
        )}.${agreement_number.substring(4, 6)}.${agreement_number.substring(
            0,
            4
        )}`;
    }
    return "-";
};

export const useClickOutside = (ref, callback) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
};

export const clearCacheData = () => {

    const queryCache = new QueryCache({
        onError: error => {
            console.log(error)
        },
        onSuccess: data => {
            console.log(data)
        }
    });

    queryCache.clear();

    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });

    localStorage.clear();
    sessionStorage.clear();
    console.log('cleared');


};

export const parseParams = (objParams) => {
    let queryParams = '?'
    for (const key in objParams) {
        queryParams = queryParams + `${key}=${objParams[key]}&`
    }
    queryParams = queryParams.slice(0, -1)
    return queryParams
}

export const badgeStatus = (availabilitySymbol) => {
    switch (availabilitySymbol) {
        case 'AVAILABLE': return 'success'
        case 'OUT_OF_STOCK': return 'danger'
        case 'PREORDER': return 'danger'
        case 'BACKORDER': return 'success'
        default: return 'success'
    }
};

export const debounce = (func, timeout = 300) => {
    let timer
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
            func.apply(this, args)
        }, timeout)
    }
}

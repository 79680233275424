import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import CurrencyIcon from '../../../components/Icons/components/CurrencyIcon'
import {
    selectIsCompany,
    selectMainProduct,
    selectMinimalMonthsParsed,
    selectShopUuid,
} from "../../../features/basketSlice"
import { useQuery } from 'react-query'
import ProductService from '../../../services/ProductsService'
import Spiner from '../../../components/Spiner/Spiner'


const MainProduct = () => {
    const mainProduct = useSelector(selectMainProduct);
    const shop_uuid = useSelector(selectShopUuid);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany)

    const { image_url, name } = mainProduct


    const { data: monthCost, isLoading: isLoadingMonthCost } = useQuery(
        ["product-calculation", mainProduct.id, shop_uuid, minimal_months, isCompany],
        ProductService.getProductCalculation(
            mainProduct.id,
            shop_uuid,
            minimal_months
        ),
        {
            select: (data) => isCompany ?
                data.calculations.company_option.option_formatted
                    .gross_month_cost : data.calculations.natural_person_option.option_formatted
                    .gross_month_cost,
            staleTime: 60 * 1000,
        }
    );


    return (
        <StyledMainProduct>
            <Title>
                Główny przedmiot najmu
            </Title>
            <Box>
                <ImageWrapper>
                    <Image src={image_url} />
                </ImageWrapper>
                <Info>
                    <Name>{name}</Name>
                    <PriceRow>
                        <IconWrapper>
                            <CurrencyIcon />
                        </IconWrapper>
                        <Text>
                            Rata miesięczna:
                        </Text>
                        <Price>
                            {isLoadingMonthCost ? <Spiner /> : `${monthCost} zł brutto`}
                        </Price>
                    </PriceRow>
                </Info>
            </Box>
        </StyledMainProduct>
    )
}

export default MainProduct

const StyledMainProduct = styled.div`
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
`

const Box = styled.div`

    border-radius: 20px;
    background-color: ${props => props.theme.white};
    display: flex;
    gap: 20px;
`

const ImageWrapper = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Image = styled.img`
    display: block;
    max-width: 100%;
    max-height: 100%;
`

const Info = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const Name = styled.div`
    font-size: 18px;
    font-weight: bold;
`

const PriceRow = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
    min-height: 24px;
    flex-wrap: wrap;
`

const IconWrapper = styled.div`
    color: ${props => props.theme.primary};
    font-size: 20px;
    display: flex;
`
const Price = styled.div`
    font-weight: bold;
    min-width: 92px;
    
`
const Text = styled.div``
import React from 'react';
import styled, { keyframes } from 'styled-components';

const Spiner = () => {
    return <StyledSpiner />;
};

export default Spiner;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const StyledSpiner = styled.div`
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spin} 2s linear infinite;
  display: inline-block;
`;

import { axios } from "../axios";

const ProductService = {
    getMainProductData: (product_code, shop_uuid) => async () => {
        return await axios
            .get(`/init/product-code/${product_code}/${shop_uuid}`)
            .then((res) => {
                return res.data;
            });
    },

    getProductData: (product_id, shop_uuid, months, mainProductId = null) => async () => {
        if (!product_id || !shop_uuid || !months) {
            return new Promise((resolve) => resolve(null));
        }

        let get = mainProductId ? `/init/product-id/${product_id}/shop/${shop_uuid}/${months}/${mainProductId}` :
            `/init/product-id/${product_id}/shop/${shop_uuid}/${months}`;

        return await axios
            .get(get)
            .then((res) => {
                return res.data;
            });
    },

    getProductCalculation: (product_id, shop_uuid, months, mainProductId = null) => async () => {

        let get = mainProductId ? `/init/product-id/${product_id}/shop/${shop_uuid}/${months}/${mainProductId}` :
            `/init/product-id/${product_id}/shop/${shop_uuid}/${months}`;

        return await axios
            .get(get)
            .then((res) => {
                return res.data;
            });
    },
    getCalculation: ({
        main_product_id,
        minimal_months,
        real_months,
        additional_products = [],
        services_before = [],
        services_during = [],
    }) => async () => {
        return await axios
            .post(`/init/calculator/rate-params`, {
                minimal_months,
                real_months,
                main_product_id,
                additional_products,
                services_before,
                services_during,
            })
            .then((res) => {
                return res.data;
            });
    },
    getProductsByType: (type, shop_uuid, months, mainProductId = null) => async () => {

        let get = mainProductId ? `/init/products/${type}/${shop_uuid}/${months}/${mainProductId}` :
            `/init/products/${type}/${shop_uuid}/${months}`;

        return await axios
            .get(get)
            .then((res) => {
                return res.data;
            });
    },

    getProductDetails: (id, shop_uuid, months, mainProductId) => () => {
        return axios.get(`init/product-id/${id}/shop/${shop_uuid}/${months}/${mainProductId}`).then(res => res.data)
    }
};

export default ProductService;

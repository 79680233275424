import { axios } from "../axios";

const OrderService = {
    sendEmail: (data) => {
        return axios.post(`/init/send-email`, data).then((res) => {
            return res.data;
        });
    },
    confirmEmail: (token1, token2) => () => {
        return axios
            .post(`/init/confirm-email/${token1}/${token2}`)
            .then((res) => {
                return res.data;
            });
    },
    uploadLogin: (token1, token2) => () => {
        return axios
            .post(`/init/transaction/upload-login/${token1}/${token2}`)
            .then((res) => {
                return res.data;
            });
    },
    walkAroundEmail: (data) => {
        return axios.post(`/init/save/step/0`, data).then((res) => {
            return res.data;
        });
    },
    
};

export default OrderService;

const FilterIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 18.442 19.921"
        {...props}
    >
        <g
            data-name="Path 155"
            fill="none"
            strokeMiterlimit={10}
            fillRule="evenodd"
        >
            <path d="M.153 2.786l6.068 10.135v7l6-3v-4l6.068-10.135A1.437 1.437 0 0017.147.714L10.798.079a15.833 15.833 0 00-3.154 0L1.295.714A1.437 1.437 0 00.153 2.786z" />
            <path
                d="M9.221 1.5a14.4 14.4 0 00-1.428.072l-6.245.624 6.173 10.31v4.988l3-1.5v-3.488l6.173-10.31-6.245-.624A14.395 14.395 0 009.22 1.5m0-1.5c.526 0 1.052.026 1.577.079l6.349.635a1.437 1.437 0 011.142 2.072l-6.068 10.135v4l-6 3v-7L.153 2.786c-.45-.9.141-1.972 1.142-2.072L7.644.079C8.169.026 8.694 0 9.221 0z"
                fill="currentColor"
            />
        </g>
    </svg>
);

export default FilterIcon;

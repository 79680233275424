import React from 'react'
import styled from 'styled-components'
import CalendarIcon from '../Icons/components/CalendarIcon'
import Tooltip from '../Tooltip/Tooltip'
import MinimalMonthPeriod from '../../connectedComponents/MonthPeriod/MinimalMonthPeriod'

const tooltipTextArr = [
    'Po upływie minimalnego okresu najmu możesz zwrócić produkt, lub kontynuować jego wypożyczanie tak długo jak chcesz. W momencie zwrotu przedmiotu najmu, umowa ulega zakończeniu.',
    'Pamiętaj, że w każdej chwili możesz wykupić przedmiot najmu, zarówno w trakcie trwania minimalnego okresu najmu, jak i po jego upływie.',
    'Dla każdego produktu, po upływie określonego czasu trwania umowy najmu, produkt przechodzi na Twoją własność za symboliczne 1zł.'
]

const PeriodRow = () => {
    return (
        <StyledPerdioRow>
            <Info>
                <IconWrapper>
                    <CalendarIcon width={20} height={20} />
                </IconWrapper>
                <Text>
                    Minimalny okres najmu
                </Text>
                <Tooltip descriptionArr={tooltipTextArr} />
            </Info>
            <MinimalMonthPeriod />
        </StyledPerdioRow>
    )
}

export default PeriodRow

const StyledPerdioRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`
const Info = styled.div`
    display: flex;
    gap: 10px;
`

const IconWrapper = styled.div`
    color: ${props => props.theme.primary};
`
const Text = styled.div`
    font-size: 17px;
    font-weight: bold;
`
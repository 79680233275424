import styled from "styled-components";

const LabelsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 100%;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    & > div {
        transition: all 0.5s;
        opacity: 0;
    }
`;
const Circle = styled.div`
    width: 35px;
    height: 35px;
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    transition: all 0.5s;
    background: ${({ theme }) => theme.white};
    border-radius: 50%;
`;

const Root = styled.div`
    position: relative;
    width: 100px;
    height: 44px;
    border-radius: 35px;
    transition: all 0.25s;
    background: ${({ value, theme }) =>
        !!value ? theme.primary : theme.darkGrey};
    color: ${({ theme }) => theme.white};
    cursor: pointer;
    & > ${Circle} {
        transform: translateX(${(props) => (!!props.value ? "55px" : "0px")});
    }
    & > ${LabelsContainer} > div:nth-child(1) {
        opacity: ${(props) => (!!props.value ? 1 : 0)};
    }
    & > ${LabelsContainer} > div:nth-child(2) {
        opacity: ${(props) => (!props.value ? 1 : 0)};
    }
`;

const Toggle = ({ value, onChange }) => (
    <Root value={value} onClick={() => onChange(!value)}>
        <LabelsContainer>
            <div>Tak</div>
            <div>Nie</div>
        </LabelsContainer>
        <Circle />
    </Root>
);

export default Toggle;

import { useSelector } from "react-redux";
import { selectMobile } from "../../features/appSlice";
import ProductsCategoriesDesktop from "./ProductsCategoriesDesktop";
import ProductsCategoriesMobile from "./ProductsCategoriesMobile";

const ProductsCategories = ({ navProps }) => {
    const isMobile = useSelector(selectMobile);

    if (isMobile) {
        return <ProductsCategoriesMobile navProps={navProps} />;
    }
    return <ProductsCategoriesDesktop navProps={navProps} />;
};

export default ProductsCategories;

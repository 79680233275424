import React from 'react'
import styled, { css } from 'styled-components'
import FlexBox from '../../../components/FlexBox/FlexBox'
import PlusIcon from '../../../components/Icons/components/PlusIcon'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import RecommendedProductsService from '../../../services/RecommendedProductsService'
import {
    selectMainProduct,
    selectMinimalMonthsParsed,
    selectShopUuid,
} from "../../../features/basketSlice";
import { useSelector } from 'react-redux'
import RecommendedProduct from './RecommendedProduct'
import Spiner from '../../../components/Spiner/Spiner'


import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/swiper-bundle.min';
import Tooltip from '../../../components/Tooltip/Tooltip'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const RecommendedAccesoriesSlider = () => {

    const shopUuid = useSelector(selectShopUuid);
    const mainProduct = useSelector(selectMainProduct);
    const minimalMonths = useSelector(selectMinimalMonthsParsed);


    const { data, isSuccess, isLoading } = useQuery(
        ['recommended-accessories', shopUuid, mainProduct.id, minimalMonths],
        RecommendedProductsService.getAllRecommendedProducts('ACCESSORIES', shopUuid, minimalMonths, mainProduct.id, { limit: 4 })
    )


    return (
        <StyledRecommendedAccesories>
            <Header>
                <IconWrapper>
                    <PlusIcon width={20} height={20} />
                </IconWrapper>
                <HeaderText>Dodaj akcesoria</HeaderText>
                <Tooltip description={'Po zakończeniu minimalnego okresu najmu, wybrane przez Ciebie akcesoria przechodzą na Twoją własność.'} />
            </Header>
            <Slider>
                {
                    isLoading && <Spiner />
                }
                {
                    isSuccess && (
                        <Swiper
                            className='slider'
                            modules={[Navigation]}
                            slidesPerView={2}
                            spaceBetween={-40}
                        >
                            {
                                data.items.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <Link to={`/products-collections/accessories/${item.details.id}`}>
                                            <RecommendedProduct data={item} className='recommendedProduct' />
                                        </Link>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    )
                }
            </Slider>
            <FlexBox alignItems='center' justifyContent='flex-end' gap="10px" margin='0 15px 0 0'>
                <StyledLink to='/categories/accessories' disabled={isLoading}>
                    Wyświetl wszystkie akcesoria
                </StyledLink>
                <Icon />
            </FlexBox>
        </StyledRecommendedAccesories>
    )
}

export default RecommendedAccesoriesSlider

const StyledRecommendedAccesories = styled.div`
    .swiper-slide {
        padding-right: 50px;
        height: auto;
    }
`

const Icon = styled(PlusIcon)`
    color: ${props => props.theme.primary};
    font-size: 20px;
`

const disabledLinkStyle = css`
    pointer-events: none;
    opacity: 0.3;
`

const StyledLink = styled(Link)`
    text-decoration: underline;
    ${props => props.disabled && disabledLinkStyle}
`

const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

`
const IconWrapper = styled.div`
    color: ${props => props.theme.primary};
    display: flex;
`
const HeaderText = styled.div`
    font-size: 17px;
    font-weight: bold;
`

const Slider = styled.div`
    margin-bottom: 20px;
`


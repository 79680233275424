import React from 'react';
import bg from './bg.png';
import logo from '../../components/Header/logo3.svg'
import styled from 'styled-components';

const Maintenance = () => {
  return (
    <Root style={{ backgroundImage: `url(${bg})` }} >
      <Card>
        <Logo src={logo} />
        <h1>Przerwa techniczna, stroimy instrumenty i zaraz wracamy.</h1>
        <h2>
          Zapraszamy tymczasem do bufetu{' '}
          <span aria-label="emojis" role="img">
            &#128521;
          </span>{' '}
        </h2>
      </Card>
    </Root>
  );
};

export default Maintenance;

const Logo = styled.img`
  max-width: 300px;
  width: 100%;
  height: auto;
`

const Root = styled.div`
   background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3f3f46;
  padding: 1rem;
`

const Card = styled.div`
    text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4rem;
  box-shadow: 0 0 24px 0 #a1a1aa;
  width: 980px;
  h1 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 400;
    font-size: 30px;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 400;
  }

  @media screen and (max-width: 600px) {
    h1 {
      font-size: 26px;
    }
    h2 {
      font-size: 20px;
    }
    img {
      max-width: 200px;
      width: 100%;
    }
  }
`





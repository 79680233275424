import styled from "styled-components";
import { Root } from "./Root";

const ArrowIcon = styled.span`
    margin: 0 10px;
`;
const ButtonIcon = styled.span`
    display: flex;
    align-items: center;
    margin-right: 14px;
`;
const Label = styled.div`
    display: flex;
    align-items: center;
    font-size:14px;
`;

const OutlineRoot = styled(Root)`
    background: #f9f9f9;
    box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.16);
    color: ${({ theme }) => theme.black};
    width: ${props => props.width};
`;

const Button = ({
    width = '100%',
    children = "Dalej",
    Icon = null,
    LeftIcon = null,
    RightIcon = null,
    buttonType = "solid",
    onClick = () => null,
    ...props
}) => {
    if (buttonType === "outline") {
        return (
            <OutlineRoot
                width={width}
                onClick={onClick}
                buttonType={buttonType}
                type="button"
                {...props}
            >
                <ArrowIcon>{LeftIcon}</ArrowIcon>
                <Label>
                    {Icon && <ButtonIcon>{Icon}</ButtonIcon>}
                    {children}
                </Label>
                <ArrowIcon>{RightIcon}</ArrowIcon>
            </OutlineRoot>
        );
    }

    return (
        <Root
            width={width}
            onClick={onClick}
            buttonType={buttonType}
            type="button"
            {...props}
        >
            <ArrowIcon>{LeftIcon}</ArrowIcon>
            <Label>
                {Icon && <ButtonIcon>{Icon}</ButtonIcon>}
                {children}
            </Label>
            <ArrowIcon>{RightIcon}</ArrowIcon>
        </Root>
    );
};

export default Button;

const AlertIcon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    {...props}
  >
    <g data-name="Group 949">
      <g data-name="&gt;-ico">
        <path
          data-name="Path 130"
          d="M10 1.538c-2.169 0-5.134.2-6.985.343a1.228 1.228 0 00-1.134 1.134C1.738 4.866 1.538 7.831 1.538 10s.2 5.134.343 6.985a1.228 1.228 0 001.134 1.134c1.851.144 4.816.343 6.985.343s5.134-.2 6.985-.343a1.228 1.228 0 001.134-1.134c.144-1.851.343-4.816.343-6.985s-.2-5.134-.343-6.985a1.228 1.228 0 00-1.134-1.134c-1.851-.143-4.816-.343-6.985-.343zM2.9.347C4.748.2 7.765 0 10 0s5.252.2 7.1.347A2.767 2.767 0 0119.653 2.9C19.8 4.748 20 7.765 20 10s-.2 5.252-.347 7.1a2.767 2.767 0 01-2.553 2.553C15.252 19.8 12.234 20 10 20s-5.252-.2-7.1-.347A2.767 2.767 0 01.347 17.1C.2 15.252 0 12.234 0 10s.2-5.252.347-7.1A2.767 2.767 0 012.9.347z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
      <g fill="currentColor">
        <path
          data-name="Path 938"
          d="M9.88 12.096a.554.554 0 11.554.554.554.554 0 01-.554-.554zm.054-3.328a.5.5 0 111 0l-.194 1.944a.306.306 0 01-.61 0z"
        />
        <path
          data-name="Path 939"
          d="M11.697 7.393l-.514-.524.4-.387.345.353.489-.006a1.6 1.6 0 011.62 1.621l-.005.493.353.345a1.6 1.6 0 010 2.291l-.353.345.006.493a1.6 1.6 0 01-1.621 1.62l-.493-.005-.345.353a1.6 1.6 0 01-2.291 0l-.345-.353-.493.006a1.6 1.6 0 01-1.621-1.621l.005-.493-.352-.345a1.6 1.6 0 010-2.291l.353-.345-.006-.493A1.6 1.6 0 018.45 6.829l.493.005.345-.352a1.6 1.6 0 012.291 0l-.4.387a1.047 1.047 0 00-1.5 0l-.51.523-.732-.009a1.047 1.047 0 00-1.059 1.06l.009.731-.523.51a1.047 1.047 0 000 1.5l.523.51-.009.732a1.047 1.047 0 001.06 1.059l.731-.009.515.523a1.047 1.047 0 001.5 0l.51-.523.732.009a1.047 1.047 0 001.059-1.06l-.009-.731.521-.511a1.047 1.047 0 000-1.5l-.523-.51.009-.732a1.047 1.047 0 00-1.06-1.059z"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export default AlertIcon;

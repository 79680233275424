import styled from "styled-components";
import Container from "../Container/Container";
// import FilterIcon from "../Icons/components/FilterIcon";
// import SortIcon from "../Icons/components/SortIcon";
import SearchInput from "../Inputs/SearchInput";

const Root = styled(Container)`
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.grey};
    padding: 10px 10px 8px;
    form, input {
        width: 100%;
    }
`;
// const Icon = styled.div`
//     padding: 10px 10px;
//     margin: 0 5px;
//     cursor: pointer;
//     transition: all 0.2s;
//     &:hover {
//         opacity: 0.6;
//     }
// `;

// const ICON_SIZE = 20;

const SearchBar = ({ onSubmit, onSort, onFilter }) => (
    <Root>
        {/* <Icon onClick={onFilter}>
            <FilterIcon width={ICON_SIZE} height={ICON_SIZE} />
        </Icon> */}
        {/* <Icon onClick={onSort}>
            <SortIcon width={ICON_SIZE} height={ICON_SIZE} />
        </Icon> */}
        <SearchInput onSubmit={onSubmit} />
    </Root>
);

export default SearchBar;

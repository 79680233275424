const InfoIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        {...props}
    >
        <path
            data-name="Path 92"
            d="M10 1.539c-2.169 0-5.134.2-6.985.342a1.228 1.228 0 00-1.134 1.134C1.737 4.866 1.538 7.831 1.538 10s.2 5.134.343 6.985a1.228 1.228 0 001.134 1.134c1.851.144 4.816.343 6.985.343s5.133-.2 6.985-.343a1.229 1.229 0 001.134-1.134c.143-1.851.342-4.816.342-6.985s-.2-5.134-.342-6.985a1.229 1.229 0 00-1.134-1.134c-1.852-.143-4.816-.342-6.985-.342zM2.9.347C4.752.204 7.769 0 10 0s5.251.2 7.1.347a2.768 2.768 0 012.549 2.549c.143 1.852.347 4.87.347 7.1s-.2 5.252-.347 7.1a2.767 2.767 0 01-2.549 2.549c-1.853.144-4.87.347-7.1.347s-5.252-.2-7.1-.347a2.767 2.767 0 01-2.549-2.549c-.144-1.852-.347-4.869-.347-7.1s.2-5.252.347-7.1A2.768 2.768 0 012.896.347z"
            fill="currentColor"
            fillRule="evenodd"
        />
        <path
            data-name="Path 93"
            d="M10 14.062a.769.769 0 00.769-.769v-4.1a.77.77 0 00-1.539 0v4.1a.769.769 0 00.77.769z"
            fill="currentColor"
            fillRule="evenodd"
        />
        <path
            data-name="Path 94"
            d="M11.025 6.113A1.026 1.026 0 1010 7.139a1.025 1.025 0 001.025-1.026z"
            fill="currentColor"
        />
    </svg>
);

export default InfoIcon;

import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../../components/Buttons/Button";
import Loader from "../../../components/Loader/Loader";
import { selectTransactionId } from "../../../features/transactionSlice";
import Step6Service from "../../../services/Step6Service";
import { getDataFromAgreementNumber } from "../../../utils/functions";
import InputDescription from "../components/InputDescription";
import RentLayout from "../components/RentLayout";
import RentTitle from "../components/RentTitle";
import { selectMobile } from '../../../features/appSlice'
import FlexBox from "../../../components/FlexBox/FlexBox";
import przelewy24 from './przelewy24.jpg'
import CardIcon from "../../../components/Icons/components/CardIcon";
import PaymentCardIcon from "../../../components/Icons/components/PaymentCardIcon";
import VoucherInput from "../components/VoucherInput/VoucherInput";
import { useParams } from "react-router-dom";

const Subtitle = styled.div`
    margin: -20px 0 32px;
    line-height: 18px;
    font-size: 17px;
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    text-align: ${props => props.isMobile ? 'center' : 'left'};
    justify-content: space-between;
    line-height: 20px;
    border-bottom: ${({ border }) => (border ? "1px solid #798090" : "none")};
    margin-top: ${({ border }) => (border ? "5px" : "0")};
    padding-left: ${({ padding }) => (padding ? "8px!important;" : "0")};
    padding-right: ${({ padding }) => (padding ? "8px!important;" : "0")};
`;

const CostsContainer = styled.div`
  ${props => !props.isMobile && 'flex: 1;'}
  margin-top: 40px;
  font-size: 17px;
  & > div:first-child {
    margin-bottom: 10px;
  }
  ${Flex} {
    padding: 10px 0;

    & > span:first-child {
      max-width: 70%;
    }
  }
`;
const RecurringContainer = styled(CostsContainer)`
  ${props => !props.isMobile && 'flex: 1;'}
  margin-top: 40px;
  & > div:first-child {
    margin-bottom: 10px;
  }
`;
const PaymentMethodContainer = styled(CostsContainer)`
  margin-top: 10px;
  margin-bottom: 0px;
`;
const Total = styled.div`
  padding: 18px 0;
  background-color: #f7f7f7;
  font-size: 18px;
  text-align: right;
  margin-top: 16px;
`;

const MasterCardContainer = styled.div`
  svg {
    width: 69px;
    height: 44px;
  }
`;

const Wrapper = styled.div`
  ${props => !props.isMobile && 'text-align: center;'}
`;

const RentTitleDesktop = styled.h3`
  margin: 28px 0 30px;
  font-size: 28px;
  line-height: 26px;
`;

const ProductListContainer = styled(CostsContainer)`

`;

const Przelewy24 = styled.img`
    width: 100%;
`

const VoucherTextValue = styled.span`
    color: #00733C;
    font-weight: bold;
    text-align: right;
`;


const OnlinePaymentAgain = ({ navProps }) => {
    const { schedule_id } = useParams();

    const isMobile = useSelector(selectMobile)
    const transactionId = useSelector(selectTransactionId);

    const { data, isLoading: isGettingData } = useQuery(
        "payment-summary",
        Step6Service.getData(transactionId, schedule_id)
    );

    const {
        mutateAsync: registerPayment,
        isLoading: isGeneratingPayment,
    } = useMutation(schedule_id && parseInt(schedule_id) > 0 ?
        Step6Service.registerExtraTransaction(transactionId, schedule_id) :
        Step6Service.registerTransaction(transactionId), {
        onError: () => {
            toast.error("Kod jest nieprawidłowy");
        },
    });

    const pay = async () => {

        const { link } = await registerPayment();
        window.location.href = link;
    };

    if (isGettingData || isGeneratingPayment) {
        return <Loader fixed />;
    }

    return (
        <RentLayout showNav={false} showSteps={false} {...navProps} style={{paddingLeft: 20, paddingRight: 20}}>
            <Wrapper isMobile={isMobile}>
                {
                    isMobile ? <RentTitle>Płatność cykliczna online</RentTitle> : <RentTitleDesktop>Płatność cykliczna online</RentTitleDesktop>
                }
                <Subtitle>
                    <div>
                        <strong>dla umowy nr {data?.agreement_number}</strong>
                    </div>
                    {data && (
                        <div>
                            {`z dnia ${getDataFromAgreementNumber(
                                data?.agreement_number
                            )}`}
                        </div>
                    )}
                </Subtitle>
            </Wrapper>
            <FlexBox flexDirection={'row'} alignItems='initial' justifyContent="initial">
                <ProductListContainer>
                    <Flex>
                        <strong>Zamówione produkty / usługi:</strong>
                    </Flex>
                    {
                        data && data.products.map((p, index) => (
                            <Flex border key={index}>
                                <span>{p.name}</span>
                            </Flex>
                        ))
                    }
                    {data.voucher.amount !== null && data.voucher.code !== null &&
                        <VoucherInput transactionId={transactionId} refetch={null} voucher={data.voucher} disabled/>
                    }
                </ProductListContainer>
            </FlexBox>
            <FlexBox flexDirection={isMobile ? 'column' : 'row'} alignItems='initial' justifyContent="initial" gap='50px'>
                {schedule_id && parseInt(schedule_id) === 0 &&
                    <CostsContainer isMobile={isMobile}>
                    <Flex>
                        <strong>Twoja dzisiejsza płatność</strong>
                    </Flex>
                    <Flex border padding>
                        <span>Rata za pierwszy miesiąc najmu:</span>
                        <strong>{data?.gross_month_cost_without_discount} zł</strong>
                    </Flex>
                    {data.voucher.amount !== null && data.voucher.code !== null &&
                        <Flex border padding>
                            <span>Voucher płatniczy:</span>
                            <VoucherTextValue>- {data.voucher.amount.toLocaleString('pl-PL', {minimumFractionDigits: 2})} zł</VoucherTextValue>
                        </Flex>
                    }
                    <Flex border padding>
                        <span>Jednorazowa kaucja zwrotna:</span>
                        <strong>{data?.net_administrative_fee} zł</strong>
                    </Flex>
                    <Total>
                        <Flex padding>
                            <span>Łączna pierwsza płatność</span>
                            <div style={{textAlign: 'right'}}>
                                <strong>{data?.first_payment} zł brutto</strong>
                            </div>
                        </Flex>
                    </Total>
                </CostsContainer>
                }
                <RecurringContainer isMobile={isMobile}>
                    <Flex>
                        <strong>Twoje płatności cykliczne</strong>
                        <InputDescription description={"Płatności kolejnych rat najmu będą pobierane co miesiąc z Twojej karty płatniczej. Pierwsza płatność cykliczna zostanie pobrana po upływie 30 dni od daty odbioru sprzętu."} />
                    </Flex>
                    <Flex border padding>
                        <span>Minimalna liczba rat do opłacenia:</span>
                        <span>
                            <strong>{data?.months} raty</strong>
                        </span>
                    </Flex>
                    {data.voucher.amount !== null && data.voucher.code !== null ?
                        <>
                            <Flex border padding>
                                <span>Miesięczna rata w 2-{data.voucher.maxInstallments} miesiącu najmu:</span>
                                <VoucherTextValue>{data?.gross_month_cost} zł brutto</VoucherTextValue>
                            </Flex>
                            <Flex border padding>
                                <span>Miesięczna rata w {data.voucher.maxInstallments + 1}-{data?.months} miesiącu najmu:</span>
                                <span style={{textAlign: 'right'}}><strong>{data?.gross_month_cost_without_discount} zł brutto</strong></span>
                            </Flex>
                        </> :
                        <Flex border padding>
                            <span> Miesięczna rata w kolejnych miesiącach najmu:</span>
                            <span style={{textAlign: 'right'}}><strong>{data?.gross_month_cost_without_discount} zł brutto</strong></span>
                        </Flex>
                    }
                    {
                        !isMobile ? (
                            <div style={{marginTop: 10}}>
                                <FlexBox justifyContent="space-between" alignItems="flex-start" flexDirection="column">
                                    {data?.payPerLink &&
                                        <Przelewy24 src={przelewy24} alt='przelewy24' />
                                    }
                                    {!data?.payPerLink &&
                                        <FlexBox flexDirection="row" alignItems={'center'}>
                                            <FlexBox margin="0 10px 0 0">
                                                <PaymentCardIcon />
                                            </FlexBox>
                                            <FlexBox margin="0 20px 0 0">
                                                Płatność kartą
                                            </FlexBox>
                                            <MasterCardContainer isMobile={isMobile}>
                                                <CardIcon />
                                            </MasterCardContainer>
                                        </FlexBox>
                                    }
                                </FlexBox>
                                <FlexBox margin="15px 0 0 0" flexDirection={'column'} gap={'15px'}>
                                    <Button onClick={pay}>Zamawiam i płacę</Button>
                                </FlexBox>
                            </div>
                        ) : null
                    }
                </RecurringContainer>
            </FlexBox>
            {
                isMobile ? (
                    <>
                        <PaymentMethodContainer>
                            {!data?.payPerLink &&
                                <FlexBox flexDirection="row" alignItems={'center'}>
                                    <FlexBox margin="0 10px 0 0">
                                        <PaymentCardIcon />
                                    </FlexBox>
                                    <FlexBox margin="0 20px 0 0">
                                        Płatność kartą
                                    </FlexBox>
                                    <MasterCardContainer isMobile={isMobile}>
                                        <CardIcon />
                                    </MasterCardContainer>
                                </FlexBox>
                            }
                        </PaymentMethodContainer>
                        <MasterCardContainer isMobile={isMobile}>
                            {data?.payPerLink && <Przelewy24 src={przelewy24} alt='przelewy24' />}
                        </MasterCardContainer>
                        <footer>
                            <Button onClick={pay} style={{marginTop: '15px'}}>Zamawiam i płacę</Button>
                        </footer>
                    </>
                ) : null
            }
        </RentLayout >
    );
};

export default OnlinePaymentAgain;

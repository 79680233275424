import {useMemo, useState} from "react";
import {useQuery} from "react-query";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import styled from "styled-components";
import Button from "../../components/Buttons/Button";
import IconButton from "../../components/Buttons/IconButton";
import Card from "../../components/Card/Card";
import Container from "../../components/Container/Container";
import BackArrowIcon from "../../components/Icons/components/BackArrowIcon";
import BasketIcon from "../../components/Icons/components/BasketIcon";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import PriceRow from "../../components/Product/PriceRow";
import CompanyToggleContainer from "../../components/Product/ProductTitle/CompanyToggleContainer";
import ProductDetails from "../../components/Product/ProductTitle/ProductDetails";
import ProductTitle from "../../components/Product/ProductTitle/ProductTitle";
import SearchBar from "../../components/SearchBar/SearchBar";
import ImageSwiper from "../../components/Swiper/ImageSwiper";
import {
    addService, selectIsCompany, selectMainProduct,
    selectMinimalMonthsParsed,
    selectShopUuid
} from "../../features/basketSlice";
import ProductService from "../../services/ProductsService";
import ProductBreadcrump from "../AddProduct/components/ProductBreadcrump";

const Footer = styled.div`
  padding: 40px 0 35px;
  gap: 10px;
  display: flex;

  & > div:last-child {
    flex: 1;
    margin-left: 10px;
  }
`;

const Service = () => {
    const {type, id: productId} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const shop_uuid = useSelector(selectShopUuid);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany);
    const mainProduct = useSelector(selectMainProduct);

    const [product, setProduct] = useState(null);

    const {isLoading} = useQuery(
        ["product", productId, shop_uuid, minimal_months, mainProduct.id],
        ProductService.getProductData(productId, shop_uuid, minimal_months, mainProduct.id),
        {
            onSuccess: (data) => {
                setProduct(data);
            },
        }
    );

    const installments = useMemo(() => {
        if (!isLoading) {
            if (isCompany) {
                return {
                    gross_month_cost: product?.calculations?.company_option?.option_formatted?.gross_month_cost,
                    net_month_cost: product?.calculations?.company_option?.option_formatted?.net_month_cost
                };
            } else {
                return {
                    gross_month_cost: product?.calculations?.natural_person_option?.option_formatted?.gross_month_cost,
                    net_month_cost: product?.calculations?.natural_person_option?.option_formatted?.net_month_cost
                };
            }
        }
    }, [isCompany, isLoading, product]);

    const handleAddService = () => {
        dispatch(addService({...product, ...{type}}));
        history.push("/basket");
    };

    if (!product) {
        return <Loader fixed/>;
    }
    return (
        <Layout>
            <SearchBar/>

            <Container>
                <ProductBreadcrump
                    list={[
                        // {
                        //     label: "Usługi dodatkowe",
                        //     link: "/services-collections",
                        // },
                        // {
                        //     label: "Przedsprzedażowe",
                        //     link: "/services-collections",
                        // },
                    ]}
                />
                <Card>
                    <ImageSwiper
                        images={[product.image_url, ...product.imageUrls]}
                    />
                </Card>
                <Container>
                    <ProductTitle
                        label="Usługa dodatkowa"
                        name={product.name}
                        availability={product.availability_text}
                    />
                    <ProductDetails
                        location={product.localizations[0].city}
                        condition={product.conditionText}
                        price={product.formattedGrossPrice}
                        description={product.description}
                        service={true}
                        openDefault={true}
                    />

                    <CompanyToggleContainer/>
                    <PriceRow gross_month_cost={installments.gross_month_cost}
                              net_month_cost={installments.net_month_cost} isLoading={isLoading}/>
                </Container>

                <Footer>
                    <IconButton Icon={<BackArrowIcon/>} onClick={history.goBack}></IconButton>
                    <Button
                        LeftIcon={<BasketIcon/>}
                        onClick={handleAddService}
                    >
                        Dodaj do koszyka
                    </Button>
                </Footer>
            </Container>
        </Layout>
    );
};

export default Service;

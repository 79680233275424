const DownloadDocsIcon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
    {...props}
  >
    <g data-name="Group 304" fill="currentColor">
      <path
        data-name="Path 108"
        d="M11.184 33.426l-7.1-.239h-.08a1.186 1.186 0 01-1.186-1.186v-.08L2.41 19.872q-.063-1.869 0-3.739l.408-12.049v-.08a1.187 1.187 0 011.186-1.186h.08l12.049-.409a55.49 55.49 0 013.74 0l12.049.409h.08a1.187 1.187 0 011.186 1.186v.08l.238 7.012a1.186 1.186 0 102.371-.08l-.238-7.013A3.559 3.559 0 0032 .444L19.951.036q-.974-.033-1.95-.033t-1.95.033L4.002.444A3.559 3.559 0 00.443 4.003L.035 16.052q-.066 1.949 0 3.9l.408 12.049a3.559 3.559 0 003.559 3.559l7.1.239a1.186 1.186 0 10.08-2.371z"
      />
      <path
        data-name="Path 109"
        d="M24.089 12.179A11.911 11.911 0 1036 24.09a11.911 11.911 0 00-11.911-11.911zm0 21.444a9.533 9.533 0 119.533-9.533 9.544 9.544 0 01-9.533 9.533z"
      />
      <path
        data-name="Path 110"
        d="M28.201 23.349l-2.919 2.918v-7.224a1.189 1.189 0 00-2.378 0v7.224l-2.891-2.891a1.189 1.189 0 00-1.681 1.681l4.921 4.921.841.841 5.789-5.789a1.189 1.189 0 10-1.681-1.681z"
      />
      <path
        data-name="Path 111"
        d="M29.138 8.492a1.187 1.187 0 00-1.185-1.189H8.047a1.189 1.189 0 000 2.378h19.906a1.187 1.187 0 001.185-1.189z"
      />
      <path
        data-name="Path 112"
        d="M11.126 17.626a1.189 1.189 0 00-1.189-1.189h-1.9a1.189 1.189 0 000 2.378h1.9a1.189 1.189 0 001.189-1.189z"
      />
      <path
        data-name="Path 113"
        d="M8.035 25.571a1.189 1.189 0 000 2.378h.713a1.189 1.189 0 100-2.378z"
      />
    </g>
  </svg>
);

export default DownloadDocsIcon;

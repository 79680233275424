const QuestionMarkIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        {...props}
    >
        <path
            data-name="Path 90"
            d="M10 1.539c-2.17 0-5.134.2-6.985.342a1.228 1.228 0 00-1.134 1.134C1.737 4.866 1.538 7.831 1.538 10s.2 5.134.343 6.985a1.228 1.228 0 001.134 1.134c1.851.144 4.815.343 6.985.343s5.133-.2 6.985-.343a1.229 1.229 0 001.134-1.134c.143-1.851.342-4.816.342-6.985s-.2-5.134-.342-6.985a1.229 1.229 0 00-1.134-1.134c-1.852-.143-4.816-.342-6.985-.342zM2.9.347C4.752.204 7.769 0 10 0s5.251.2 7.1.347a2.768 2.768 0 012.549 2.549c.143 1.852.347 4.87.347 7.1s-.2 5.252-.347 7.1a2.767 2.767 0 01-2.549 2.549c-1.853.144-4.87.347-7.1.347s-5.252-.2-7.1-.347a2.766 2.766 0 01-2.549-2.549c-.144-1.852-.347-4.869-.347-7.1s.2-5.252.347-7.1A2.767 2.767 0 012.896.347z"
            fillRule="evenodd"
            fill="currentColor"
        />
        <g data-name="Group 284">
            <path
                data-name="Path 91"
                d="M9.982 11.564a.738.738 0 01-.721-.892 2.158 2.158 0 01.111-.367 2.591 2.591 0 01.7-.923 3.585 3.585 0 00.68-.74 1.159 1.159 0 00.169-.6q0-.955-.88-.955a.892.892 0 00-.668.257.828.828 0 00-.167.248.8.8 0 01-.729.462.8.8 0 01-.714-1.185 1.987 1.987 0 01.408-.5 3.217 3.217 0 013.726-.031 2.058 2.058 0 01.66 1.634 1.99 1.99 0 01-.214.907 3.752 3.752 0 01-.75.946l-.457.434a1.6 1.6 0 00-.408.609.876.876 0 00-.024.1.735.735 0 01-.724.6zm-.966 1.729a.822.822 0 01.257-.621 1 1 0 011.315 0 .82.82 0 01.256.621.811.811 0 01-.251.613 1.028 1.028 0 01-1.325 0 .812.812 0 01-.252-.613z"
                fill="currentColor"
            />
        </g>
    </svg>
);

export default QuestionMarkIcon;

import styled from "styled-components";

const Root = styled.strong`
    margin-bottom: 30px;
    line-height: 30px;
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
`;
const RentSubtitle = ({ children }) => {
    return <Root>{children}</Root>;
};

export default RentSubtitle;

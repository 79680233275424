import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import ProductBox from "../../components/ProductBox/ProductBox";
import ProductBoxAlternative from "../../components/ProductBox/ProductBoxAlternative";
import {
    selectIsCompany,
    selectMainProduct,
    selectMinimalMonthsParsed,
    selectShopUuid,
} from "../../features/basketSlice";
import ProductService from "../../services/ProductsService";


const MainProductBox = ({ alternativeView }) => {
    const mainProduct = useSelector(selectMainProduct);
    const shop_uuid = useSelector(selectShopUuid);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany)


    const { data: monthCost, isLoading: isLoadingMonthCost } = useQuery(
        ["product-calculation", mainProduct.id, shop_uuid, minimal_months, isCompany],
        ProductService.getProductCalculation(
            mainProduct.id,
            shop_uuid,
            minimal_months
        ),
        {
            select: (data) => isCompany ?
                data.calculations.company_option.option_formatted
                    .gross_month_cost : data.calculations.natural_person_option.option_formatted
                    .gross_month_cost,
            staleTime: 60 * 1000,
        }
    );




    return (
        <>
            {
                alternativeView ? (
                    <ProductBoxAlternative
                        isLoadingMonthCost={isLoadingMonthCost}
                        name={mainProduct.name}
                        imageSrc={mainProduct.image_url}
                        availability={mainProduct.availabilityText}
                        status={mainProduct.conditionText}
                        price={mainProduct.formattedGrossPrice}
                        installment={monthCost}
                    />
                ) : (
                    <ProductBox
                        name={mainProduct.name}
                        imageSrc={mainProduct.image_url}
                        availability={mainProduct.availabilityText}
                        availabilitySymbol={mainProduct.availabilitySymbol}
                        status={mainProduct.conditionText}
                        price={mainProduct.formattedGrossPrice}
                        installment={monthCost}
                    />
                )
            }
        </>
    );
};

export default MainProductBox;

import React from 'react';
import FlexBox from "../../components/FlexBox/FlexBox";
import Tile from "../../components/Icons/components/Tile";
import mObywatelIcon from "../../components/Icons/mObywatel_icon.png";
import StandardFormIcon from "../../components/Icons/standardForm_icon.png";
import styled from "styled-components";
import Tooltip from "../../components/Tooltip/Tooltip";
import useAuthologicContext from "./useAuthologic";


const SelectPersonalDataProvider = () => {
    const {changeStatus, status, isError} = useAuthologicContext()
    const handleClick = (e) => {
        changeStatus(e.target.value)
    }

    return (
        <div>
            <Title>Wybór formularza</Title>
            <RadioContainer>
                <FlexBox justifyContent='center' flex="1" gap={'4rem'}>
                    <InputGroup>
                        <IconLabel htmlFor="mObywatel">
                            <Tile
                                icon={
                                    <div>
                                        <img src={mObywatelIcon} alt="mObywatel"/>
                                        mObywatel
                                    </div>
                                }
                                active={status === 'active'}
                                // disabled={isError}
                                disabled={true}
                            />
                            <input
                                type='radio'
                                id='mObywatel'
                                value='mObywatel'
                                onClick={handleClick}
                                disabled={isError}
                            />
                        </IconLabel>
                        <TileDescription>
                            Formularz skrócony
                            <TooltipContainer>
                                <Tooltip className='tooltip' description="Ta opcja pozwala na weryfikację tożsamości z wykorzystaniejm aplikacji mObywarel. Twoje dane są bezpieczne i chronione zgodnie z najwyższymi standardami bezpieczeństwa." />
                            </TooltipContainer>
                            <br/>
                            (weryfikacja danych za pomocą<br/>aplikacji mObywatel)
                        </TileDescription>
                    </InputGroup>
                    <InputGroup>
                        <IconLabel htmlFor="STANDARD_FORM">
                            <Tile
                                icon={
                                    <img src={StandardFormIcon} alt="mObywatel"/>
                                }
                                active={status !== 'active'}
                            />
                            <input
                                type='radio'
                                id='STANDARD_FORM'
                                value='STANDARD_FORM'
                                onClick={handleClick}
                            />
                        </IconLabel>
                        <TileDescription>
                            Formularz standardowy<br/>
                            (samodzielne wypełnienie danych)
                        </TileDescription>
                    </InputGroup>
                </FlexBox>
            </RadioContainer>
        </div>
    );
}

export default SelectPersonalDataProvider;

const RadioContainer = styled.div`
  border: 1px solid ${({theme, isError}) => (isError ? theme.danger : "transparent")};
  padding: 10px;
  margin: 10;
  display: flex;

  label > span {
    font-weight: bold;
  }
`;

const InputGroup = styled.div`
  font-size: 1.125rem;
  font-weight: bold;
`;

const IconLabel = styled.label`
  display: inline-block;
  cursor: pointer;

  & > input {
    position: absolute;
    display: block;
    width: 0px;
    height: 0px;
    opacity: 0;
    top: -1000px;
    left: -1000px;
  }
`;

const Title = styled.h1`
  font-size: 28px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const TileDescription = styled.p`
  line-height: 24px;
`;

const TooltipContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  margin-top: -3px;
`;
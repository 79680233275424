import React, { useState } from 'react'
import Modal from '../../../components/Modal/Modal'
import Button from '../../../components/Buttons/Button'
import styled from 'styled-components'
import TextInput from '../../../components/Inputs/TextInput'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import BasketService from '../../../services/BasketService'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import Loader from '../../../components/Loader/Loader'
import { toast } from 'react-toastify'
import { CopyToClipboard } from 'react-copy-to-clipboard';


const ShareLinkModal = ({ show, closeModal }) => {

    const minimalMonths = useSelector(state => state.basket.minimal_months)
    const productCode = useSelector(state => state.basket.product_code)
    const shopUuid = useSelector(state => state.basket.shop_uuid)
    const additionalProducts = useSelector(state => state.basket.additionalProducts)
    const services = useSelector(state => state.basket.services)
    const mainProductId = useSelector(state => state.basket.mainProduct.id)

    const [isCopied, setIsCopied] = useState(false)

    const additionaProductId = useMemo(() => {
        return additionalProducts.length > 0 ? `${additionalProducts.map(product => product.id)}` : 'empty'
    }, [additionalProducts])

    const servicesId = useMemo(() => {
        return services.length > 0 ? `${services.map(service => service.id)}` : 'empty'
    }, [services])


    const link = useMemo(() => {
        return `${window.location.protocol}//${window.location.host}/product/${productCode}/shop/${shopUuid}/min_months/${minimalMonths}/additionaProductId/${additionaProductId}/servicesId/${servicesId}/mainProductId/${mainProductId}`
    }, [productCode, shopUuid, minimalMonths, additionaProductId, mainProductId, servicesId])

    const { register, handleSubmit, setError, formState: { errors } } = useForm({
        defaultValues: {
            email: ''
        }
    })

    const { mutate, isLoading } = useMutation(
        BasketService.share(),
        {
            onError: (e) => {
                e.response.data?.errors?.forEach(
                    ({ message, invalid_property }) => {
                        setError(invalid_property, {
                            type: "manual",
                            message,
                        });
                    }
                );
            },
            onSuccess: () => {
                toast.success('Wiadomość została wysłana pomyślnie.')
                closeModal()
            }
        }
    )


    const onSubmit = (values) => {
        mutate({
            email: values.email,
            link: link
        })
    }

    return (
        <>
            {isLoading && <Loader fixed />}
            <Modal show={show} closeModal={closeModal}>
                <ModalContent>
                    <ShareLinkText>Udostępnij koszyk</ShareLinkText>
                    <SharedLink defaultValue={link} disabled rows={6} />
                    {isCopied && <CopiedText isCopied={isCopied}>Skopiowany!</CopiedText>}
                    <CopyToClipboard
                        text={link}
                        onCopy={() => setIsCopied(true)}
                    >
                        <CopyButton buttonType='outline'>Skopiuj Link</CopyButton>
                    </CopyToClipboard>

                    <ShareLinkText2>lub wyślij link na adres email</ShareLinkText2>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>
                            <TextInput
                                placeholder="Adres email"
                                register={register('email')}
                            />
                            {errors?.email && <Error>{errors.email.message}</Error>}
                        </FormGroup>
                        <Button type='submit'>Wyślij email</Button>
                    </Form>
                    <Button buttonType='outline' onClick={closeModal}>Zamknij</Button>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ShareLinkModal

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
`

const ShareLinkText = styled.div`
    font-size: 20px;
    color: black;
    font-weight: bold;
    text-align: center;
`

const ShareLinkText2 = styled(ShareLinkText)`
    font-size: 16px;
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const SharedLink = styled.textarea`
    border-radius: 15px;
    padding: 10px;
    border-color: gray;
    resize: vertical;
`

const FormGroup = styled.div`
    width: 100%;
    input {
        width: 100%;
    }  
`

const Error = styled.div`
    padding-left: 22px;
    padding-top: 11px;
    font-size: 13px;
    color: ${props => props.theme.danger};
`

const CopyButton = styled(Button)`
    color: ${props => props.theme.secondary};
    border: 1px solid ${props => props.theme.secondary};
    background-color: ${props => props.theme.white};
    box-shadow: none;
    padding: 10px;
`

const CopiedText = styled.div`
    color: ${props => props.theme.secondary};
    text-align: center;
    font-weight: bold;
    font-size: 16px;
`
const FacebookIcon = (props) => (
    <svg
        data-name="Group 330"
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 30 30"
        {...props}
    >
        <path
            data-name="Path 133"
            d="M26.25 0H3.75A3.754 3.754 0 000 3.75v22.5A3.754 3.754 0 003.75 30h22.5A3.754 3.754 0 0030 26.25V3.75A3.754 3.754 0 0026.25 0z"
            fill="#1976d2"
        />
        <path
            data-name="Path 134"
            d="M25.306 15.021h-4.681v-3.745c0-1.034.839-.936 1.872-.936h1.872V5.66h-3.745a5.616 5.616 0 00-5.617 5.617v3.745h-3.743v4.681h3.745v10.3h5.617v-10.3h2.808z"
            fill="#fafafa"
        />
    </svg>
);

export default FacebookIcon;

const DiamondIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        {...props}
    >
        <g data-name="Group 880">
            <g data-name="&gt;-ico">
                <path
                    data-name="Path 130"
                    d="M18.462 10c0-2.169-.2-5.134-.343-6.985a1.228 1.228 0 00-1.134-1.134c-1.851-.143-4.816-.343-6.985-.343s-5.134.2-6.985.343a1.228 1.228 0 00-1.134 1.134C1.737 4.866 1.538 7.831 1.538 10s.2 5.134.343 6.985a1.228 1.228 0 001.134 1.134c1.851.144 4.816.343 6.985.343s5.134-.2 6.985-.343a1.228 1.228 0 001.134-1.134c.143-1.851.343-4.816.343-6.985zm1.191-7.1C19.8 4.748 20 7.765 20 10s-.2 5.252-.347 7.1a2.767 2.767 0 01-2.553 2.553C15.252 19.8 12.235 20 10 20s-5.252-.2-7.1-.347A2.767 2.767 0 01.347 17.1C.2 15.252 0 12.234 0 10s.2-5.252.347-7.1A2.767 2.767 0 012.9.347C4.748.2 7.766 0 10 0s5.252.2 7.1.347A2.767 2.767 0 0119.653 2.9z"
                    fill="currentColor"
                    fillRule="evenodd"
                />
            </g>
            <path
                d="M5.687 9.515l3.309 3.531-1.593-3.531H5.687zm4.313 4.1l1.854-4.1H8.146zm-2.581-4.78l1.083-2.04H7.111l-1.53 2.04zm3.585 4.211l3.309-3.531h-1.716zM8.189 8.835h3.622l-1.083-2.04H9.273zm4.393 0h1.837l-1.529-2.04h-1.392zm.749-2.582l2.04 2.72a.338.338 0 01-.021.435l-5.1 5.439a.346.346 0 01-.5 0L4.651 9.408a.338.338 0 01-.021-.435l2.04-2.72a.31.31 0 01.271-.138h6.121a.31.31 0 01.271.138z"
                fill="currentColor"
            />
        </g>
    </svg>
);

export default DiamondIcon;

const Consumer = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="90"
        height="84.465"
        viewBox="0 0 90 84.465"
        stroke={props.color ? props.color : '#0486D6'}
        {...props}
    >
        <g id="Group_1342" data-name="Group 1342" transform="translate(-14.805 -28.863)">
            <line id="Line_99" data-name="Line 99" x2="86" transform="translate(16.805 111.327)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <g id="Group_1341" data-name="Group 1341" transform="translate(16.805 30.863)">
                <circle id="Ellipse_388" data-name="Ellipse 388" cx="6.825" cy="6.825" r="6.825" transform="translate(0 52.51)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <circle id="Ellipse_389" data-name="Ellipse 389" cx="6.825" cy="6.825" r="6.825" transform="translate(24.806 15.45)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <circle id="Ellipse_390" data-name="Ellipse 390" cx="6.825" cy="6.825" r="6.825" transform="translate(48.355 39.644)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <circle id="Ellipse_391" data-name="Ellipse 391" cx="6.825" cy="6.825" r="6.825" transform="translate(72.349)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <line id="Line_100" data-name="Line 100" y1="25.717" x2="17.213" transform="translate(10.622 27.947)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <line id="Line_101" data-name="Line 101" y1="27.676" x2="17.45" transform="translate(58.325 12.739)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <line id="Line_102" data-name="Line 102" x2="14.216" y2="14.252" transform="translate(36.451 27.107)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            </g>
        </g>
    </svg>
);

export default Consumer;




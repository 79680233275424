import { axios } from "../axios";

const BasketService = {
    share: () => (data) => {
        return axios.post(`/init/cart/share`, data).then((res) => {
            return res.data;
        });
    },

};

export default BasketService;

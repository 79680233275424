const DoneIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 14.962 11.081"
        {...props}
    >
        <path
            data-name="Path 871"
            d="M1.414 6.614l3.467 3.467 8.666-8.667"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
        />
    </svg>
);

export default DoneIcon;

import { useEffect } from "react";
import { useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
    margin: 0 -15px;
`;
const Input = styled.input`
    width: 60px;
    height: 80px;
    border-radius: 15px;
    transition: all 0.2s;
    border: 2px solid
        ${({ value, theme }) => (value === "" ? "#798090" : theme.primary)};
    margin: 0 15px;
    font-size: 3.5714285714285716em;
    text-align: center;
    outline: none;
    &:focus {
        border: 2px solid ${({ theme }) => theme.primary};
    }
`;

const PinInput = ({
    isMobile = false,
    pinLength = 4,
    onPinFill = (value) => console.log(value),
}) => {
    const [value, setValue] = useState(Array(pinLength).fill(""));
    const containerRef = useRef(null);

    const selectNextInput = (index, v) => {
        if (index + 1 >= pinLength) {
            containerRef.current.children[index].blur();
            const valString = v.join("");
            if (valString.length !== pinLength) {
                containerRef.current.children[v.indexOf("")].focus();
                return;
            }
            if (onPinFill && valString.length === pinLength) {
                onPinFill(v.join(""));
            }
            return;
        }
        containerRef.current.children[index + 1].focus();
    };
    const selectPrevInput = (index) => {
        if (index - 1 < 0) {
            return;
        }
        containerRef.current.children[index - 1].focus();
    };
    const onKeyPress = (e, index) => {

        if (isMobile) {

            return;
        }

        const newValue = value.map((v, _index) =>
            index === _index ? e.key : v
        );


        if (e.keyCode >= 48 && e.keyCode <= 57) {
            setValue(newValue);
            selectNextInput(index, newValue);
            return;
        }
        if (e.keyCode >= 65 && e.keyCode <= 90) {
            setValue(newValue);
            selectNextInput(index, newValue);
            return;
        }

        switch (e.keyCode) {
            case 8 /* backspace */: {
                selectPrevInput(index);
                setValue(
                    value.map((v, _index) => (index <= _index + 1 ? "" : v))
                );
                break;
            }
            case 37 /* left */: {
                selectPrevInput(index);
                break;
            }
            case 39 /* right */: {
                selectNextInput(index);
                break;
            }
            default: {
                break;
            }
        }
    };



    // const onKeyUp = (e, index) => {
    //
    //     if(isMobile){
    //
    //
    //         const ev = e.target.value;
    //         const currentKey = ev? ev.slice(-1) : null;
    //
    //
    //         const newValue = value.map((v, _index) =>
    //             index === _index ? currentKey : v
    //         );
    //
    //         alert(currentKey);
    //
    //         if(!['0','1','2','3','4','5','6','7','8','9'].includes(currentKey)){
    //             return;
    //         }
    //         setValue(newValue);
    //         selectNextInput(index, newValue);
    //     }
    // };


    const onchangeHandler = (e, index) => {

        if (isMobile) {

            const ev = e.target.value;
            const currentKey = ev ? ev.slice(-1) : null;


            const newValue = value.map((v, _index) =>
                index === _index ? currentKey : v
            );

            if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(currentKey)) {
                return;
            }
            setValue(newValue);
            selectNextInput(index, newValue);
        }
    };

    useEffect(() => {
        // Focus on the first input field when the component mounts
        containerRef.current.children[0].focus();
    }, []);


    return (
        <Container ref={containerRef}>
            {Array(pinLength)
                .fill()
                .map((_, index) => (
                    <Input
                        key={index}
                        type="text"
                        value={!!value[index] ? "*" : ""}
                        onKeyDown={(e) => onKeyPress(e, index)}
                        onChange={(e) => onchangeHandler(e, index)}
                    />
                ))}
            {/* {value.join()} */}
        </Container>
    );
};

export default PinInput;

import { axios } from "../axios";


const SkipFormService = {
    skipForm: (token1, token2) => () => {
        return axios
            .post(`/init/transaction/login/step/3/token1/${token1}/token2/${token2}`)
            .then((res) => {
                return res.data.data;
            });
    },
};

export default SkipFormService;

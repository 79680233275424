const CurrencyIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        {...props}
    >
        <g data-name="Group 280">
            <path
                data-name="Path 88"
                d="M10.854 11.557a.807.807 0 00-.2-.576 1.822 1.822 0 00-.681-.4 7.872 7.872 0 01-.829-.359 2.637 2.637 0 01-.6-.41 1.646 1.646 0 01-.388-.545 1.822 1.822 0 01-.139-.746A1.649 1.649 0 018.493 7.3a2.01 2.01 0 011.266-.556v-.587a.353.353 0 01.353-.353.353.353 0 01.353.353v.6a1.8 1.8 0 011.553 1.272.609.609 0 01-.59.779h-.043a.615.615 0 01-.587-.463.881.881 0 00-.163-.329.723.723 0 00-.585-.262.742.742 0 00-.56.205.784.784 0 00-.2.567.744.744 0 00.2.538 2.128 2.128 0 00.721.414 8.133 8.133 0 01.866.4 2.5 2.5 0 01.574.423 1.633 1.633 0 01.357.538 1.859 1.859 0 01.124.7 1.645 1.645 0 01-.468 1.217 2.044 2.044 0 01-1.288.551v.527a.351.351 0 01-.351.351.351.351 0 01-.35-.351v-.522a2.126 2.126 0 01-1.4-.642 1.794 1.794 0 01-.36-.6.629.629 0 01.588-.848h.007a.64.64 0 01.606.464.854.854 0 00.9.621.874.874 0 00.613-.2.712.712 0 00.225-.55z"
                fill="currentColor"
            />
        </g>
        <path
            data-name="Path 89"
            d="M10 1.538c-2.169 0-5.134.2-6.985.342a1.23 1.23 0 00-1.134 1.134C1.738 4.866 1.539 7.83 1.539 10s.2 5.133.342 6.985a1.23 1.23 0 001.134 1.134c1.851.143 4.816.342 6.985.342s5.134-.2 6.985-.342a1.229 1.229 0 001.134-1.134c.144-1.852.343-4.816.343-6.985s-.2-5.134-.343-6.986a1.229 1.229 0 00-1.134-1.134c-1.851-.143-4.815-.342-6.985-.342zM2.9.347C4.753.203 7.77 0 10 0s5.252.2 7.1.347a2.765 2.765 0 012.549 2.549c.144 1.852.347 4.869.347 7.1s-.2 5.251-.347 7.1a2.765 2.765 0 01-2.549 2.549c-1.852.144-4.869.348-7.1.348s-5.251-.2-7.1-.348a2.767 2.767 0 01-2.549-2.549c-.143-1.852-.347-4.869-.347-7.1s.2-5.252.347-7.1A2.767 2.767 0 012.896.347z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export default CurrencyIcon;

import styled from "styled-components";
import CompanyToggle from "../../../connectedComponents/CompanyToggle/CompanyToggle";
import Tooltip from "../../Tooltip/Tooltip";

const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 24px;
    @media(max-width:290px){
       flex-direction:column; 
       align-items: flex-start;
       justify-content: center;
    }
`;

const TooltipContainer = styled.div`
    margin-right: 0px;
     @media(max-width:290px){
       margin-top:10px;
       margin-bottom:10px;
    }
`;
const Label = styled.div`
    margin-right: 14px;
    font-size: 17px;
`;
const CompanyToggleContainer = ({ value, onChange }) => (
    <Root>
        <Label>Wynajem na firmę</Label>
        <TooltipContainer>
            <Tooltip description="Wynajmij jako osoba fizyczna, osoba prowadząca jednoosobową działalność gospodarczą lub jako osoba prawna, w tym spółka, instytucja, szkoła, uczelnia, stowarzyszenie, fundacja, przedsiębiorstwo państwowe, związek, jednostka samorządowa, etc. Sprawdź nasze promocje dla firm oraz instytucji!" />
        </TooltipContainer>
        <CompanyToggle />
    </Root>
);

export default CompanyToggleContainer;

// import { useSelector } from "react-redux";
import styled from "styled-components";
// import { selectMobile } from "../../features/appSlice";
import Container from "../Container/Container";
import DesktopFooter from "../DesktopFooter/DesktopFooter";
import Header from "../Header/Header";

const Root = styled.div`
    background: #f0f0f0;
`;

const Layout = ({ children, showBasket, desktopTitle }) => {
    // const isMobile = useSelector(selectMobile);
    return (
        <Root>
            <Header showBasket={showBasket} desktopTitle={desktopTitle} />
            {/* {isMobile && children}
            {!isMobile && <Container>{children}</Container>} */}
            <Container>{children}</Container>
            <DesktopFooter />
        </Root>
    );
};

export default Layout;

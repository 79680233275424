import { useEffect } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import { setTransaction } from "../../features/transactionSlice";
import OrderService from "../../services/OrderService";

const ConfirmEmail = () => {
    const { token1, token2 } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();


    const { mutate } = useMutation(OrderService.confirmEmail(token1, token2), {
        onSuccess: (data) => {
            dispatch(setTransaction(data.data));

            history.push("/rent/contract-data")

        },
    });
    useEffect(mutate, [mutate]);

    return (
        <Loader fixed />
    );
};

export default ConfirmEmail;

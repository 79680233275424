const Consumer = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="86.814"
        height="75.612"
        viewBox="0 0 86.814 75.612"
        stroke={props.color ? props.color : '#0486D6'}
        {...props}
    >
        <g id="Group_1340" data-name="Group 1340" transform="translate(-25.901 -37.504)">
            <path id="Path_1030" data-name="Path 1030" d="M92.593,86.455H52L41.274,39.5H51.04l9.383,37H88Z" transform="translate(0)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <path id="Path_1031" data-name="Path 1031" d="M28.716,96.607a64.041,64.041,0,0,0,81.185,0" transform="translate(0 0)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <path id="Path_1032" data-name="Path 1032" d="M94.1,64.454c-7.9-3.618-18.011-5.79-29.03-5.79a81.473,81.473,0,0,0-9.046.5" transform="translate(0 0)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_96" data-name="Line 96" y1="18.654" x2="9.797" transform="translate(42.2 86.455)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_97" data-name="Line 97" x1="9.797" y1="18.654" transform="translate(82.796 86.455)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_98" data-name="Line 98" y1="13.655" x2="7.172" transform="translate(80.523 62.158)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
        </g>
    </svg>
);

export default Consumer;


import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import PlusIcon from '../../../components/Icons/components/PlusIcon';
import { selectAdditionalProducts, selectServices } from "../../../features/basketSlice";
import SelectedProductRow from './SelectedProductRow';


const SelectedProductsList = () => {
    const additionalProducts = useSelector(selectAdditionalProducts);
    const services = useSelector(selectServices);
    return (
        <>
            {
                (additionalProducts.length !== 0 || services.length !== 0) && (
                    <StyledSelectedProductsList>
                        <Header>
                            <IconWrapper>
                                <PlusIcon width={20} height={20} />
                            </IconWrapper>
                            <HeaderText>Dodane akcesoria i usługi</HeaderText>
                        </Header>

                        {
                            additionalProducts.map((product, index) => (
                                <SelectedProductRow key={index} product={product} type='accesories' />
                            ))
                        }
                        {
                            services.map((product, index) => (
                                <SelectedProductRow key={index} product={product} type='all_services' />
                            ))
                        }
                    </StyledSelectedProductsList>
                )
            }
        </>
    )
}

export default SelectedProductsList

const StyledSelectedProductsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

`
const IconWrapper = styled.div`
    color: ${props => props.theme.primary};
    display: flex;
`
const HeaderText = styled.div`
    font-size: 17px;
    font-weight: bold;
`
const CheckedIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        {...props}
    >
        <g data-name="Group 952">
            <g data-name="&gt;-ico">
                <path
                    data-name="Path 130"
                    d="M18.462 10c0-2.169-.2-5.134-.343-6.985a1.228 1.228 0 00-1.134-1.134c-1.851-.143-4.816-.343-6.985-.343s-5.134.2-6.985.343a1.228 1.228 0 00-1.134 1.134C1.737 4.866 1.538 7.831 1.538 10s.2 5.134.343 6.985a1.228 1.228 0 001.134 1.134c1.851.144 4.816.343 6.985.343s5.134-.2 6.985-.343a1.228 1.228 0 001.134-1.134c.143-1.851.343-4.816.343-6.985zm1.191-7.1C19.8 4.748 20 7.765 20 10s-.2 5.252-.347 7.1a2.767 2.767 0 01-2.553 2.553C15.252 19.8 12.235 20 10 20s-5.252-.2-7.1-.347A2.767 2.767 0 01.347 17.1C.2 15.252 0 12.234 0 10s.2-5.252.347-7.1A2.767 2.767 0 012.9.347C4.748.2 7.766 0 10 0s5.252.2 7.1.347A2.767 2.767 0 0119.653 2.9z"
                    fill="currentColor"
                    fillRule="evenodd"
                />
            </g>
            <path
                d="M14.39 7.95a.524.524 0 01-.159.385l-4.1 4.1-.77.77a.547.547 0 01-.77 0l-.77-.77-2.052-2.049a.547.547 0 010-.77l.77-.77a.547.547 0 01.77 0l1.666 1.67 3.715-3.721a.547.547 0 01.77 0l.77.77a.524.524 0 01.159.385z"
                fill="currentColor"
            />
        </g>
    </svg>
);

export default CheckedIcon;

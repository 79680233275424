import styled from "styled-components";
import Breadcrumps from "../../../components/Breadcrumps/Breadcrumps";

const Root = styled.div`
    margin: 38px 0 15px;

`;
const ProductBreadcrump = (props) => (
    <Root>
        <Breadcrumps {...props} />
    </Root>
);

export default ProductBreadcrump;

import { configureStore } from "@reduxjs/toolkit";
import basketReducer from "./features/basketSlice";
import transactionReducer from "./features/transactionSlice";
import appReducer from "./features/appSlice";
import { persistStore, persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "g4r_root",
    storage,
    blacklist: ['app']
};
const combinedReducers = combineReducers({
    basket: basketReducer,
    transaction: transactionReducer,
    app: appReducer
});
const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [],
});
export const persistor = persistStore(store);

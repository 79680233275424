import styled from "styled-components";
import InputDescription from "./InputDescription";

const Root = styled.div`
    margin-bottom: 20px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Label = styled.div`
    font-size: 17px;
    line-height: 25px;
    font-weight: bold;
    color: ${({ theme, isError }) =>
        isError ? `${theme.danger}` : `${theme.black}`};
`;

const Input = styled.input`
  border: none;
  border-bottom: ${({theme, isError}) =>
          isError ? `1px solid ${theme.danger}` : "1px solid #C2C2C2FF"};
  padding: 4px 0 6px;
  font-size: 14px;
  line-height: 26px;
  width: 100%;
  outline: none;
  background-color: #f8f8f8;
`;
export const ErrorContainer = styled.div`
    text-align: right;
    font-size: 12px;
    margin-top: 4px;
    color: ${({ theme }) => theme.danger};
`;

const RentInput = ({
    label,
    description,
    register,
    name,
    error = null,
    ...inputProps
}) => {
    return (
        <Root>
            <label>
                <Header>
                    {label && <Label isError={!!error}>{label}</Label>}
                    {!!description && (
                        <InputDescription description={description} />
                    )}
                </Header>
                {register ? (
                    <Input
                        {...register(name)}
                        {...inputProps}
                        isError={!!error}
                    />
                ) : (
                    <Input {...inputProps} />
                )}
                {error?.message && (
                    <ErrorContainer>
                        <span>{error.message}</span>
                    </ErrorContainer>
                )}
            </label>
        </Root>
    );
};

export default RentInput;

// eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";
import styled from "styled-components";
// eslint-disable-next-line no-unused-vars
import Button from "../../../components/Buttons/Button";
import CompletedIcon from "../../../components/Icons/components/CompletedIcon";
import RentLayout from "../components/RentLayout";
import RentTitle from "../components/RentTitle";
import { selectMobile } from '../../../features/appSlice'
import { useSelector } from "react-redux";

const IconContainer = styled.div`
    font-size: 70px;
    color: ${({ theme }) => theme.primary};
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

`;
const Strong = styled.div`
    line-height: 18px;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 15px;
`;
const Text = styled.div`
    line-height: 20px;
    white-space: pre-wrap;
    margin-bottom: 28px;
    font-size: 17px;
`;

const Wraper = styled.div`
    max-width: ${props => props.isMobile ? '100%' : '590px'};
    text-align: ${props => !props.isMobile && 'center'};
    margin: auto;
`;

const G4_Confirmation = ({ navProps }) => {
    const isMobile = useSelector(selectMobile)
    return (
        <RentLayout showNav={false} {...navProps}>
            <Wraper isMobile={isMobile}>
                <RentTitle>
                    Dziękujemy za wypełnienie wniosku i czas, który nam poświęciłeś.
                </RentTitle>
                <IconContainer>
                    <CompletedIcon />
                </IconContainer>
                <Strong>
                    Na adres email Wnioskodawcy wysłaliśmy potwierdzenie złożenia
                    wniosku o najem. Znajdziesz tam także link, dzięki któremu łatwo
                    i szybko doślesz nam brakujące dokumenty. Jeśli już to
                    zrobiłeś/zrobiłaś, bardzo dziękujemy!
                </Strong>
                <Text>
                    {`Informację zwrotną z decyzją w sprawie umowy otrzymasz od nas niezwłocznie po dostarczeniu wszystkich wymaganych dokumentów.

Po ewentualny odbiór sprzętu zaprosimy Cię wraz z dowodem osobistym. Możliwa jest także wysyłka kurierska, wówczas także potrzebne będzie okazanie Twojego dowodu kurierowi.`}
                </Text>
                {/*<Link to="/rent/online-contract">*/}
                {/*    <Button>OK</Button>*/}
                {/*</Link>*/}
            </Wraper>
        </RentLayout>
    );
};

export default G4_Confirmation;

import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Button from "../../components/Buttons/Button";
import Card from "../../components/Card/Card";
import FlexBox from "../../components/FlexBox/FlexBox";
import NextArrowIcon from "../../components/Icons/components/NextArrowIcon";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import CompanyToggleContainer from "../../components/Product/ProductTitle/CompanyToggleContainer";
import MainProductBox from "../../connectedComponents/MainProductBox/MainProductBox";
import ConnectedProductBox from "../../connectedComponents/ProductBox/ProductBox";
import {
    selectMinimalMonthsParsed,
    selectShopUuid,
    selectAdditionalProducts,
    selectMainProduct,
    selectServices,
    selectIsCompany
} from "../../features/basketSlice";
import ProductService from "../../services/ProductsService";
import BackArrowIcon from "../../components/Icons/components/BackArrowIcon";
import { useHistory, useParams } from "react-router-dom";
import SocialBox from "../../components/SocialBox/SocialBox";
import Calculator from "../../components/Product/ProductTitle/Calculator";
// import MainProductCalculator from "../../components/Product/ProductTitle/MainProductCalculator";
import Deposit from "../../components/Product/ProductTitle/Deposit";

const Header = styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: left;
    ${props => props.resetTopMargin && 'margin-top: 0;'}
`;


const Common = ({ showCalculator = true, addProduct, disabledAddToCart, productToCalculate, productToCalculateLoading }) => {
    const { category, product: productId } = useParams();
    const history = useHistory()
    const shop_uuid = useSelector(selectShopUuid);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const [product, setProduct] = useState(null);
    const mainProduct = useSelector(selectMainProduct);
    const [notFound, setNotFound] = useState(false);
    const additionalProducts = useSelector(selectAdditionalProducts);
    const services = useSelector(selectServices);
    const isCompany = useSelector(selectIsCompany);

    // eslint-disable-next-line
    const { isLoading } = useQuery(
        ["product", mainProduct?.id, shop_uuid, minimal_months],
        ProductService.getProductData(
            mainProduct?.id,
            shop_uuid,
            minimal_months
        ),
        {
            onSuccess: (data) => {
                setProduct(data);
                if (!data) {
                    setNotFound(true);
                }
            },
        }
    );

    const {
        data: additionalProductData,
        isLoading: isLoadingAdditionalProduct
    } = useQuery(
        ["additional-product", productId, shop_uuid, minimal_months],
        ProductService.getProductData(productId, shop_uuid, minimal_months),
    );


    const handleBack = () => {
        history.goBack()
    }

    const handleBackToBasket = () => {
        history.push('/basket')
    }

    const handleAddToCart = () => {
        addProduct()
    }


    if (notFound) {
        return (
            <Layout>
                <h1>Nie znaleziono partnera</h1>
            </Layout>
        );
    }

    return (
        <>
            {
                !product && !notFound && <Loader fixed />
            }
            {
                !!product && (
                    <>
                        <FlexBox gap='1rem'>
                            <div style={{ flex: 3 }}>
                                <Card>
                                    {showCalculator && (
                                        <>
                                            <CompanyToggleContainer />
                                            {/* <MainProductCalculator
                                                productToCalculate={additionalProductData}
                                                productToCalculateLoading={isLoadingAdditionalProduct}
                                                {...additionalProductData?.calculations}
                                                isLoading={isLoadingAdditionalProduct}
                                            /> */}
                                            <Calculator
                                                showOptions={false}
                                                {...additionalProductData?.calculations}
                                                isLoading={isLoadingAdditionalProduct}
                                            />
                                            <DepositContainer>
                                                <Deposit price={
                                                    isCompany ?
                                                        additionalProductData?.calculations?.company_option?.option_formatted?.net_administrative_fee :
                                                        additionalProductData?.calculations?.natural_person_option?.option_formatted?.net_administrative_fee}
                                                />
                                            </DepositContainer>

                                        </>
                                    )}
                                    <Header resetTopMargin={!showCalculator}>Główny przedmiot najmu</Header>
                                    <MainProductBox alternativeView={true} />
                                    {
                                        additionalProducts.length > 0 && <Header>Akcesoria i produkty dodatkowe</Header>
                                    }
                                    {additionalProducts?.map((product, index) => (

                                        <ConnectedProductBox
                                            alternativeView={true}
                                            key={index}
                                            product={product}
                                        />
                                    ))}
                                    {
                                        services.length > 0 && <Header>Usługi dodatkowe</Header>
                                    }
                                    {services?.map((service, index) => {
                                        return (
                                            <ConnectedProductBox
                                                alternativeView={true}
                                                key={index}
                                                product={service}
                                            />
                                        );
                                    })}
                                    <ButtonContainers>
                                        {
                                            !!addProduct && category !== 'main' && (
                                                <Button
                                                    RightIcon={<NextArrowIcon />}
                                                    onClick={handleAddToCart}
                                                    disabled={disabledAddToCart}
                                                >
                                                    Dodaj do koszyka
                                                </Button>
                                            )
                                        }
                                        <Button
                                            buttonType='outline'
                                            LeftIcon={<BackArrowIcon />}
                                            onClick={handleBack}
                                        >
                                            Wstecz
                                        </Button>
                                        <Button
                                            buttonType='outline'
                                            LeftIcon={<BackArrowIcon />}
                                            onClick={handleBackToBasket}
                                        >
                                            Wróć do koszyka
                                        </Button>

                                    </ButtonContainers>
                                </Card>
                                <SocialBox />
                            </div>
                        </FlexBox>
                    </>
                )
            }
        </>
    );
};

export default Common;

const ButtonContainers = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 25px;
    & > div {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 15px;
        }
    }
`;

const DepositContainer = styled.div`
    margin-top: 20px;
`

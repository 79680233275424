import RentSubtitle from "../RentSteps/components/RentSubtitle";
import RentInput from "../RentSteps/components/RentInput";
import React from "react";
import styled from "styled-components";
import Loader from "../../components/Loader/Loader";

const AuthologicForm = ({customerType, register, errors, labels, nipChecker}) => {

    const LABELS = labels;

    return (
        <Container>
            <Title>POZOSTAŁE DANE DO UMOWY NAJMU</Title>
            {customerType !== 'LEGAL_PERSON' ?
                <>
                    {customerType === "INDIVIDUAL_ENTREPRENEUR" &&
                        <>
                            <RentInput
                                label={LABELS?.nip[customerType]}
                                name="nip"
                                error={errors.nip}
                                register={register}
                            />
                            {nipChecker.isLoading && <Loader/>}
                            {nipChecker.data?.c20 && (
                                <RentInput
                                    label={LABELS.companyName[customerType]}
                                    value={nipChecker.data?.c20}
                                    disabled
                                />
                            )}
                        </>
                    }
                    <RentInput
                        label={
                            LABELS.phone[
                            customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                        }
                        description="Numer telefonu będzie potrzebny w trakcie realizacji transakcji do potwierdzenia warunków umowy oraz przy odbiorze sprzętu."
                        register={register}
                        name="phone"
                        error={errors["c17"] || errors.phone}
                    />
                </> : <>
                    <SubtitleContainer>
                        <RentSubtitle>Dane podmiotu:</RentSubtitle>
                    </SubtitleContainer>
                    <RentInput
                        label={LABELS?.nip[customerType]}
                        name="nip"
                        error={errors.nip}
                        register={register}
                    />
                    {nipChecker.isLoading && <Loader/>}
                    {nipChecker.data?.c20 && (
                        <RentInput
                            label={LABELS.companyName[customerType]}
                            value={nipChecker.data?.c20}
                            disabled
                        />
                    )}
                    <SubtitleContainer>
                        <RentSubtitle>Dane osobowe osoby reprezentującej Najemcę:</RentSubtitle>
                    </SubtitleContainer>
                    <RentInput
                        label={
                            LABELS.phone[
                            customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                        }
                        description="Numer telefonu będzie potrzebny w trakcie realizacji transakcji do potwierdzenia warunków umowy oraz przy odbiorze sprzętu."
                        register={register}
                        name="phone"
                        error={errors["c17"] || errors.phone}
                    />
                </>
            }
        </Container>
    )
};
export default AuthologicForm;

const SubtitleContainer = styled.div`
  margin-bottom: 28px;
  font-size: 1.75rem;
  text-align: center;
`;

const Container = styled.div`
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-size: 28px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
  margin-top: 40px;
`;
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useMutation, useQuery} from "react-query";
import AuthologicService from "../../services/AuthologicService";
import {toast} from "react-toastify";

export const AuthologicContext = React.createContext();
const AuthologicIntegrationProvider = ({ children}) => {
    const customerTypeStore = useSelector(state => state.transaction.step1.customerType)
    const [intervalId, setIntervalId] = useState(null);
    const [formType, setFormType] = React.useState('STANDARD_FORM');
    const [initAuthologicState, setInitAuthologicState] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState();
    const [authologicIsBroken, setAuthologicIsBroken] = React.useState(false);
    const [initObject, setInitObject] = React.useState({});
    const [requestObjectState, setRequestObjectState] = useState({ requestCounter: 0, isLoading: false, isSuccess: false });

    const AuthologicRequestsLimit = 8

    const {mutate: initMutation} = useMutation(AuthologicService.init(), {
        onSuccess: (data) => {
            window.location.href = data.url
        },
        onError: (data) => {
            toast.error(`Usługa pobrania danych z aplikacji mObywatel jest chwilowo niedostępna. Wypełnij standardowy formularz.`)
            setInitAuthologicState(false)
            setAuthologicIsBroken(true)
            setErrorMessage('Could init Authologic service. ')
        }
    });

    useEffect(() => {
        if(initAuthologicState) {
            initMutation({
                "customer_type": customerTypeStore
            })
        }
    }, [initAuthologicState, initMutation, customerTypeStore])

    const { refetch: AuthologicRefetch } = useQuery('authologic-autocomplete', AuthologicService.autoComplete, {
        enabled: initObject?.startFetchingData ? initObject.startFetchingData : false,
        onSuccess: (queryData) => {
            queryData.then((data) => {
                if ((!data.fields || data.fields.length < 4) && requestObjectState.requestCounter <= AuthologicRequestsLimit) {
                    if (intervalId) {
                        clearInterval(intervalId);
                    }
                    const interval = setInterval(() => {
                        AuthologicRefetch();
                    }, 3000);
                    setIntervalId(interval);
                    setRequestObjectState({
                        ...requestObjectState,
                        requestCounter: requestObjectState.requestCounter + 1,
                        isLoading: true
                    })
                    // setStep(2)
                }
                else if ((data?.fields?.every(ai => ['c2', 'c3', 'c4', 'c8'].includes(ai))) && requestObjectState.requestCounter <= AuthologicRequestsLimit) {
                    // do zmiany ten warunek aby najpierw sprawdzać czy jest wiecej niz 0 elementów
                    setRequestObjectState({ ...requestObjectState, isLoading: false, isSuccess: true })
                    if (intervalId) {
                        clearInterval(intervalId);
                    }
                    toast.success('Potwierdziliśmy dane za pomocą aplikacji mObywatel')
                    initObject?.fetchingData?.isSuccess()
                    // setStep(3)
                } else {
                    if (intervalId) {
                        clearInterval(intervalId);
                    }
                    setRequestObjectState({ ...requestObjectState, isLoading: false })
                    initObject?.fetchingData?.isError()
                    // setStep(2)
                    setAuthologicIsBroken(true)
                    toast.error(`Wystąpił bład podczas pobierania danych z aplikacji mObywatel. Wypełnij standardowy formularz.`)
                }
            })
        },
    })

    useEffect(() => {
        if(errorMessage) {
            console.error('AUTHOLOGIC INTEGRATION ERROR: ', errorMessage)
        }
    }, [errorMessage])

    const authologic = {
        setDefaultObject: (value) => {
            setInitObject(value)
        },

        status: formType === 'mObywatel' ? 'active' : 'inactive',
        changeStatus: (value) => {
            console.log('changeStatus', value)
            setFormType(value)
        },
        //fire
        isStarted: initAuthologicState,
        start: () => {
            setInitAuthologicState(true)
        },
        //featching data
        isFetching: requestObjectState.isLoading,
        isSuccess: requestObjectState.isSuccess,

        // Errors
        isError: authologicIsBroken,
        errorMessage: errorMessage

    }

    return (
        <AuthologicContext.Provider value={authologic}>
            {children}
        </AuthologicContext.Provider>
    )
}
export default AuthologicIntegrationProvider
import { axios } from "../axios";
import { store } from "../store";

const Step5Service = {
    login: (token1, token2) => () => {
        return axios
            .post(`/init/transaction/step/5/accept-agreement-login/${token1}/${token2}`)
            .then((res) => {
                return res.data;
            });
    },
    sendSms: (transation_id) => () => {
        const token = store.getState().transaction.transaction.token;
        return axios
            .post(
                `/transaction/step/5/send-sms/${transation_id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
    verifySmsCode: (transation_id) => (data) => {
        const token = store.getState().transaction.transaction.token;

        return axios
            .post(
                `/transaction/step/5/verify-sms-code/${transation_id}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
    sendAgreement: (transation_id) => (data) => {
        const token = store.getState().transaction.transaction.token;

        return axios
            .post(
                `/transaction/step/5/upload/attachment/${transation_id}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
};

export default Step5Service;

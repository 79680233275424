const SearchIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        {...props}
    >
        <path
            data-name="Path 156"
            d="M9.487 1.539a7.937 7.937 0 107.948 7.938 7.944 7.944 0 00-7.948-7.938zM0 9.477a9.487 9.487 0 1116.708 6.147l3.066 3.063a.769.769 0 11-1.087 1.088l-3.069-3.065A9.49 9.49 0 010 9.477z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export default SearchIcon;

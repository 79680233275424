import styled from "styled-components";

const Root = styled.h3`
    margin: 28px 0 30px;
    font-size: 20px;
    line-height: 26px;
`;
const RentTitle = ({ children }) => {
    return <Root>{children}</Root>;
};

export default RentTitle;

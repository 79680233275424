import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import { setTransaction } from "../../features/transactionSlice";
import Step5Service from "../../services/Step5Service";
import Container from "../../components/Container/Container";
import Layout from "../../components/Layout/Layout";
import RentTitle from "../../views/RentSteps/components/RentTitle";
import CompletedIcon from "../../components/Icons/components/CompletedIcon";
import styled from "styled-components";
import { BsClockHistory } from 'react-icons/bs'


const IconContainer = styled.div`
    font-size: 70px;
    margin-top: -16px;
    color: ${({ theme }) => theme.primary};
    text-align: center;
`;

const Centered = styled.div`
    width:100%;
    text-align:center;
    padding-bottom:20px;
`;

const LoginAccepted = () => {
    const { token1, token2 } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [status, setStatus] = useState('LOADING');

    const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const { mutate, error } = useMutation(Step5Service.login(token1, token2), {
        onSuccess: async (data) => {

            setStatus(data.status);

            if (data.token) {
                dispatch(setTransaction(data));
                setStatus('ACCEPTED');
                await sleep(1000);
                history.push("/rent/online-contract");

            }
        },
    });
    useEffect(mutate, [mutate]);
    if (error) {
        return <div>Wystąpił błąd</div>;
    }

    if (status !== 'LOADING') {

        return <Layout showBasket={false}>
            <Container style={{ backgroundColor: "#fff", paddingTop: '10px' }}>
                {
                    (status === 'WAITING_FOR_RESERVATION' || status === 'WAITING_FOR_DECISION' || status === 'BIK_ERROR' || status === 'WAITING_TO_BE_TAKEN') &&
                    <RentTitle><Centered>Twój wniosek oczekuje na akceptację</Centered></RentTitle>
                }
                {
                    (status === 'REJECTED') &&
                    <RentTitle><Centered>Twój wniosek został odrzucony</Centered></RentTitle>
                }
                {
                    (status === 'ACCEPTED') &&
                    <RentTitle><Centered>Twój wniosek został zaakceptowany</Centered></RentTitle>
                }
                <IconContainer>
                    {
                        (status === 'WAITING_FOR_RESERVATION' || status === 'WAITING_FOR_DECISION' || status === 'BIK_ERROR' || status === 'REJECTED' || status === 'WAITING_TO_BE_TAKEN') &&
                        <BsClockHistory />
                    }
                    {
                        (status === 'ACCEPTED') &&
                        <CompletedIcon />
                    }
                </IconContainer>
            </Container>
        </Layout>


    }
    // if(status === 'WAITING_FOR_RESERVATION' || status === 'WAITING_FOR_DECISION' || status ==='BIK_ERROR'){
    //     return <h1> Twój wniosek oczekuje na akceptację</h1>
    // }
    // if(status === 'ACCEPTED'){
    //     return <h1> Twój wniosek został zaakceptowany</h1>
    // }

    return <Loader fixed />

};

export default LoginAccepted;

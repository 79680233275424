import { axios } from "../axios";
import { store } from "../store";

const Step3Service = {
    saveStep3: (type, transationId) => (data) => {
        const token = store.getState().transaction.transaction.token;
        return axios
            .put(`/transaction/save/step/3/${type}/${transationId}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                timeout: 20000,
            })
            .then((res) => {
                return res.data;
            });
    },
};

export default Step3Service;

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import {
    selectIsCompany,
} from "../../../features/basketSlice"


const RecommendedProduct = ({ data, ...props }) => {
    const { image_url, name } = data.details
    const isCompany = useSelector(selectIsCompany)

    const price = useMemo(() => {
        return isCompany ?
            data.rental_options.company_option.option_formatted.gross_month_cost :
            data.rental_options.natural_person_option.option_formatted.gross_month_cost
    }, [data, isCompany])


    return (
        <StyledRecommendedAccessorie {...props}>
            <ImageWrapper>
                <Image src={image_url} />
            </ImageWrapper>
            <Name>
                {name}
            </Name>
            <Price>
                Rata: <b>{`${price} zł`}</b>
            </Price>
        </StyledRecommendedAccessorie>
    )
}

export default RecommendedProduct

const StyledRecommendedAccessorie = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    text-align: center;
    background-color: ${props => props.theme.white};
    padding: 10px;
    border-radius: 10px;
    height: 100%;
`

const ImageWrapper = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Image = styled.img`
    display: block;
    max-width: 100%;
    max-height: 100%;
`

const Name = styled.div`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
`

const Price = styled.div``
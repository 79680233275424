import { Link } from "react-router-dom";
import styled from "styled-components";

const Ul = styled.ul`
    list-style: none;
    display: flex;
    li:last-child a {
        color: ${({ theme }) => theme.black};
    }
`;
const Li = styled.li`
    &:after {
        content: "";
        display: inline-block;
        background: #798090;
        height: 8px;
        width: 1px;
        margin: 0 5px;
    }
    &:last-child:after {
        display: none;
    }
    & a {
        color: #798090;
        text-transform: uppercase;
        text-decoration: underline;
        font-size: 0.7142857142857143em;
        font-weight: bold;
        line-height: 13px;
    }
`;

const Breadcrumps = ({
    list = [
        {
            label: "Usługi dodatkowe",
            link: "/",
        },
        {
            label: "Przedsprzedażowe",
            link: "/",
        },
    ],
}) => (
    <div>
        <Ul>
            {list.map(({ label, link }) => (
                <Li key={link}>
                    <Link to={link}>{label}</Link>
                </Li>
            ))}
        </Ul>
    </div>
);

export default Breadcrumps;

import styled from "styled-components";
import DownIcon from "../Icons/components/DownIcon";

const Root = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 15px 16px;
    border-radius: 25px;
    min-width: 160px;
    cursor: pointer;
    transition: all 0.4s;
    &:hover {
        opacity: 0.5;
    }
`;

const IconContainer = styled.div`
    color: ${({ theme }) => theme.primary};
    margin-right: 15px;
`;

const Label = styled.div`
    color: #798090;
    font-size: 0.7142857142857143em;
`;

const LoadMore = (props) => (
    <Root {...props}>
        <IconContainer>
            <DownIcon />
        </IconContainer>
        <Label>Wczytaj więcej</Label>
    </Root>
);

export default LoadMore;

import { useRef } from "react";
import styled from "styled-components";
import Button from "../Buttons/Button";
import UploadIcon from "../Icons/components/UploadIcon";

const Container = styled.label`
    svg {
        font-size: 24px;
        margin-right: 14px;
    }
    button {
        opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
        cursor: ${({ disabled }) => (disabled ? "not-allowed" : "cursor")};
    }
`;
const Input = styled.input`
    display: none;
`;

const FileInput = ({
    label = "DODAJ PLIK Z DYSKU",
    value,
    disabled,
    ...props
}) => {
    const inputRef = useRef(null);
    return (
        <Container disabled={disabled}>
            {value && value[0]?.name}
            <Button
                disabled={disabled}
                onClick={() => inputRef.current.click()}
            >
                <UploadIcon />
                <span>{label}</span>
            </Button>
            <Input type="file" ref={inputRef} {...props} />
        </Container>
    );
};

export default FileInput;

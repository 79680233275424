import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import CurrencyIcon from '../Icons/components/CurrencyIcon'
import {
    selectIsCompany,
    selectMainProduct,
    selectMinimalMonthsParsed,
    selectShopUuid,
} from "../../features/basketSlice"
import { useQuery } from 'react-query'
import Spiner from '../Spiner/Spiner'
import ProductService from '../../services/ProductsService'

const MainProduct = () => {
    const mainProduct = useSelector(selectMainProduct);
    const shop_uuid = useSelector(selectShopUuid);
    const months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany)
    const { image_url, name } = mainProduct

    const { data, isLoading, isSuccess } = useQuery(
        ["productDetails", mainProduct.id, shop_uuid, months, mainProduct.id],
        ProductService.getProductDetails(mainProduct.id, shop_uuid, months, mainProduct.id),
        {
            keepPreviousData: true
        }
    );

    // pick calculation data depend on "company/no campany switch"

    const calculationData = useMemo(() => {
        if (isSuccess && data) {
            return isCompany ? data.calculations.company_option.option_formatted : data.calculations.natural_person_option.option_formatted
        }
        else {
            return null
        }

    }, [isCompany, data, isSuccess])


    return (
        <StyledMainProduct>
            <Title>
                Główny przedmiot najmu
            </Title>
            <Box>
                <ImageWrapper>
                    <Image src={image_url} />
                </ImageWrapper>
                <Info>
                    <Name>{name}</Name>
                    <PriceRow>
                        <IconWrapper>
                            <CurrencyIcon />
                        </IconWrapper>
                        <Text>
                            Rata miesięczna:
                        </Text>
                        <Price>
                            {isLoading ? <Spiner /> : `${calculationData.gross_month_cost} zł brutto`}
                        </Price>
                    </PriceRow>
                </Info>
            </Box>
        </StyledMainProduct>
    )
}

export default MainProduct

const StyledMainProduct = styled.div`
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
`

const Box = styled.div`

    border-radius: 20px;
    background-color: ${props => props.theme.white};
    display: flex;
    gap: 20px;
`

const ImageWrapper = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Image = styled.img`
    display: block;
    max-width: 100%;
    max-height: 100%;
`

const Info = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const Name = styled.div`
    font-size: 18px;
    font-weight: bold;
`

const PriceRow = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
    min-height: 24px;
    flex-wrap: wrap;
`

const IconWrapper = styled.div`
    color: ${props => props.theme.primary};
    font-size: 20px;
    display: flex;
`
const Price = styled.div`
    font-weight: bold;
    min-width: 92px;
    
`
const Text = styled.div``
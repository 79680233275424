const ClockIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 40 40"
        {...props}
    >
        <g data-name="Group 316">
            <g data-name="Group 305">
                <path
                    data-name="Path 114"
                    d="M25.896 1.317a1.192 1.192 0 001.151 1.193l8.4.285h.081a1.59 1.59 0 011.589 1.591v.081l.456 13.468a61.282 61.282 0 010 4.2l-.456 13.468v.081a1.59 1.59 0 01-1.589 1.591h-.081l-8.4.285a1.192 1.192 0 00-1.151 1.193 1.192 1.192 0 001.232 1.193l8.4-.285a3.974 3.974 0 003.971-3.978l.456-13.467q.037-1.09.037-2.18t-.037-2.179l-.456-13.468A3.975 3.975 0 0035.528.411l-8.4-.285a1.193 1.193 0 00-1.232 1.193z"
                    fill="currentColor"
                />
            </g>
            <g data-name="Group 306">
                <path
                    data-name="Path 115"
                    d="M14.061 38.751a1.193 1.193 0 00-1.151-1.193l-8.37-.284h-.081a1.59 1.59 0 01-1.589-1.591v-.087L2.415 22.25a62.072 62.072 0 01-.001-4.197L2.87 4.465v-.08a1.59 1.59 0 011.591-1.589h.081l8.37-.284a1.193 1.193 0 001.151-1.193A1.192 1.192 0 0012.831.126L4.461.41A3.975 3.975 0 00.49 4.388L.034 17.976q-.036 1.081-.036 2.162 0 1.1.038 2.2l.455 13.346a3.975 3.975 0 003.971 3.978l8.37.284a1.192 1.192 0 001.232-1.193z"
                    fill="currentColor"
                />
            </g>
            <g data-name="Group 309">
                <g data-name="Group 307">
                    <path
                        data-name="Path 116"
                        d="M15.323 7.878a1.324 1.324 0 01-.936-2.26L20.005 0l5.608 5.608a1.324 1.324 0 01-1.872 1.872l-3.736-3.736-3.747 3.746a1.318 1.318 0 01-.935.388z"
                        fill="currentColor"
                    />
                </g>
                <g data-name="Group 308">
                    <path
                        data-name="Path 117"
                        d="M19.995 39.999l-5.608-5.608a1.324 1.324 0 011.872-1.872l3.736 3.736 3.747-3.746a1.324 1.324 0 111.872 1.872z"
                        fill="currentColor"
                    />
                </g>
            </g>
            <g data-name="Group 310">
                <path
                    data-name="Path 118"
                    d="M14.468 15.9a.586.586 0 01.585.585v7.15a.585.585 0 01-1.171 0v-7.15a.586.586 0 01.585-.585m0-2.647a3.233 3.233 0 00-3.233 3.232v7.15a3.233 3.233 0 006.465 0v-7.15a3.233 3.233 0 00-3.233-3.232z"
                    fill="currentColor"
                />
            </g>
            <g data-name="Group 311">
                <path
                    data-name="Path 119"
                    d="M7.111 15.9a.586.586 0 01.585.585v7.15a.585.585 0 01-1.171 0v-7.15a.586.586 0 01.585-.585m0-2.647a3.233 3.233 0 00-3.233 3.232v7.15a3.233 3.233 0 006.465 0v-7.15a3.233 3.233 0 00-3.233-3.232z"
                    fill="currentColor"
                />
            </g>
            <g data-name="Group 312">
                <path
                    data-name="Path 120"
                    d="M32.889 15.9a.586.586 0 01.585.585v7.15a.585.585 0 01-1.171 0v-7.15a.586.586 0 01.585-.585m0-2.647a3.233 3.233 0 00-3.233 3.232v7.15a3.233 3.233 0 006.465 0v-7.15a3.233 3.233 0 00-3.233-3.232z"
                    fill="currentColor"
                />
            </g>
            <g data-name="Group 313">
                <path
                    data-name="Path 121"
                    d="M25.532 15.9a.586.586 0 01.585.585v7.15a.585.585 0 01-1.171 0v-7.15a.586.586 0 01.585-.585m0-2.647a3.233 3.233 0 00-3.233 3.232v7.15a3.233 3.233 0 006.465 0v-7.15a3.233 3.233 0 00-3.233-3.232z"
                    fill="currentColor"
                />
            </g>
            <g data-name="Group 314">
                <path
                    data-name="Path 122"
                    d="M20 16.349a1.324 1.324 0 101.324 1.324A1.324 1.324 0 0020 16.349z"
                    fill="currentColor"
                />
            </g>
            <g data-name="Group 315">
                <path
                    data-name="Path 123"
                    d="M20 21.123a1.324 1.324 0 101.324 1.324A1.324 1.324 0 0020 21.123z"
                    fill="currentColor"
                />
            </g>
        </g>
    </svg>
);

export default ClockIcon;

import {useEffect, useState} from "react";
import styled from "styled-components";
import QuestionMarkIcon from "../../../components/Icons/components/QuestionMarkIcon";

const QuestionMark = styled.div`
    display: flex;
    position: relative;
    cursor: pointer;

`;

const Description = styled.div`
    position: absolute;
    right: calc(100% + 20px);
    bottom: 0;
    transform: translateY(50%) translateX(0);
    z-index: 10;
    background-color: white;
    min-width: 250px;
    padding: 20px;
    box-shadow: 0 0px 10px rgb(0 0 0 / 10%);
`;

const InputDescription = ({ description, onQuestionMarkClick = null }) => {
    const [showDescription, setDescription] = useState(false);

    useEffect(() => {
        const clickedOutside = () => {
            if (showDescription) {
                setDescription(false)
            }
        }
        document.addEventListener("mousedown", clickedOutside)

        return () => {
            document.removeEventListener("mousedown", clickedOutside)
        }
    }, [showDescription])

        return (
        <QuestionMark>
            {showDescription && <Description>{description}</Description>}
            <QuestionMarkIcon
                width={20}
                height={20}
                onClick={(e) => {
                    e.stopPropagation();
                    if (onQuestionMarkClick) {
                        onQuestionMarkClick();
                    } else {
                        setDescription(!showDescription);
                    }
                }}
            />
        </QuestionMark>
    );
};

export default InputDescription;

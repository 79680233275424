import { useEffect } from "react";
import { useQueries, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import {
    setMainProduct,
    setShopUuid,
    setMinimalMonths,
    addAdditionalProduct,
    addService,
    setProductCode,
    setIsSharedBasket
} from "../../features/basketSlice";


import ProductService from "../../services/ProductsService";
import { clearData } from "../../utils/functions";


const ProductLoaderShare = () => {
    const params = useParams();

    const additionaProductIdArr = params.additionaProductId === 'empty' ? [] : params.additionaProductId.split(',')
    const servicesIdArr = params.servicesId === 'empty' ? [] : params.servicesId.split(',')


    const dispatch = useDispatch();


    useEffect(() => {
        clearData();
        // eslint-disable-line
    }, []);


    const {
        isSuccess: isSuccessMainProduct,
        isLoading: isLoadingMainProduct
    } = useQuery(
        ["main-product", params.product_code, params.shop_uuid],
        ProductService.getMainProductData(
            params.product_code,
            params.shop_uuid
        ),
        {
            onSuccess: (data) => {
                dispatch(setMainProduct(data));
                dispatch(setShopUuid(params.shop_uuid));
                dispatch(setMinimalMonths(Number(params.min_months)))
                dispatch(setProductCode(params.product_code))
                dispatch(setIsSharedBasket(true))

            }
        }

    )

    const queryAdditionalProductsResults = useQueries(
        additionaProductIdArr.map(id => {
            return {
                queryKey: ['user', id],
                queryFn: ProductService.getProductDetails(id, params.shop_uuid, params.min_months, params.mainProductId),
                onSuccess: (data) => {
                    dispatch(addAdditionalProduct(data))
                },
                enabled: isSuccessMainProduct
            }
        })
    )

    const queryServicesResults = useQueries(
        servicesIdArr.map(id => {
            return {
                queryKey: ['service', id],
                queryFn: ProductService.getProductDetails(id, params.shop_uuid, params.min_months, params.mainProductId),
                onSuccess: (data) => {
                    dispatch(addService(data))
                },
                enabled: isSuccessMainProduct
            }
        })
    )

    const allAdditionalProductsQueriesSuccess = queryAdditionalProductsResults.every((result) => result.isSuccess);
    const allAdditionalProductsQueriesLoading = queryAdditionalProductsResults.some((result) => result.isLoading);

    const allServicesQueriesSuccess = queryServicesResults.every((result) => result.isSuccess);
    const allServicesQueriesLoading = queryServicesResults.some((result) => result.isLoading);

    const isSuccess = isSuccessMainProduct && allAdditionalProductsQueriesSuccess && allServicesQueriesSuccess
    const isLoading = isLoadingMainProduct || allAdditionalProductsQueriesLoading || allServicesQueriesLoading


    return (
        <>
            {
                isSuccess && <Redirect to="/basket" />
            }
            {
                isLoading && <Loader fixed />
            }
        </>
    )
};

export default ProductLoaderShare;

import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Buttons/Button";
import Container from "../../components/Container/Container";
import BackArrowIcon from "../../components/Icons/components/BackArrowIcon";
import Layout from "../../components/Layout/Layout";
import SearchBar from "../../components/SearchBar/SearchBar";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import CategoryBox from "./components/CategoryBox";
import ProductBreadcrump from "./components/ProductBreadcrump";

const CategoryBoxContainer = styled.div`
    margin-bottom: 10px;
`;
const Footer = styled.div`
    margin-top: 32px;
    margin-bottom: 35px;
`;

const ProductsCollection = () => {
    return (
        <Layout>
            <SearchBar />
            <Container>
                <ProductBreadcrump
                    list={[
                        // {
                        //     label: "Rekomendowane",
                        //     link: "/products-collections",
                        // },
                        // {
                        //     label: "Kolekcja",
                        //     link: "/products-collections",
                        // },
                    ]}
                />
                <SectionHeader marginBottom={30}>
                    Kolekcja akcesoriów i produktów dodatkowych
                </SectionHeader>

                <CategoryBoxContainer>
                    <CategoryBox url={"/products-collections/wzmacniacze"} />
                </CategoryBoxContainer>

                <CategoryBoxContainer>
                    <CategoryBox url={"/products-collections/wzmacniacze"} />
                </CategoryBoxContainer>

                <CategoryBoxContainer>
                    <CategoryBox url={"/products-collections/wzmacniacze"} />
                </CategoryBoxContainer>
                <Footer>
                    <Link to="/basket">
                        <Button LeftIcon={<BackArrowIcon />}>Wstecz</Button>
                    </Link>
                </Footer>
            </Container>
        </Layout>
    );
};

export default ProductsCollection;

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DownIcon from "../../../components/Icons/components/DownIcon";
import NextArrowIcon from "../../../components/Icons/components/NextArrowIcon";
import PlusIcon from "../../../components/Icons/components/PlusIcon";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { selectLocation } from "../../../features/basketSlice";

const LocationBox = () => {
    const location = useSelector(selectLocation);
    return (
        <StyledLocationBox>
            <Header>
                <DownIcon className='icon' width={20} height={20} />
                <Text>Punkt odbioru lub wysyłki sprzętu</Text>
                <Tooltip description={'W przypadku wysyłki kurierskiej, skontaktujemy się z Tobą osobiście.'} />
            </Header>
            <Box>
                {
                    location ? (
                        <>
                            <NextArrowIcon className='icon' />
                            <div>
                                <div>{location.shop_name}</div>
                                <div>
                                    ul. {location.street}
                                </div>
                                <div>
                                    {location.city}, {location.postal_code}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <StyledLink to="/select-place">
                                Wybierz punkt odbioru
                                <PlusIcon className='icon' width={20} height={20} />
                            </StyledLink>
                        </>
                    )
                }
            </Box>
        </StyledLocationBox>
    );
};

export default LocationBox;

const StyledLocationBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 17px;
    .icon {
        color: ${({ theme }) => theme.primary};
    }
`

const Header = styled.div`
    display: flex;
    gap: 10px;
`
const Text = styled.div`
    font-weight: bold;
`

const Box = styled.div`
    display: flex;
    gap: 20px;
`

const StyledLink = styled(Link)`
        display: flex;
        align-items: center;
        gap: 20px;
        .icon {
        color: ${({ theme }) => theme.primary};
    }
`



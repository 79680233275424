import React from 'react'
import styled from 'styled-components'
import CurrencyIcon from '../../Icons/components/CurrencyIcon'
import Tooltip from '../../Tooltip/Tooltip'

const Deposit = ({ price = '' }) => {
    return (
        <StyledDeposit>
            <Info>
                <Icon>
                    <CurrencyIcon width={20} height={20} />
                </Icon>
                <Text>
                    Jednorazowa kaucja zwrotna:
                </Text>
                <Tooltip className='tooltip' description="Jednorazowa kaucja zwrotna służy zabezpieczeniu kosztów ewentualnych uszkodzeń jakie mogą powstać w trakcie trwania umowy najmu lub zaległości w rozliczeniach z tytułu wynajmu. Kaucja jest zwracana na konto Klienta niezwłocznie po zakończeniu i rozliczeniu umowy najmu, a w przypadku wykupu sprzętu może być zaliczona na poczet ceny wykupu." />
            </Info>
            <Price>
                {`${price} zł`}
            </Price>
        </StyledDeposit>
    )
}

export default Deposit

const StyledDeposit = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    .tooltip {
        margin: 0 !important;
    }
`
const Info = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

`
const Icon = styled.div`
    color: ${props => props.theme.primary};
    display: flex;
`
const Text = styled.div`
    font-size: 17px;
`
const Price = styled.div`
    font-weight: bold;
    font-size: 18px;
    margin-left: auto;
    white-space: nowrap;
`
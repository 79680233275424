import React, { useRef } from 'react'
import ReactDOM from "react-dom";
import styled from 'styled-components';

const Modal = ({ show, closeModal, children, ...props }) => {

    const ref = useRef(null)

    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            closeModal()
        }
    }



    if (!show) return null
    return ReactDOM.createPortal(
        <Overlay onClick={handleClick}>
            <Content ref={ref} {...props}>
                {children}
            </Content>
        </Overlay>,
        document.getElementById('portal')
    )

}

export default Modal


const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`

const Content = styled.div`
    background-color: white;
    max-width: 400px;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 4px 2px rgba(0,0,0,0.15);
    margin: 10px;
`
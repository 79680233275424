import styled from "styled-components";

const Root = styled.input`
    padding: 14px 22px;
    font-weight: bold;
    outline: none;
    border: ${({ theme }) => `1px solid ${theme.primary}`};
    border-radius: 22px;
    letter-spacing: 0.025em;
  font-size: 14px;
`;

const TextInput = ({ register, ...props }) => {
    return <Root {...register} {...props} />;
};

export default TextInput;

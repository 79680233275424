import styled from "styled-components";
import NextArrowIcon from "../Icons/components/NextArrowIcon";
import { Root } from "./Root";

const IconRoot = styled(Root)`
    width: 50px;
    height: 50px;
    justify-content: center;
`;

const IconButton = ({ Icon = <NextArrowIcon />, ...props }) => (
    <IconRoot {...props}>{Icon}</IconRoot>
);

export default IconButton;

import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import Loader from "../../../components/Loader/Loader";
import { setTransaction } from "../../../features/transactionSlice";
import Step6Service from "../../../services/Step6Service";


const ConfirmPaymentAgainLink = () => {
    const { token1, token2, schedule_id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();


    const { mutate } = useMutation(Step6Service.payAgainLogin(token1, token2), {
        onSuccess: (data) => {
            dispatch(setTransaction(data));
            history.push(`/online-repayment/${schedule_id}`)
        },
        onError() {
            history.push(`/link-inactive`)
        }
    });
    useEffect(mutate, [mutate]);

    return (
        <Loader fixed />
    );
};

export default ConfirmPaymentAgainLink;

import React, {useEffect, useState} from "react";
import styled from "styled-components";
import DoneIcon from "../../../components/Icons/components/DoneIcon";
import { RENT_ROUTES } from "../RentSteps";
import { useLocation } from 'react-router-dom';

const Li = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Ul = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    list-style: none;
    gap: 10px;
    &:before {
        content: "";
        display: block;
        position: absolute;

        height: 2px;
        border-bottom: 2px solid ${({ theme }) => theme.primary};
        top: 15px;
        left: 10%;
        right: 10%;
        z-index: 0;
    }
`;
const DoneContainer = styled.div`
    display: flex;
    color: ${({ theme }) => theme.primary};
`;
const ActiveCircle = styled.div`
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    background: ${({ theme }) => theme.primary};
`;
const Circle = styled.div`
    width: 35px;
    height: 35px;
    position: relative;
    margin: auto;
    display: flex;
    margin-bottom: 18px;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 50%;
    color: #798090;
`;
const Label = styled.div`
    text-align: center;
    font-size: 0.7142857142857143em;
`;

const Content = styled.div`
    display: inline-block;
    // background: white;
    & ${Circle} {
        border: ${({ active, index, theme }) =>
        active >= index
            ? `2px solid ${theme.primary}`
            : "1px solid #798090"};
    }
`;
const RentNavigation = ({
    finishedSteps = [true, false, false, false],
}) => {
    const [step, setStep] = useState(0);
    const [visible, setVisible] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setStep(RENT_ROUTES.findIndex(({ path }) => path === location.pathname))
        setVisible(!!RENT_ROUTES.find(({ path }) => path === location.pathname))
    }, [location]);

    return (
        <div>
        {visible && (
            <Ul>
                {RENT_ROUTES.map(({ label }, index) => (
                <Li key={index}>
                    <Content active={step} index={index}>
                        <Circle>
                            {index < step && (
                                <DoneContainer>
                                    <DoneIcon />
                                </DoneContainer>
                            )}
                            {step === index && <ActiveCircle />}
                            {index > step && <span>{index + 1}</span>}
                        </Circle>
                        <Label>{label}</Label>
                    </Content>
                </Li>
                ))}
            </Ul>
            )}
        </div>
    );
}

export default RentNavigation;

import React, { useMemo, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import RecommendedProductsService from '../../services/RecommendedProductsService'
import { useSelector } from 'react-redux'
import {
    selectMainProduct,
    selectShopUuid,
    selectMinimalMonthsParsed
} from "../../features/basketSlice";
import Loader from '../../components/Loader/Loader'
import CategoryRow from './components/CategoryRow'
import { selectMobile } from '../../features/appSlice'
import { Link, useParams } from 'react-router-dom'
import Button from '../../components/Buttons/Button'
import BackArrowIcon from '../../components/Icons/components/BackArrowIcon'
import MainProduct from './components/MainProduct'
import CompanyRow from '../../components/CompanyRow/CompanyRow'
import PeriodRow from '../../components/PeriodRow/PeriodRow'
import allAccessoriesImage from './all_accessories.jpeg';
import allServicesImage from './all_services.jpeg'
import Search from '../../components/Search/Search'
import ProductRow from '../../components/Product/ProductTitle/ProductRow'
import ProductRowMobile from '../../components/Product/ProductTitle/ProductRowMobile'



const CategoryType = () => {
    const { type } = useParams()

    const isMobile = useSelector(selectMobile)
    const shopUuid = useSelector(selectShopUuid);
    const mainProduct = useSelector(selectMainProduct);
    const minimalMonths = useSelector(selectMinimalMonthsParsed);

    const [value, setValue] = useState('')
    const [items, setItmes] = useState([])

    const TYPE = useMemo(() => {
        return type.toUpperCase()
    }, [type])


    // ------------------------------------------------------------------
    // --------- CREATE SECTION HEADER NAME BY TYPE FROM PARAMS ---------
    // ------------------------------------------------------------------

    const sectionHeader = useMemo(() => {
        switch (type) {
            case 'accessories': return 'Kolekcja akcesoriów'
            case 'all_services': return 'Kolekcja usług'
            default: return ''
        }
    }, [type])


    // ------------------------------------------------------------------------
    // --------- CREATE LAST ROW DATA FOR ALL ACCESSORIES/SERVICES ------------
    // ------------------------------------------------------------------------

    const allCategoryObj = useMemo(() => {
        switch (type) {
            case 'accessories':
                return {
                    name: 'Wszystkie akcesoria',
                    imageUrl: allAccessoriesImage
                }
            case 'all_services':
                return {
                    name: 'Wszystkie usługi',
                    imageUrl: allServicesImage
                }
            default: return ''
        }
    }, [type])


    // ----------------------------------------------------
    // --------- GET ALL PRODUCT FROM API -----------------
    // ----------------------------------------------------

    const {
        // eslint-disable-next-line
        data: dataProducts,
        isLoading: isLoadingProducts,
        isSuccess: isSuccessProducts
    } = useQuery(
        [`categoryProducts`, TYPE, shopUuid, minimalMonths, mainProduct.id, {}],
        RecommendedProductsService.getAllCategoryProducts(TYPE, shopUuid, minimalMonths, mainProduct.id, {}),
        {
            onSuccess: (data) => {
                setItmes(data)
            },
            select: (data => data.items)
        }
    )

    // ---------------------------------------------------------------
    // --------- FILTER ALL PRODUCT BY SEARCH INPUT VALUE ------------
    // ---------------------------------------------------------------

    const filteredItems = useMemo(() => {
        return value ? items.filter(item => item.details.name.toLowerCase().includes(value.toLowerCase())) : []
    }, [value, items])


    // ---------------------------------------------------------
    // --------- GET RECCOMENDED CATEGORIES FROM API -----------
    // ---------------------------------------------------------

    const {
        data: dataRecommendedCategories,
        isLoading: isLoadingRecommendedCategories,
        isSuccess: isSuccessRecommendedCategories
    } = useQuery(
        [`recommended-categories`, TYPE, shopUuid, mainProduct.id],
        RecommendedProductsService.getAllRecommendedCategories(TYPE, shopUuid, mainProduct.id),
        {
            select: (data => data.data)
        }
    )

    // ---------------------------------------------------------
    // --------- REDNER STATUSES -------------------------------
    // ---------------------------------------------------------

    const isLoading = isLoadingRecommendedCategories || isLoadingProducts
    const isSuccess = isSuccessRecommendedCategories && isSuccessProducts

    return (
        <Layout desktopTitle="Konfigurator oferty" >
            {
                (isLoading || isLoadingProducts) && <Loader fixed />
            }

            {
                isMobile ? (
                    <Mobile>
                        <Header>{sectionHeader}</Header>
                        <Search setValue={setValue} />
                        {
                            isSuccess && (filteredItems.length === 0
                                ? dataRecommendedCategories.map((category, index) => <CategoryRow key={index} category={category} />)
                                : filteredItems.map((product, index) => <ProductRowMobile key={index} product={product} type={type} />))
                        }
                        <CategoryRow category={allCategoryObj} />
                        <Buttons>
                            <Link to='/basket'>
                                <Button buttonType='outline' LeftIcon={<BackArrowIcon />}>
                                    Powrót do koszyka
                                </Button>
                            </Link>
                        </Buttons>
                    </Mobile>
                ) : (
                    <Desktop>
                        <Header>{sectionHeader}</Header>
                        <Row>
                            <Col1>
                                <Search setValue={setValue} />
                                {
                                    isSuccess && (filteredItems.length === 0
                                        ? dataRecommendedCategories.map((category, index) => <CategoryRow key={index} category={category} />)
                                        : filteredItems.map((product, index) => <ProductRow key={index} product={product} type={type} />))
                                }
                                <CategoryRow category={allCategoryObj} />

                            </Col1>
                            <Col2>
                                <Col1Background>
                                    {/* IS COMPANY*/}
                                    <CompanyRow />

                                    {/* PERIOD */}
                                    <PeriodRow />

                                    {/* MAIN PRODUCT */}
                                    <MainProduct />

                                    {/* BUTTONS */}
                                    <Buttons>
                                        <Link to='/basket'>
                                            <Button buttonType='outline' LeftIcon={<BackArrowIcon />}>
                                                Powrót do koszyka
                                            </Button>
                                        </Link>
                                    </Buttons>
                                </Col1Background>
                            </Col2>
                        </Row>
                    </Desktop>
                )
            }
        </Layout>
    )
}

export default CategoryType

const Col1 = styled.div`
    flex: 5;
    display:flex;
    flex-direction: column;
    gap: 20px;
`

const Col2 = styled.div`
    flex: 3;
    display:flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 20px;
`

const Desktop = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`
const Row = styled.div`
    display: flex;
    gap: 20px;
`

const Col1Background = styled.div`
    background-color: ${props => props.theme.white};
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 20px;
    border-radius: 20px;
`


const Buttons = styled.div`
`

const Mobile = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Header = styled.div`
    font-weight: bold;
    font-size: 28px;
`

import { Link } from "react-router-dom";
import styled from "styled-components";
import NextIcon from "../../../components/Icons/components/NextIcon";
import ProductImage from "../../../components/ProductBox/ProductImage";

const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 10px;
    background-color: ${({ theme }) => theme.white};
    border-radius: 15px;
    transition: all 0.4s;
    &:hover {
        opacity: 0.6;
    }
    strong {
        flex: 1;
        margin: 0 20px;
    }
`;

const CategoryBox = ({url}) => (
    <Link to={url}>
        <Root>
            <ProductImage padding={0}/>
            <strong>Wzmacniacze</strong>
            <NextIcon width={20} height={20} color="#798090"/>
        </Root>
    </Link>
);

export default CategoryBox;

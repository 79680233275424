import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Container from "../../components/Container/Container";
import Layout from "../../components/Layout/Layout";
import PlaceBox from "../../components/PlaceBox/PlaceBox";
import SearchBar from "../../components/SearchBar/SearchBar";
import { selectPickupPoints, setLocation } from "../../features/basketSlice";
import ProductBreadcrump from "../AddProduct/components/ProductBreadcrump";
import {Link} from "react-router-dom";
import Button from "../../components/Buttons/Button";
import BackArrowIcon from "../../components/Icons/components/BackArrowIcon";


const Footer = styled.div`
    margin-top: 32px;
    margin-bottom: 35px;
    display: flex;
    gap: 10px;
    a,
    button {
        flex: 1;
    }
`;

const Title = styled.div`
    font-size: 1.4285714285714286em;
    margin: 15px 0 42px;
`;
const PlaceContainer = styled.div`
    margin-bottom: 10px;
`;

const Location = () => {
    const pickuPoints = useSelector(selectPickupPoints);

    const dispatch = useDispatch();
    const history = useHistory();

    const handleSetLocation = (location) => {
        dispatch(setLocation(location));
        history.goBack()
    };
    return (
        <Layout>
            <SearchBar />

            <Container>
                <Container>
                    <ProductBreadcrump
                        list={[
                            {
                                label: "Punkty odbioru / wysyłki",
                                link: "/select-place",
                            },
                        ]}
                    />
                    <Title>
                        <strong>Wybierz punkt odbioru sprzętu</strong>
                    </Title>
                    {pickuPoints.map((location, index) => (
                        <PlaceContainer key={index}>
                            <PlaceBox
                                location={location}
                                onClick={() => handleSetLocation(location)}
                            />
                        </PlaceContainer>
                    ))}
                </Container>
                <Footer>
                    <Link to="/basket">
                        <Button LeftIcon={<BackArrowIcon />}>Wstecz</Button>
                    </Link>
                </Footer>
            </Container>

        </Layout>
    );
};

export default Location;

const CardIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 69 44"
        {...props}
    >
        <g transform="translate(-50 -1045)">
            <g transform="translate(50 1045)" fill="#fff" stroke="#798090">
                <rect width={69} height={44} rx={5} stroke="none" />
                <rect
                    x={0.5}
                    y={0.5}
                    width={68}
                    height={43}
                    rx={4.5}
                    fill="none"
                />
            </g>
            <ellipse
                cx={14}
                cy={14.5}
                rx={14}
                ry={14.5}
                transform="rotate(180 53.5 541)"
                fill="#ffd500"
            />
            <ellipse
                cx={14}
                cy={14.5}
                rx={14}
                ry={14.5}
                transform="rotate(180 44.5 541)"
                fill="red"
            />
        </g>
    </svg>
);

export default CardIcon;

import styled from "styled-components";

const Root = styled.div`
    text-align: center;
    padding: 16px 0;
`;

const SearchNotFound = () => <Root>Brak wyników wyszukiwania</Root>;

export default SearchNotFound;

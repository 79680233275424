import { useSelector } from "react-redux";
import { selectMobile } from "../../features/appSlice";
import LocationDesktop from "./LocationDesktop";
import LocationMobile from "./LocationMobile";

const ProductsCategories = ({ navProps }) => {
    const isMobile = useSelector(selectMobile);

    if (isMobile) {
        return <LocationMobile navProps={navProps} />;
    }
    return <LocationDesktop navProps={navProps} />;
};

export default ProductsCategories;

import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Loader from "../../../components/Loader/Loader";
import SectionHeader from "../../../components/SectionHeader/SectionHeader";
import Tooltip from "../../../components/Tooltip/Tooltip";
import {
    selectAdditionalProducts,
    selectIsCompany,
    selectMainProduct,
    selectMinimalMonthsParsed,
    selectServicesBefore,
    selectServicesDuring,
} from "../../../features/basketSlice";
import ProductService from "../../../services/ProductsService";
import { polishPlural } from "../../../utils/functions";

const Root = styled.div`
    /* padding: 35px 20px; */
    background-color: ${({ theme }) => theme.white};
    border-radius: 25px;
    @media screen and (max-width: 500px) {
        font-size: 0.9285714285714286em;
    }
`;

const Title = styled.div`
    margin-bottom: 12px;
`;
const Row = styled.div`
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
`;

const PriceContainer = styled.div`
    margin-top: 14px;
    padding-top: 18px;
    border-top: 1px solid black;
    line-height: 26px;
    /* font-weight: bold; */
    text-align: right;
`;
const Price = styled.div`
    font-size: 17px;
    font-weight:bold;
`;
const NetPrice = styled.div`
    color: #798090;
`;

const Icon = styled.div`
    margin-left: 1rem;
    display: flex;
`;

const SummaryBox = ({ setIsDisableButton }) => {
    const mainProduct = useSelector(selectMainProduct);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    // const real_months = useSelector(selectRealMonthsParsed);
    const additionalProducts = useSelector(selectAdditionalProducts);
    const servicesBefore = useSelector(selectServicesBefore);
    const servicesDuring = useSelector(selectServicesDuring);
    const isCompany = useSelector(selectIsCompany);

    const parseList = ({ id }) => ({ id, quantity: 1 });

    const query = {
        main_product_id: mainProduct.id,
        minimal_months,
        real_months: minimal_months,
        additional_products: additionalProducts.map(parseList),
        services_before: servicesBefore.map(parseList),
        services_during: servicesDuring.map(parseList),
        isCompany,
    };


    const [data, setData] = useState(null);

    useQuery(["summary", query], ProductService.getCalculation(query), {
        select: (data) => {
            if (isCompany) {
                return data.company_option.option_formatted;
            } else {
                return data.natural_person_option.option_formatted;
            }
        },
        onSuccess: (data) => {
            setIsDisableButton(false)
            setData(data);
        },
    });

    if (!data)
        return (
            <Root>
                <Loader />
            </Root>
        );
    return (
        <Root>
            <Title>
                <SectionHeader marginBottom={12}>Podsumowanie:</SectionHeader>
            </Title>

            <Row>
                <div>Całkowita wartość koszyka</div>
                <strong>{data.total_gross_price} zł</strong>
            </Row>
            <Row>
                <div>Minimalny okres najmu</div>
                <strong>
                    {minimal_months}{" "}
                    {polishPlural(
                        "Miesiąc",
                        "Miesiące",
                        "Miesięcy",
                        minimal_months
                    )}
                </strong>
            </Row>
            <Row>
                <Row>
                    Jednorazowa kaucja zwrotna
                    <Icon>
                        <Tooltip
                            description={`Jednorazowa kaucja zwrotna służy zabezpieczeniu kosztów ewentualnych uszkodzeń jakie mogą powstać w trakcie trwania umowy najmu lub zaległości w rozliczeniach z tytułu wynajmu. Kaucja jest zwracana na konto Klienta niezwłocznie po zakończeniu i rozliczeniu umowy najmu, a w przypadku wykupu sprzętu może być zaliczona na poczet ceny wykupu.`}
                        />
                    </Icon>
                </Row>
                <strong>{data.net_administrative_fee} zł</strong>
            </Row>
            <PriceContainer>
                <Row>
                    <Price >Miesięczna rata najmu brutto</Price>
                    <Price>{data.gross_month_cost} zł brutto</Price>
                </Row>

                <Row>
                    <div>Miesięczna rata najmu netto</div>
                    <NetPrice>{data.net_month_cost} zł netto</NetPrice>
                </Row>
            </PriceContainer>
        </Root>
    );
};

export default SummaryBox;

const Building = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg"
        width="86.083" height="93.48"
        viewBox="0 0 86.083 93.48"
        stroke={props.color ? props.color : '#0486D6'}
        {...props}
    >
        <g id="Group_1335" data-name="Group 1335" transform="translate(-23.917 -19.493)">
            <rect id="Rectangle_838" data-name="Rectangle 838" width="49.01" height="69.401" transform="translate(25.917 41.573)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <rect id="Rectangle_839" data-name="Rectangle 839" width="35.988" height="10.04" transform="translate(32.428 31.533)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_77" data-name="Line 77" x1="6.524" transform="translate(33.781 50.765)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_78" data-name="Line 78" x1="6.524" transform="translate(47.16 50.765)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_79" data-name="Line 79" x1="6.524" transform="translate(60.538 50.765)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_80" data-name="Line 80" x1="6.524" transform="translate(33.781 59.126)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_81" data-name="Line 81" x1="6.524" transform="translate(47.16 59.126)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_82" data-name="Line 82" x1="6.524" transform="translate(60.538 59.126)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_83" data-name="Line 83" x1="6.524" transform="translate(33.781 67.488)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_84" data-name="Line 84" x1="6.524" transform="translate(47.16 67.488)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_85" data-name="Line 85" x1="6.524" transform="translate(60.538 67.488)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_86" data-name="Line 86" x1="6.524" transform="translate(33.781 75.85)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_87" data-name="Line 87" x1="6.524" transform="translate(47.16 75.85)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_88" data-name="Line 88" x1="6.524" transform="translate(60.538 75.85)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_89" data-name="Line 89" x1="6.524" transform="translate(33.781 84.211)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_90" data-name="Line 90" x1="6.524" transform="translate(47.16 84.211)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_91" data-name="Line 91" x1="6.524" transform="translate(60.538 84.211)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <rect id="Rectangle_840" data-name="Rectangle 840" width="26.757" height="17.706" transform="translate(37.043 93.267)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <rect id="Rectangle_841" data-name="Rectangle 841" width="22.088" height="10.04" transform="translate(39.378 21.493)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_92" data-name="Line 92" y2="17.706" transform="translate(50.422 93.267)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <path id="Path_1024" data-name="Path 1024" d="M111.261,95.252a5.923,5.923,0,1,0-10.354,0,8.376,8.376,0,1,0,10.354,0Z" transform="translate(-11.762 -10.643)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_93" data-name="Line 93" y2="10.541" transform="translate(94.322 99.562)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <line id="Line_94" data-name="Line 94" x1="35.512" transform="translate(72.489 110.974)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
        </g>
    </svg>


);

export default Building;

const LinkedinIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 30 30"
        {...props}
    >
        <path
            data-name="Path 135"
            d="M27.286 0H2.714A2.714 2.714 0 000 2.714v24.572A2.714 2.714 0 002.714 30h24.572A2.714 2.714 0 0030 27.286V2.714A2.714 2.714 0 0027.286 0zm-18 25.9a.79.79 0 01-.79.79H5.132a.79.79 0 01-.79-.79V11.812a.79.79 0 01.79-.79h3.362a.79.79 0 01.79.79zM6.813 9.694A3.194 3.194 0 1110.006 6.5a3.194 3.194 0 01-3.193 3.194zm20.039 16.274a.726.726 0 01-.726.726h-3.608a.726.726 0 01-.726-.726v-6.61c0-.986.289-4.321-2.577-4.321-2.223 0-2.674 2.283-2.765 3.307v7.624a.726.726 0 01-.726.726h-3.489a.726.726 0 01-.726-.726v-14.22a.726.726 0 01.726-.726h3.489a.726.726 0 01.726.726v1.229c.824-1.237 2.05-2.192 4.658-2.192 5.776 0 5.743 5.4 5.743 8.362v6.82z"
            fill="#0077b7"
        />
    </svg>
);

export default LinkedinIcon;

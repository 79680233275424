import { useMemo } from "react";
import styled from "styled-components";
import Badge from "../../Badge/Badge";
import Tooltip from "../../Tooltip/Tooltip";

const Root = styled.div`
    padding: 20px 25px;
    border-bottom: 1px solid #ebecee;
    margin: 0 -25px 20px;
`;
const Label = styled.div`
    font-size: 0.7142857142857143em;
    line-height: 13px;
    text-transform: uppercase;
    color: #798090;
`;
const Name = styled.div`
    font-size: 28px;

`;
const Availability = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;
`;
const BadgeContainer = styled.div`
    margin: 0 8px 0 4px;
`;

const ProductTitle = ({
    label,
    name,
    availabilityText,
    availabilitySymbol,
}) => {
    const badgeType = useMemo(() => {
        if (availabilitySymbol === "AVAILABLE") {
            return "primary";
        }
        else {
            return "danger";
        }
    }, [availabilitySymbol]);

    const availabilityTextMapped = useMemo(() => {
        if (availabilitySymbol === "BACKORDER") {
            return "Na zamówienie";
        }
        return availabilityText;
    }, [availabilitySymbol, availabilityText]);

    return (
        <Root>
            {label && (
                <Label>
                    <strong style={{ fontSize: "12px" }}>{label}</strong>
                </Label>
            )}
            {name && (
                <Name>
                    <strong>{name}</strong>
                </Name>
            )}
            {availabilityText && (
                <Availability>
                    <span>Dostępność:</span>
                    <BadgeContainer>
                        <Badge type={badgeType}>{availabilityTextMapped}</Badge>
                    </BadgeContainer>
                    <Tooltip description="Jeśli produkt jest dostępny, oznacza to, że znajduje się w magazynie i jest gotowy do wydania lub wysyłki “od ręki”. W przypadku gdy produkt jest dostępny na zamówienie, czas dostawy może być zróżnicowany w zależności od rodzaju i marki produktu. W takich przypadkach zalecamy kontakt z nami w celu potwierdzenia możliwego terminu odbioru/wysyłki." />
                </Availability>
            )}
        </Root>
    );
};

export default ProductTitle;

import React from 'react';
import Button from "../../../../components/Buttons/Button";
import {useMutation} from "react-query";
import Step6Service from "../../../../services/Step6Service";
import {toast} from "react-toastify";
import TextInput from "../../../../components/Inputs/TextInput";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {selectMobile} from "../../../../features/appSlice";
import {useForm} from "react-hook-form";
import checkMark from "./voucher-checked-icon.png";


const VoucherInput= ({transactionId, refetch, voucher, disabled = false}) => {
    const isMobile = useSelector(selectMobile);
    const {mutate} = useMutation(Step6Service.assignVoucher(), {
        onSuccess: () => {
            toast.success("Voucher został aktywowany")
            refetch()
        },
        onError: (error) => {
            toast.error("Wprowadzony kod jest nieprawidłowy, sprawdź i wprowadź go ponownie");
            reset({
                code: ""
            })
        },
    })
    const {mutate: removeVoucherMutate} = useMutation(Step6Service.removeVoucher(), {
        onSuccess: () => {
            toast.success("Voucher został usunięty")
            refetch()
            reset({
                code: ""
            })
        },
        onError: (error) => {
            toast.error("Wystąpił błąd. Nie udało się usunąć Vouchera.");
        },
    })

    const {
        register,
        handleSubmit,
        reset
    } = useForm();
    const assignVoucher = ({code}) => {
        mutate({
            code: code,
            transaction_id: transactionId
        });
    }

    const removeVoucher = () => {
        removeVoucherMutate({transaction_id: transactionId})
    }

    function instalmentsText(number) {
        const forms = ['rata', 'raty', 'rat', 'ratę'];

        if(number === null) {
            return 'raty'
        }

        if (number === 1) {
            return `${number} ${forms[3]}`;
        } else if (number >= 2 && number <= 4) {
            return `${number} ${forms[1]}`;
        } else if (number >= 5 && number <= 24) {
            return `${number} ${forms[2]}`;
        } else {
            return `${number} ${forms[2]}`; // dla liczb powyżej 24 również "rat"
        }
    }
    function preInstalmentsText(number) {

        if (number === 1) {
            return `Twoją pierwszą`;
        } else if(number === null) {
            return '';
        } else {
            return `Twoje pierwsze`;
        }
    }

    return (
        <GeneralWrapper>
            <Wrapper isMobile={isMobile}>
                <Container isMobile={isMobile}>
                    <StyledTextInput
                        placeholder={"Wprowadź kod vouchera lub kod promocyjny"}
                        register={register("code")}
                        defaultValue={voucher?.code}
                        approved={voucher?.code !== null}
                        disabled={voucher?.code !== null}
                    />
                    {voucher?.code !== null &&
                        <CheckMarkIcon src={checkMark} alt={"checkmark"}/>
                    }
                    {voucher?.code !== null && !disabled &&
                        <StyledButton buttonType="outline" type="submit" onClick={handleSubmit(removeVoucher)}>Usuń Voucher</StyledButton>
                    }
                    {voucher?.code === null &&
                        <StyledButton buttonType="outline" type="submit" onClick={handleSubmit(assignVoucher)}>Aktywuj</StyledButton>
                    }
                </Container>
        </Wrapper>
            {voucher?.code !== null && (
                <Explainer>
                    Zastosowany voucher pozwala obniżyć {preInstalmentsText(voucher?.maxInstallments)} <strong>{instalmentsText(voucher?.maxInstallments)}</strong> o wartość <strong>{voucher?.amount} zł</strong> za każdą z objętych voucherem rat, przy czym <strong>obniżka każdej z rat nie może być większa niż wartość raty. </strong>

                    {voucher?.minProductPrice > 0 &&
                        <span>Minimalna wartość produktu objętego voucherem to <strong>{voucher?.minProductPrice} zł. </strong></span>
                    }
                    Voucher nie obniża wartości kaucji zwrotnej i obniża raty pod warunkiem, że jest aktywny. Szczegóły znajdziesz w regulaminie voucherów VIBE na stronie <a href="https://vibe.pl/">vibe.pl</a>
                </Explainer>
            )}
        </GeneralWrapper>
    )
}

const Container = styled.div`
     display: flex;
    flex-direction: ${props => props.isMobile ? 'column' : 'row'};
    gap: 20px;
    width: 100%;
    margin-top: 1rem;
    position: relative;
`;

const StyledButton = styled(Button)`
    flex: 1 1 25%;
    min-width: 200px;
`;

const StyledTextInput = styled(TextInput)`
    flex: 1 1 75%;
    color: ${props => props.approved ? '#00733C' : 'inherit'};
    padding-left: ${props => props.approved ? '42px' : '22px'};
`;

const GeneralWrapper = styled.div`
    width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  max-width: ${props => props.isMobile ? '100%' : '580px'}
`

const CheckMarkIcon = styled.img`
  position: absolute;
  left: 15px;
  top: 13px;
  width: 20px; 
  height: 20px;
`

const Explainer = styled.div`
  margin-top: 20px;
  width: 100%;
  background: #F7F7F7;
  text-align: left;
  font-size: 14px;
  padding: 8px 12px;
  line-height: 24px;
`;

export default VoucherInput;
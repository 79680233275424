import { Link } from "react-router-dom";
import styled from "styled-components";
import { badgeType } from "../../utils/functions";
import Badge from "../Badge/Badge";
import CloseIcon from "../Icons/components/CloseIcon";
import ProductImage from "./ProductImage";
import { useSelector } from "react-redux";
import { selectMobile } from "../../features/appSlice";
import FlexBox from '../FlexBox/FlexBox'

const Root = styled.div`
    display: flex;
    align-items: ${(props) => (props.isMobile ? "flex-start" : "center")};
    background-color: ${({ theme }) => theme.white};
    padding: 20px;
    border-radius: 25px;
    a {
        display: flex;
        align-items: flex-start;
        flex: 1;
    }
`;

const DescriptionContainer = styled.div`
    flex: 1;
    margin-left: 15px;
    margin-right: 15px;
    line-height: 19px;
`;
const Name = styled.div`
    font-size:17px;
`;
const TextRow = styled.div`
    font-size:14px;
`;
const BadgeText = styled.span`
    font-size: 8px;
    font-weight: 900;
    display: flex;
    line-height: 0;
    padding: 5px 0;
`;

const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 5px;
    color: #798090;
    text-transform: uppercase;
    font-size: 12px;
    & > div:first-child {
        margin-right: 10px;
    }
    & > div:nth-child(2) {
        margin-right: 8px;
    }
`;
const RemoveContainer = styled.div`
    display: flex;
    margin: ${(props) => (props.isMobile ? "-10px;" : "0 0 0 14px")};
    padding: ${(props) => (props.isMobile ? "10px;" : "0px")};
    font-size: 20px;
    color: ${({ theme }) => theme.primary};
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
        opacity: 0.5;
    }
`;

// const FlexBox = styled.div`
//     display: flex;
//     align-items: center;
//     ${(props) => props.direction && `flex-direction: ${props.direction};`}
//     ${(props) =>
//         props.justifyContent && `justify-content: ${props.justifyContent};`}
//     ${(props) => props.flex && `flex: ${props.flex};`}
// `;
const StyledText = styled.div`
    color: #798090;
    font-weight: bold;
    font-size: 10px;
`;

const SmallFont = styled.div`
    font-size: 8px;
`;

const ProductBox = ({
    name,
    quantity,
    installment,
    availabilitySymbol,
    availability,
    status,
    price,
    onRemove = null,
    url = "/",
    imageSize,
    imageSrc,
    hidePrice = false
}) => {
    const isMobile = useSelector(selectMobile);

    const availabilityTextMapped = () => {
        if (availabilitySymbol === "BACKORDER") {
            return "Na zamówienie";
        }
        return availability;
    };

    return (
        <Root isMobile={isMobile}>
            <Link to={url}>
                {/* <ImageContainer>
                <Image src="https://muzyczny.pl/towary/238288.jpg" alt={name} />
            </ImageContainer> */}
                {isMobile ? (
                    <>
                        <ProductImage size={imageSize} src={imageSrc} />
                        <DescriptionContainer>
                            <Name>
                                <strong>{name}</strong>
                            </Name>
                            {quantity && (
                                <TextRow>ilość: {quantity} szt.</TextRow>
                            )}
                            {installment && (
                                <TextRow>rata: {installment} zł</TextRow>
                            )}
                            <StatusContainer>
                                {availability && (
                                    <div>
                                        <Badge
                                            type={badgeType(availabilitySymbol)}
                                        >
                                            <BadgeText>
                                                {availabilityTextMapped()}
                                            </BadgeText>
                                        </Badge>
                                    </div>
                                )}
                                {status && <SmallFont>{status}</SmallFont>}
                                {price && !hidePrice && <SmallFont>Wartość: {price} zł</SmallFont>}
                            </StatusContainer>
                        </DescriptionContainer>
                    </>
                ) : (
                    //DESKTOP
                    <FlexBox justifyContent="space-between" flex="1" alignItems="center">
                        <FlexBox textAlign="left" alignItems="center">
                            <ProductImage size={imageSize} src={imageSrc} />
                            <DescriptionContainer>
                                <Name>
                                    <strong>{name}</strong>
                                </Name>
                                {quantity && (
                                    <TextRow>ilość: {quantity} szt.</TextRow>
                                )}
                                <StatusContainer>
                                    {availability && (
                                        <div>
                                            <Badge
                                                type={badgeType(
                                                    availabilitySymbol
                                                )}
                                            >
                                                <BadgeText>
                                                    {availabilityTextMapped()}
                                                </BadgeText>
                                            </Badge>
                                        </div>
                                    )}
                                    {status && <StyledText>{status}</StyledText>}
                                </StatusContainer>
                            </DescriptionContainer>
                        </FlexBox>
                        <FlexBox flexDirection="column">
                            {installment && (
                                <div style={{ marginBottom: "6px", whiteSpace: "nowrap" }}>
                                    Rata miesięczna:{" "}
                                    <strong style={{ fontSize: "18px" }}>
                                        {installment} zł
                                    </strong>
                                </div>
                            )}
                            {price && !hidePrice && (
                                <StyledText>Wartość: {price} zł</StyledText>
                            )}
                        </FlexBox>
                    </FlexBox>
                )}
            </Link>
            {onRemove && (
                <RemoveContainer onClick={onRemove} isMobile={isMobile}>
                    <CloseIcon />
                </RemoveContainer>
            )}
        </Root>
    );
};

export default ProductBox;

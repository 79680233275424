import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import Card from "../../components/Card/Card";
import FlexBox from "../../components/FlexBox/FlexBox";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import ProductDetails from "../../components/Product/ProductTitle/ProductDetails";
import ProductTitle from "../../components/Product/ProductTitle/ProductTitle";
import {
    addService,
    selectMinimalMonthsParsed,
    selectShopUuid,
    selectIsCompany, selectMainProduct
} from "../../features/basketSlice";
import ProductService from "../../services/ProductsService";
import Common from "../Common/Common";
import GalleryBox from "../Home/components/GalleryBox";


const Service = () => {
    const { type, id: productId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const shop_uuid = useSelector(selectShopUuid);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany)
    const mainProduct = useSelector(selectMainProduct);

    const [product, setProduct] = useState(null);

    const { isLoading } = useQuery(
        ["product", productId, shop_uuid, minimal_months, mainProduct.id],
        ProductService.getProductData(productId, shop_uuid, minimal_months, mainProduct.id),
        {
            onSuccess: (data) => {
                setProduct(data);
            },
        }
    );

    const handleAddService = () => {
        dispatch(addService({ ...product, ...{ type } }));
        history.push("/basket");
    };

    if (!product) {
        return <Loader fixed />;
    }
    return (
        <Layout desktopTitle="Usługi przed wynajmem i w trakcie trwania wynajmu">
            <FlexBox gap="1rem" textAlign={'left'}>
                <div style={{ flex: 5 }}>
                    <Card>
                        <FlexBox textAlign="left">
                            <ProductTitle
                                label=""
                                name={product.name}
                                availabilityText={product.availabilityText}
                                availabilitySymbol={product.availabilitySymbol}
                            />
                        </FlexBox>
                        <ProductDetails
                            location={product.localizations[0].city}
                            condition={product.conditionText}
                            service={true}
                            flexDirection='row'
                            flexWrap='wrap'
                            gap='1rem'
                        />
                        <GalleryBox photosArr={[product.image_url, ...product.imageUrls]} />
                        <ProductDetails
                            description={product.description}
                            openDefault={true}
                        // extraContent={
                        //     <ProductDetails
                        //         price={product.formattedGrossPrice}
                        //     />
                        // }
                        />
                    </Card>
                </div>
                <div style={{ flex: 3 }}>
                    <Common
                        productToCalculateLoading={isLoading}
                        productToCalculate={isCompany ? product.calculations.company_option : product.calculations.natural_person_option}
                        addProduct={handleAddService}
                    />
                </div>
            </FlexBox>
        </Layout>
    );
};

export default Service;

import {axios} from "../axios";
import {store} from "../store";

const AuthologicService = {

        init: () => (payload) => {
            const token = store.getState().transaction.transaction.token;
            const transactionId = store.getState().transaction.transaction.transaction_id
            return axios
                .post(
                    `/transaction/authologic/start/${transactionId}`,
                    payload,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((res) => {
                    return res.data;
                });


        },
        autoComplete: () => () => {
            const token = store.getState().transaction.transaction.token;
            const transactionId = store.getState().transaction.transaction.transaction_id
            return axios
                .get(
                    `/transaction/authologic/check-autocompleted/${transactionId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((res) => {
                    return res.data;
                });

        }
    };

export default AuthologicService;

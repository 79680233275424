import React from 'react'
import styled from 'styled-components'
import ProductDetails from './ProductTitle/ProductDetails';
import ProductTitle from './ProductTitle/ProductTitle';
import GalleryBox from './components/GalleryBox'


const Product2 = ({ data, category }) => {
    return (
        <StyledMainProduct>
            <ProductTitle
                label=""
                name={data.name}
                availabilityText={data.availabilityText}
                availabilitySymbol={
                    data.availabilitySymbol
                }
            />
            <ProductDetails
                location={data.localizations[0].city}
                condition={data.conditionText}
                flexDirection='row'
                flexWrap='wrap'
                gap='1rem'
                type={data.type}
            />
            <GalleryBox photosArr={
                [data.image_url,
                ...data.imageUrls]}

            />
            <ProductDetails
                openDefault={true}
                category={category}
                description={data.description}
                type={data.type}
                extraContent={
                    <ProductDetails
                        category={category}
                        price={data.formattedGrossPrice}
                        type={data.type}
                    />
                }
            />
        </StyledMainProduct>
    )
}

export default Product2

const StyledMainProduct = styled.div`
    background-color: ${props => props.theme.white};
    padding: 20px;
    border-radius: 20px;
`
import NativeLoader from "react-loader-spinner";
import styled from "styled-components";
import { theme } from '../../theme'

const Container = styled.div`
    position: ${(props) => (props.fixed ? "fixed" : "relative")};
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Loader = ({ fixed = false }) => {
    return (
        <Container fixed={fixed}>
            <NativeLoader type="Audio" color={theme.primary} height={50} width={50} />
        </Container>
    );
};

export default Loader;

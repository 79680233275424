import React from 'react'
import styled from 'styled-components'
import CompanyToggle from '../../connectedComponents/CompanyToggle/CompanyToggle'
import CheckedIcon from '../Icons/components/CheckedIcon'


const CompanyRow = () => {
    return (
        <StyledCompanyRow>
            <IconWrapper>
                <CheckedIcon width={20} height={20} />
            </IconWrapper>
            <Text>
                Najem na firmę
            </Text>
            <CompanyToggle />
        </StyledCompanyRow>
    )
}

export default CompanyRow

const StyledCompanyRow = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const IconWrapper = styled.div`
    color: ${props => props.theme.primary};
    display: flex;
`

const Text = styled.div`
    font-size: 17px;
    font-weight: bold;
`
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import styled from "styled-components";
import Button from "../../components/Buttons/Button";
import Container from "../../components/Container/Container";
import BackArrowIcon from "../../components/Icons/components/BackArrowIcon";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import { selectTransactionId } from "../../features/transactionSlice";
import Step6Service from "../../services/Step6Service";
import { clearData } from "../../utils/functions";
import { selectMobile } from '../../features/appSlice'
import { toast } from "react-toastify";

const Title = styled.h3`
    font-size: ${props => props.isMobile ? '20px' : '28px'};
    line-height: 26px;
    padding-top: ${props => props.isMobile ? '38px' : '54px'};
    padding-bottom: ${props => props.isMobile ? '10px' : '16px'};
`;
const Text = styled.div`
    font-size: 17px;
    line-height: 22px;
    margin-top: 20px;
`;

const Footer = styled.footer`
    padding-bottom: 44px;
    margin-top: 20px;
    
`;

const Box = styled.div`
    max-width: ${props => !props.isMobile && '424px'};
    background: ${({ theme }) => theme.primaryGradient};
    border-radius: 50px;
    color: white;
    font-size: 18px;
    line-height: 18px;
    padding: 28px 0;
    margin: ${props => props.isMobile ? '20px 0 30px 0' : '40px auto 40px auto'};
    header {
        padding-bottom: 14px;
        margin-bottom: 24px;
        border-bottom: 1px solid white;
    }
    p {
        line-height: 24px;
    }
`;

const Wrapper = styled.div`
    max-width: ${props => props.isMobile ? '100%' : '580px'};
    text-align: center;
    margin: auto;
`;

const BackgroundColor = styled.div`
    background-color: ${props => !props.isMobile && props.theme.white};
`;
const Finished = ({ type = "address", street, phone, postcode, city }) => {

    const { session_id } = useParams();
    const isMobile = useSelector(selectMobile);
    const transactionId = useSelector(selectTransactionId);
    const location = useLocation();
    const [unauthorized, setUnauthorized] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(-2);
    const [paymentStatusAttempts, setPaymentStatusAttempts] = useState(0);

    const returnUrl = useMemo(() => {
        const searchQuery = new URLSearchParams(location.search);
        return searchQuery.get("return_url") || 'https://vibe.pl';
    }, [location.search]);

    const { data, isLoading: isGettingData } = useQuery(
        "payment-summary-finished",
        Step6Service.getData(transactionId),
        {
            onSuccess: () => {
                setUnauthorized(false);
                checkPayment();
            },
            onError: () => {
                setUnauthorized(true);
                clearData();
            },
        }
    );


    const {
        mutateAsync: checkPayment,
        isLoading: isCheckingPayment,
    } = useMutation(Step6Service.checkPaymentStatus(session_id), {
        onSuccess: ({ status }) => {
            setPaymentStatus(status);
            setPaymentStatusAttempts(prev => prev + 1);
            if (status === 0 || status === -1) {
                toast.error("Płatność nie powiodła się");
                clearData();
            }
            if (status === 2) {
                clearData();
            }
        },
        onError: () => {
            toast.error("Płatność nie powiodła się");
            clearData();
        },
    });

    useEffect(() => {
        if (paymentStatus === 1) {
            if (paymentStatusAttempts < 10) {
                setTimeout(() => {
                    checkPayment();
                }, 10000);
            } else {
                setPaymentStatus(0);
                toast.error("Płatność nie powiodła się");
                clearData();
            }
        }
        // eslint-disable-next-line
    }, [paymentStatusAttempts]);


    const goto_shop = async () => {
        window.location.assign(returnUrl);
    };

    if (unauthorized) {
        window.location.href = '/link-inactive';
    }
    if (isGettingData || isCheckingPayment || paymentStatus === 1 || paymentStatus === -2) {
        return <Loader fixed />;
    }
    if (paymentStatus === 2) {

        return (
            <Layout showBasket={false}>
                <Container>
                    <BackgroundColor isMobile={isMobile}>
                        <Wrapper isMobile={isMobile}>
                            <Container>
                                <Title isMobile={isMobile}>Dziękujemy!</Title>
                                <Text>
                                        {type === "UPS" &&
                                            "Twoje zamówienie jest gotowe do wysyłki."}
                                        {type === "address" &&
                                            "Zgodnie z naszymi ustaleniami uruchamiamy wysyłkę Twojego zamówienia lub czekamy na Ciebie w naszym Sklepie Partnerskim. 🤗"}
                                </Text>

                            </Container>
                            {type === "UPS" && (
                                <Box isMobile={isMobile}>
                                    <Container>
                                        <header>Twój sprzęt dostarczy kurier {type}</header>
                                        <div>
                                            <p>
                                                Informację o dacie dostawy otrzymasz
                                                niezwłocznie po zarejestrowaniu przesyłki
                                                przez kuriera.
                                            </p>
                                        </div>
                                    </Container>
                                </Box>
                            )}
                            <Text className="mt-5">
                                W przypadku odbioru osobistego, na numer telefonu podany w trakcie zamówienia wyślemy kod autoryzacyjny, a przypadku wysyłki kurierskiej wraz z przesyłką otrzymasz Protokół Wydania, z prośbą o jego podpisanie i przekazanie kurierowi.
                            </Text>
                            {returnUrl && (
                                <Footer>
                                    <Button
                                        className={'mt-3'}
                                        onClick={goto_shop}
                                        LeftIcon={<BackArrowIcon />}
                                    >
                                        Powrót do sklepu
                                    </Button>
                                </Footer>
                            )}
                        </Wrapper>
                    </BackgroundColor>
                </Container>
                {
                    data && <div id="trustedShopsCheckout" style={{ display: "none" }}>
                        <span id="tsCheckoutOrderNr">{data.agreement_number}</span>
                        <span id="tsCheckoutBuyerEmail">{data.email}</span>
                        <span id="tsCheckoutOrderAmount">{data.total_gross_price_raw}</span>
                        <span id="tsCheckoutOrderCurrency">PLN</span>
                        <span id="tsCheckoutOrderPaymentType">PRZELEWY 24</span>
                        <span id="tsCheckoutOrderEstDeliveryDate">{data.date}</span></div>
                }
            </Layout>
        )
    } else {
        return (
            <Layout showBasket={false}>
                <Container>
                    <Title isMobile={isMobile}>Płatność nie powiodła się</Title>
                    <Text>
                        <strong>
                            Proszę opłacić ponownie z linku przesłanego na Państwa adres e-mail
                        </strong>
                    </Text>
                </Container>
            </Layout>
        )
    }
};

export default Finished;

import { axios } from "../axios";
import { store } from "../store";

const UploadService = {
    uploadStatementNaturalPerson: (transationId, token = null) => ({
        filename,
        base64,
    }) => {
        const _token = token || store.getState().transaction.transaction.token;

        let formData = new FormData();
        formData.append("filename", filename);
        formData.append("binaryStr", base64);

        return axios
            .post(
                `/transaction/statement/natural-person/${transationId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${_token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
    uploadStatementIE: (transationId, token = null) => ({
           filename,
           base64,
       }) => {
        const _token = token || store.getState().transaction.transaction.token;

        let formData = new FormData();
        formData.append("filename", filename);
        formData.append("binaryStr", base64);

        return axios
            .post(
                `/transaction/statement/ie/${transationId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${_token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
    uploadStatementLP: (transationId, token = null) => ({
                                                            filename,
                                                            base64,
                                                        }) => {
        const _token = token || store.getState().transaction.transaction.token;

        let formData = new FormData();
        formData.append("filename", filename);
        formData.append("binaryStr", base64);

        return axios
            .post(
                `/transaction/upload/statement/legal-person/${transationId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${_token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
};

export default UploadService;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import styled from "styled-components";
import FlexBox from "../../components/FlexBox/FlexBox";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import LoadMore from "../../components/LoadMore/LoadMore";
import ProductBox from "../../components/ProductBox/ProductBox";
import SearchBar from "../../components/SearchBar/SearchBar";
import SearchNotFound from "../../components/SearchNotFound/SearchNotFound";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import {
    selectIsCompany, selectMainProduct,
    selectMinimalMonthsParsed,
    selectShopUuid,
} from "../../features/basketSlice";
import ProductService from "../../services/ProductsService";
import Common from "../Common/Common";

/*const TitleContainer = styled.div`
    margin-top: 60px;
`;
const Subtitle = styled.div`
    margin-top: 38px;
    margin-bottom: 8px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.white};
`;*/
const SubCategory = styled.div`
    margin-bottom: 60px;
`;

const CategoryBoxContainer = styled.div`
    margin-bottom: 10px;
`;

const LoadMoreContainer = styled.div`
    text-align: center;
    margin-top: 20px;
`;

const INITIAL_PAGINATION_COUNT = 10;

const AddService = () => {

    const shop_uuid = useSelector(selectShopUuid);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany);
    const mainProduct = useSelector(selectMainProduct);
    const [beforePaginationCount, setBeforePaginationCount] = useState(
        INITIAL_PAGINATION_COUNT
    );
    const [afterPaginationCount, setAfterPaginationCount] = useState(
        INITIAL_PAGINATION_COUNT
    );

    const [searchQuery, setQuery] = useState("");


    const { data: beforeServices } = useQuery(
        [
            "additional-products",
            "SERVICES_BEFORE_THE_LEASE",
            shop_uuid,
            minimal_months,
            mainProduct.id
        ],
        ProductService.getProductsByType(
            "SERVICES_BEFORE_THE_LEASE",
            shop_uuid,
            minimal_months,
            mainProduct.id
        ),
        {
            select: ({ items }) => items,
        }
    );
    const { data: afterServices } = useQuery(
        [
            "additional-products",
            "SERVICES_DURING_THE_LEASE",
            shop_uuid,
            minimal_months,
            mainProduct.id
        ],
        ProductService.getProductsByType(
            "SERVICES_DURING_THE_LEASE",
            shop_uuid,
            minimal_months,
            mainProduct.id
        ),
        {
            select: ({ items }) => items,
        }
    );
    const filterServices = useCallback(
        (service) => {
            if (searchQuery.length === 0) {
                return true;
            }
            return service.details.name
                .toLowerCase()
                .includes(searchQuery.toLowerCase());
        },
        [searchQuery]
    );

    const getInstallment = (service) => {
        if (isCompany) {
            return (
                service?.rental_options?.company_option?.option_formatted
                    ?.gross_month_cost || "0zł"
            );
        }
        return (
            service?.rental_options?.natural_person_option?.option_formatted
                ?.gross_month_cost || "0zł"
        );
    };
    useEffect(() => {
        setBeforePaginationCount(INITIAL_PAGINATION_COUNT);
        setAfterPaginationCount(INITIAL_PAGINATION_COUNT);
    }, [searchQuery]);

    const parsedBeforeServices = useMemo(() => {
        if (!beforeServices) return null;
        return beforeServices
            .slice(0, beforePaginationCount)
            .filter(filterServices);
    }, [beforeServices, beforePaginationCount, filterServices]);

    const parsedAfterServices = useMemo(() => {
        if (!afterServices) return null;

        return afterServices
            .slice(0, afterPaginationCount)
            .filter(filterServices);
    }, [afterServices, afterPaginationCount, filterServices]);



    return (
        <Layout>
            <SearchBar onSubmit={setQuery} />

            <FlexBox gap='1rem' margin='30px 0 0 0'>
                <div style={{ flex: 5 }}>
                    <SubCategory>
                        <SectionHeader marginBottom={35}>
                            Usługi dodatkowe przed wynajmem
                        </SectionHeader>
                        {!parsedBeforeServices && <Loader fixed />}
                        {parsedBeforeServices?.length === 0 ? (
                            <SearchNotFound />
                        ) : (
                            parsedBeforeServices?.map((service) => (
                                <CategoryBoxContainer key={service.details.id}>
                                    <ProductBox
                                        hidePrice={true}
                                        name={service.details.name}
                                        price={service.details.formattedGrossPrice}
                                        quantity={null}
                                        url={`/services-collections/services_before/${service.details.id}`}
                                        imageSrc={service.details.image_url}
                                        installment={getInstallment(service)}
                                    />
                                </CategoryBoxContainer>
                            ))
                        )}

                        {beforePaginationCount <
                            parsedBeforeServices?.filter(filterServices).length && (
                                <LoadMoreContainer>
                                    <LoadMore
                                        onClick={() =>
                                            setBeforePaginationCount((val) => val + 10)
                                        }
                                    />
                                </LoadMoreContainer>
                            )}
                    </SubCategory>
                    <SubCategory>
                        <SectionHeader marginBottom={35}>
                            Usługi w trakcie trwania najmu
                        </SectionHeader>
                        {!parsedAfterServices && <Loader fixed />}

                        {parsedAfterServices?.length === 0 ? (
                            <SearchNotFound />
                        ) : (
                            parsedAfterServices?.map((service) => (
                                <CategoryBoxContainer key={service.details.id}>
                                    <ProductBox
                                        hidePrice={true}
                                        name={service.details.name}
                                        price={service.details.formattedGrossPrice}
                                        quantity={null}
                                        url={`/services-collections/services_during/${service.details.id}`}
                                        imageSrc={service.details.image_url}
                                        installment={getInstallment(service)}
                                    />
                                </CategoryBoxContainer>
                            ))
                        )}
                        {parsedAfterServices <
                            afterServices?.filter(filterServices).length && (
                                <LoadMoreContainer>
                                    <LoadMore
                                        onClick={() =>
                                            setAfterPaginationCount((val) => val + 10)
                                        }
                                    />
                                </LoadMoreContainer>
                            )}
                    </SubCategory>
                </div>
                <div style={{ flex: 3 }}>
                    <Common />
                </div>
            </FlexBox>
        </Layout>
    );
};

export default AddService;

const CompletedIcon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 69 69"
    {...props}
  >
    <g fill="none" stroke="currentColor" strokeWidth={2}>
      <circle cx={34.5} cy={34.5} r={34.5} stroke="none" />
      <circle cx={34.5} cy={34.5} r={33.5} />
    </g>
    <path
      d="M25.477 36.287l6.849 6.849 17.123-17.123"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={2}
    />
  </svg>
);

export default CompletedIcon;

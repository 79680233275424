import React from 'react'
import styled from 'styled-components';
import RealMonthPeriod from '../../connectedComponents/MonthPeriod/RealMonthPeriod';
import Button from '../Buttons/Button';
import Collapse from '../Collapse/Collapse';
import NextArrowIcon from '../Icons/components/NextArrowIcon';
import SectionHeader from '../SectionHeader/SectionHeader';
import Tooltip from '../Tooltip/Tooltip';

import {
    selectIsCompany,
    selectMainProduct,
    selectMinimalMonthsParsed,
    selectRealMonthsParsed,
} from "../../features/basketSlice";
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import ProductService from '../../services/ProductsService';
import Loader from '../Loader/Loader';

const RepurchaseBox = () => {

    const mainProduct = useSelector(selectMainProduct);
    const real_months = useSelector(selectRealMonthsParsed);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany);

    const { data, isLoading, isSuccess } = useQuery(
        [
            "calculation",
            { main_product_id: mainProduct.id, real_months, minimal_months, isCompany },
        ],
        ProductService.getCalculation({
            main_product_id: mainProduct.id,
            real_months,
            minimal_months,
        }),
        {
            select: (d) => {
                if (isCompany) {
                    return d.company_option;
                }
                return d.natural_person_option;
            }
        }
    );

    const gross_optional_main_item_repurchase =
        data?.option_formatted?.gross_optional_main_item_repurchase;

    return (

        <CollapseContainer>
            <Collapse
                ActionComponent={({ toggleOpen, isOpen }) => (
                    <Button
                        buttonType="outline"
                        RightIcon={
                            <RotatedIcon isOpen={isOpen}>
                                <NextArrowIcon />
                            </RotatedIcon>
                        }
                        onClick={toggleOpen}
                    >
                        Opcje wykupu
                    </Button>
                )}
            >
                <CollapseContent>
                    <SectionHeader
                        Icon={<Tooltip description="Gwarantujemy Ci możliwość wykupu wynajętego sprzętu w dowolnym momencie od rozpoczęcia umowy najmu. Nie płacisz za to żadnych dodatkowych prowizji, a jedynie cenę wykupu podaną w naszej ofercie. To fantastyczna opcja dla tych, którzy szukają konkretnego sprzętu, ale najpierw chcą go przetestować komfortowo w swoich warunkach bez presji czasu i otoczenia." />}
                    >
                        Opcja wykupu*
                    </SectionHeader>
                    <SectionSubHeader>
                        Wybierz miesiąc od rozpoczęcia umowy najmu
                    </SectionSubHeader>
                    <RealMonthPeriod />
                    <PurchaseSummary>
                        <div style={{ textAlign: "left" }}>
                            <strong style={{ fontSize: "17px" }}>
                                Cena wykupu w {real_months} miesiącu
                                najmu:
                            </strong>
                        </div>
                        <PurchasePrice>
                            {isLoading && <Loader />}
                            {isSuccess &&
                                gross_optional_main_item_repurchase && (
                                    <strong style={{ fontSize: "20px" }}>
                                        {
                                            gross_optional_main_item_repurchase
                                        }{" "}
                                        zł brutto
                                    </strong>
                                )}
                        </PurchasePrice>
                        <AdditionalText>
                            * Wykup nie jest obowiązkowy
                        </AdditionalText>
                    </PurchaseSummary>
                </CollapseContent>
            </Collapse>
        </CollapseContainer>
    )
}

export default RepurchaseBox


const CollapseContainer = styled.div`
    margin-top: 26px;
`;
const CollapseContent = styled.div`
    padding: 30px 0 0;
`;

const RotatedIcon = styled.div`
    transition: all 0.4s;
    transform: ${({ isOpen }) =>
        !!isOpen ? `rotateZ(90deg)` : `rotateZ(${0});`};
`;

const SectionSubHeader = styled.div`
    margin-top: -20px;
    margin-bottom: 35px;
`;
const AdditionalText = styled.div`
    text-align: left;
    margin-top: 0px;
    color: #777878;
`;

const PurchaseSummary = styled.div`
    margin-top: 38px;
    line-height: 18px;
`;
const PurchasePrice = styled.div`
    margin-top: 4px;
    font-size: 1.4285714285714286em;
    line-height: 26px;
    display: flex;
`;
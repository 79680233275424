import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
    selectIsCompany,
    selectMainProduct,
    selectMinimalMonthsParsed,
    selectRealMonthsParsed
} from "../../../features/basketSlice";
import Button from "../../Buttons/Button";
import CalendarIcon from "../../Icons/components/CalendarIcon";
import CurrencyIcon from "../../Icons/components/CurrencyIcon";
import { polishPlural } from "../../../utils/functions";
import { useQuery } from "react-query";
import ProductService from "../../../services/ProductsService";
import NegativeContainer from "../../Container/NegativeContainer";

const Root = styled.div`
    padding: 20px 10px;
    display: block;
    background: ${({ theme }) => theme.white};
    z-index: 100;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.15);

`;
const ProductName = styled.div`
    font-size: 1.6428571428571428em;
    line-height: 30px;
`;
const ButtonContainer = styled.div`
    margin-top: 24px;
`;

const Col = styled.div`
    line-height: 16px;
    display: flex;
    align-items: center;
    svg {
        color: ${({ theme }) => theme.primary};
        margin-right: 8px;
    }
`;

const Row = styled.div`
    display: flex;
    margin-top: 20px;
    ${Col}:first-child {
        margin-right: 25px;
    }
`;
const FooterSummary = () => {
    const mainProduct = useSelector(selectMainProduct);
    const real_months = useSelector(selectRealMonthsParsed);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany);

    const { data, isSuccess } = useQuery(
        [
            "calculation",
            { main_product_id: mainProduct.id, real_months, minimal_months, isCompany },
        ],
        ProductService.getCalculation({
            main_product_id: mainProduct.id,
            real_months,
            minimal_months,
        }),
        {
            select: (d) => {
                if (isCompany) {
                    return d.company_option;
                }
                return d.natural_person_option;
            }
        }
    );

    const gross_month_cost = data?.option_formatted?.gross_month_cost;

    return (
        <NegativeContainer>
            <Root>
                <ProductName>
                    <strong style={{ fontSize: "23px" }}>{mainProduct.name}</strong>
                </ProductName>
                <Row>
                    <Col>
                        <CalendarIcon width={20} height={20} />
                        <span style={{ fontSize: "17px" }}>{minimal_months}{" "}
                            {polishPlural(
                                "miesiąc",
                                "miesiące",
                                "miesięcy",
                                minimal_months
                            )}</span>
                    </Col>
                    <Col>
                        <CurrencyIcon width={20} height={20} />
                        <strong style={{ fontSize: "17px" }}>
                            {isSuccess && gross_month_cost}
                            zł brutto / mc</strong>
                    </Col>
                </Row>
                <ButtonContainer>
                    {mainProduct.availabilitySymbol === "OUT_OF_STOCK" && (
                        <Link to="/basket">
                            <Button>Dalej</Button>
                        </Link>
                    )}
                    {mainProduct.availabilitySymbol !== "OUT_OF_STOCK" && (
                        <Link to="/basket">
                            <Button>Dalej</Button>
                        </Link>
                    )}
                </ButtonContainer>
            </Root>
        </NegativeContainer>
    );
};

export default FooterSummary;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    form: 'mObywatel',
    transaction: null,
    step1: { customerType: 'NATURAL_PERSON' },
    step2: {},
    step3: {},
    step5: {},
    step6: {},
};

export const transationSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {

        setTransaction: (state, action) => {
            state.transaction = action.payload;
        },
        setStep1: (state, action) => {
            state.step1 = action.payload;
        },
        reset: (state) => {
            state.transaction = null;
            state.form = 'mObywatel';
            state.step1 = { ...initialState.step1 };
            state.step2 = { ...initialState.step2 };
            state.step3 = { ...initialState.step3 };
            state.step4 = { ...initialState.step4 };
            state.step5 = { ...initialState.step5 };
            state.step6 = { ...initialState.step6 };
        },
        setForm: (state, action) => {
            state.form = action.payload;
        },
    },
});

export const selectTransactionData = (state) => state.transaction.transaction
export const selectTransactionId = (state) =>
    state.transaction?.transaction?.transaction_id;
export const selectTransactionToken = (state) =>
    state.transaction.transsaction.token;
export const selectCustomerType = (state) =>
    state.transaction.step1.customerType;
export const selectStep1Data = (state) => state.transaction.step1;

export const { setTransaction, setStep1, reset, setForm } = transationSlice.actions;

export default transationSlice.reducer;

import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackArrowIcon from "../../components/Icons/components/BackArrowIcon";
import NextArrowIcon from "../../components/Icons/components/NextArrowIcon";
import PeriodInput from "../../components/Inputs/PeriodInput";
import { setRealMonths } from "../../features/basketSlice";
import styled from "styled-components";
import FlexBox from "../../components/FlexBox/FlexBox";

const LEN = 6;
const MAX = 100;

const ArrowButton = styled.div`
    /* background: red; */
    /* padding: 10px; */
    color: ${({ theme }) => theme.primary};
    &:first-child {
        padding: 5px;
        margin-right: 5px;
        margin-left: -10px;
    }
    &:last-child {
        padding: 5px;
        margin-left: 5px;
        margin-right: -10px;
    }
`;

const RealMonthPeriod = () => {
    const dispatch = useDispatch();
    const actualMonth = useSelector(state => state.basket.real_months)
    const period = 1;
    const [index, setIndex] = useState(Math.floor((period - 1) / LEN));
    const values = useMemo(() => {
        return Array(LEN)
            .fill()
            .map((_, i) => i + 1 + LEN * index);
    }, [index]);
    const prevValues = () => {
        if (index - 1 >= 0) {
            dispatch(setRealMonths(actualMonth - 6));
            setIndex(Math.max(0, index - 1));
        }
    };
    const nextValues = () => {
        if ((index + 1) * LEN < MAX) {
            dispatch(setRealMonths(actualMonth + 6));
            setIndex(Math.min(100, index + 1));
        }
    };
    const updateValue = (value) => {
        const _value = value + 1 + LEN * index;
        dispatch(setRealMonths(_value));

    };
    return (
        <FlexBox alignItems="center">
            <ArrowButton onClick={prevValues}>
                <BackArrowIcon />
            </ArrowButton>
            <PeriodInput
                values={values}
                onChange={updateValue}
                value={(period - 1) % LEN}
            />

            <ArrowButton onClick={nextValues}>
                <NextArrowIcon />
            </ArrowButton>
        </FlexBox>
    );
};

export default RealMonthPeriod;

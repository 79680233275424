import { useEffect, useState } from "react";
import { Collapse as NativeCollapse } from "react-collapse";
import styled from "styled-components";

const Root = styled.span`
    & > .collapse-container {
        transition: all 0.3s;
    }
`;

const Collapse = ({ ActionComponent = null, isOpen: _isOpen, children, openDefault = false }) => {
    const [isOpen, setOpen] = useState(!ActionComponent);
    useEffect(() => {
        setOpen(_isOpen);
    }, [_isOpen]);

    useEffect(() => {
        openDefault && setOpen(true)
        // eslint-disable-next-line
    }, [])
    return (
        <Root>
            {ActionComponent &&
                ActionComponent({
                    toggleOpen: () => setOpen(!isOpen),
                    isOpen,
                    setOpen,
                })}
            <NativeCollapse
                isOpened={isOpen}
                theme={{ collapse: "collapse-container", content: "bar" }}
            >
                {children}
            </NativeCollapse>
        </Root>
    );
};

export default Collapse;

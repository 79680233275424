import styled from "styled-components";

const ImageContainer = styled.div`
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 15px;
    padding: ${({ padding }) => padding}px;
    overflow: hidden;
`;
const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
const ProductImage = ({
    name = "Ibanez AFB200-TKS",
    src = "https://muzyczny.pl/towary/238288.jpg",
    padding = 10,
    size = 82,
}) => (
    <ImageContainer padding={padding} size={size}>
        <Image src={src} alt={name} />
    </ImageContainer>
);

export default ProductImage;

import { useRef, useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useClickOutside } from "../../utils/functions";
import CloseIcon from "../Icons/components/CloseIcon";
import QuestionMarkIcon from "../Icons/components/QuestionMarkIcon";

const minWidth = 320;

const InAnim = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50%) translateY(0px);

    }

    to: {
        opacity: 1;
        transform: translateX(-50%) translateY(-10px);
    }
`;

const InAnimDir = keyframes`
    from {
        opacity: 0;
        transform: translateX(0) translateY(0px);

    }
    to: {
        opacity: 1;
        transform: translateX(0) translateY(-10px);
    }
`;

const Root = styled.div`
${props => !props.isMobile && 'margin-right: 20px;'}
    position: relative;
    display: flex;
`;

const TooltipContainer = styled.div`
    background-color: ${({ theme }) => theme.white};
    position: absolute;
    z-index: 100;
    min-width: ${`${minWidth}px`};
    bottom: 100%;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: ${({ show }) => (!show ? "none" : "block")};
    white-space: pre-wrap;
    @media screen and (max-width: 500px) {
        min-width: 250px;
    }
      @media screen and (max-width: 400px) {
        min-width: 200px;
      }
`;

const TooltipCenter = styled(TooltipContainer)`
    transform: translateX(-50%) translateY(-10px);
    left: 50%;
    animation: ${InAnim} 0.2s;
`;
const TooltipRightContainer = styled(TooltipContainer)`
    transform: translateX(0) translateY(-10px);
    left: initial;
    right: -115%;
    animation: ${InAnimDir} 0.2s;
`;
const TooltipLeftContainer = styled(TooltipContainer)`
    transform: translateX(0) translateY(-10px);
    right: initial;
    left: -50%;
    animation: ${InAnimDir} 0.2s;
`;
const Content = styled.div`
    max-height: 400px;
    overflow: auto;
    padding: 0 30px 0 0;
    text-align: left;
  font-weight: normal;
`;
const Icon = styled.div`
    cursor: pointer;
    transition: all 0.2s;
    opacity: 1;
    display: flex;

    &:hover {
        opacity: 0.2;
    }
`;
const CloseContainer = styled(Icon)`
    position: absolute;
    right: 8px;
    cursor: pointer;
`;

const Tooltip = ({ description, descriptionArr = [], ...props }) => {
    const [open, setOpen] = useState(false);
    const [directionClass, setDirectionClass] = useState(null);
    const tooltipContainerRef = useRef();
    const wrapperRef = useRef();

    useEffect(() => {
        if (open) {
            const { top } = tooltipContainerRef.current.getBoundingClientRect();
            const contentHeight = wrapperRef.current.offsetHeight
            if (contentHeight > top) {
                wrapperRef.current.style.transform = "translateY(calc(100% + 30px))";
            }
        }
    }, [open])

    useClickOutside(tooltipContainerRef, () => setOpen(false));

    const handleClick = (value) => {
        const {
            left,
            right,
        } = tooltipContainerRef.current.getBoundingClientRect();

        if (left < minWidth / 2) {
            setDirectionClass("left");
        } else if (right > window.innerWidth - minWidth / 2) {
            setDirectionClass("right");
        } else {
            setDirectionClass(null);
        }
        setOpen(value);
    };

    const renderTooltip = () => {
        if (directionClass === "right") {
            return (
                <TooltipRightContainer ref={wrapperRef} show={open} {...props}>
                    <CloseContainer onClick={() => handleClick(false)}>
                        <CloseIcon width={20} height={20} />
                    </CloseContainer>
                    <Content>{description}</Content>
                    <ContentArr>
                        {
                            descriptionArr.map((paragraph, index) => (
                                <div key={index}>{paragraph}</div>
                            ))
                        }
                    </ContentArr>

                </TooltipRightContainer>
            );
        }
        if (directionClass === "left") {
            return (
                <TooltipLeftContainer ref={wrapperRef} show={open} {...props}>
                    <CloseContainer onClick={() => handleClick(false)}>
                        <CloseIcon width={20} height={20} />
                    </CloseContainer>
                    <Content>{description}</Content>
                    <ContentArr>
                        {
                            descriptionArr.map((paragraph, index) => (
                                <div key={index}>{paragraph}</div>
                            ))
                        }
                    </ContentArr>
                </TooltipLeftContainer>
            );
        }
        return (
            <TooltipCenter ref={wrapperRef} show={open} {...props}>
                <CloseContainer onClick={() => handleClick(false)}>
                    <CloseIcon width={20} height={20} />
                </CloseContainer>
                <Content>{description}</Content>
                <ContentArr>
                    {
                        descriptionArr.map((paragraph, index) => (
                            <div key={index}>{paragraph}</div>
                        ))
                    }
                </ContentArr>
            </TooltipCenter>
        );
    };

    return (
        <Root ref={tooltipContainerRef} {...props}>
            {(description || descriptionArr) && renderTooltip()}
            {(description || descriptionArr) && (
                <Icon onClick={() => handleClick(true)}>
                    <QuestionMarkIcon width={20} height={20} />
                </Icon>
            )}
            {(!description && !descriptionArr) && <QuestionMarkIcon width={20} height={20} />}
        </Root>
    );
};

export default Tooltip;


const ContentArr = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 26px;
    gap: 10px;
    max-height: 400px;
    overflow: auto;
    padding: 0 30px 0 0;
    text-align: left;
`

import React from 'react'
import styled from 'styled-components'
import NextIcon from '../../../components/Icons/components/NextIcon'
import { useParams, useHistory } from 'react-router-dom'
import { setCategory } from '../../../features/basketSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectMobile } from '../../../features/appSlice'

const CategoryRow = ({ category }) => {

    const { id, name, imageUrl } = category
    const history = useHistory()
    const { type } = useParams()
    const dispatch = useDispatch();
    const isMobile = useSelector(selectMobile)




    const handleClick = () => {
        if (id) {
            history.push(`/categories/${type}/${id}`)
        }
        else {
            history.push(`/categories/${type}/all`)
        }
        dispatch(setCategory(name))
    }
    return (
        <StyledCategory onClick={handleClick} isMobile={isMobile}>
            <Box>
                <ImageWrapper isMobile={isMobile}>
                    <Image src={imageUrl} />
                </ImageWrapper>
                <Name isMobile={isMobile}>{name}</Name>
            </Box>
            <Icon >
                <NextIcon width={20} height={20} />
            </Icon>
        </StyledCategory>
    )
}

export default CategoryRow

const StyledCategory = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: ${props => props.isMobile ? '10px 20px' : '20px'};
    border-radius: 20px;
    background-color: ${props => props.theme.white};
    cursor: pointer;
`

const Box = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;

`

const ImageWrapper = styled.div`
    width: ${props => props.isMobile ? '75px' : '100px'};
    height: ${props => props.isMobile ? '75px' : '100px'};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Image = styled.img`
    display: block;
    max-width: 100%;
    max-height: 100%;
`

const Name = styled.div`
    font-size: 20px;
    font-weight: ${props => props.isMobile ? 'normal' : 'bold'}; 
`

const Icon = styled.div`
    color: ${({ theme }) => theme.primary};
`

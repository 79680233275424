import { Link } from "react-router-dom";
import styled from "styled-components";
import { MOBILE_WIDTH } from "../../variables";
import BackArrowIcon from "../Icons/components/BackArrowIcon";
import NextArrowIcon from "../Icons/components/NextArrowIcon";
import Button from "./Button";

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;
    & > div {
        flex: 1;
        margin: 0 10px;
    }
    @media screen and (max-width: 420px) {
        button {
            font-size: 11px;
        }
    }
    @media screen and (min-width: ${MOBILE_WIDTH}px) {
        margin-top: 60px;
        button {
            min-width: 160px;
            width: auto;
            margin: auto;
        }
    }
`;

const NavButton = ({
    onBackClicked = () => console.error("back handler not implemented"),
    onNextClicked = () => console.error("next handler not implemented"),
    backLink = null,
    nextLink = null,
    submit = false,
    disabled = false,
    backText = '',
    hideBackButton = false
}) => (
    <Container>
        {!hideBackButton && (
        <div>
            {!!backLink ? (
                <Link to={backLink}>
                    <Button LeftIcon={<BackArrowIcon />}>{backText ? backText : 'Wstecz'}</Button>
                </Link>
            ) : (
                <Button onClick={onBackClicked} LeftIcon={<BackArrowIcon />}>
                    Wstecz
                </Button>
            )}
        </div>
        )}
        <div>
            {!!nextLink ? (
                <Link to={nextLink}>
                    <Button RightIcon={<NextArrowIcon />}>Dalej</Button>
                </Link>
            ) : (
                <Button
                    onClick={onNextClicked}
                    RightIcon={<NextArrowIcon />}
                    type={!!submit ? "submit" : "button"}
                    disabled={disabled}
                >
                    Dalej
                </Button>
            )}
        </div>
    </Container>
);

export default NavButton;

const CalendarIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        {...props}
    >
        <path
            data-name="Path 78"
            d="M6.924 13.076a1.026 1.026 0 11-1.027-1.026 1.027 1.027 0 011.027 1.026z"
            fill="currentColor"
        />
        <path
            data-name="Path 79"
            d="M6.924 8.974a1.026 1.026 0 11-1.027-1.026 1.027 1.027 0 011.027 1.026z"
            fill="currentColor"
        />
        <path
            data-name="Path 80"
            d="M11.027 13.076A1.026 1.026 0 1110 12.05a1.027 1.027 0 011.027 1.026z"
            fill="currentColor"
        />
        <path
            data-name="Path 81"
            d="M11.027 8.974A1.026 1.026 0 1110 7.948a1.027 1.027 0 011.027 1.026z"
            fill="currentColor"
        />
        <path
            data-name="Path 82"
            d="M15.129 8.974a1.026 1.026 0 11-1.027-1.026 1.027 1.027 0 011.027 1.026z"
            fill="currentColor"
        />
        <path
            data-name="Path 83"
            d="M10 2.564c-2.171 0-5.137.188-6.988.323a1.228 1.228 0 00-1.139 1.13c-.143 1.754-.334 4.488-.334 6.5s.192 4.742.334 6.5a1.226 1.226 0 001.139 1.128c1.85.136 4.816.324 6.988.324s5.137-.188 6.988-.324a1.227 1.227 0 001.139-1.128c.143-1.754.334-4.488.334-6.5s-.192-4.742-.334-6.5a1.229 1.229 0 00-1.139-1.129c-1.851-.136-4.817-.324-6.988-.324zM2.9 1.353c1.85-.135 4.867-.327 7.1-.327s5.248.192 7.1.327a2.768 2.768 0 012.56 2.538c.143 1.755.339 4.544.339 6.621s-.2 4.866-.339 6.621a2.768 2.768 0 01-2.56 2.538c-1.852.135-4.868.327-7.1.327s-5.25-.19-7.101-.322a2.767 2.767 0 01-2.56-2.538C.196 15.383 0 12.594 0 10.517s.2-4.866.339-6.621a2.767 2.767 0 012.56-2.543z"
            fill="currentColor"
            fillRule="evenodd"
        />
        <path
            data-name="Path 84"
            d="M6.923 0a.77.77 0 01.77.77v2.566a.77.77 0 11-1.54 0V.77a.77.77 0 01.77-.77z"
            fill="currentColor"
            fillRule="evenodd"
        />
        <path
            data-name="Path 85"
            d="M13.077 0a.77.77 0 01.77.77v2.566a.77.77 0 11-1.54 0V.77a.77.77 0 01.77-.77z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export default CalendarIcon;

import React from 'react'
import styled from 'styled-components'

const StyledFlexBox = styled.div`
    display: flex;
    text-align: ${props => props.textAlign};
    justify-content: ${props => props.justifyContent};
    align-items: ${props => props.alignItems};
    flex-direction: ${props => props.flexDirection};
    flex: ${props => props.flex};
    font-size: ${props => props.fontSize};
    gap: ${props => props.gap};
    margin: ${props => props.margin};
`;

const FlexBox = ({
    children,
    justifyContent = 'flex-start',
    alignItems = 'flex-start',
    flexDirection = 'row',
    flex = 'initial',
    fontSize = 'initial',
    textAlign = 'center',
    gap = '0px',
    margin = '0'
}) => {
    return (
        <StyledFlexBox
            justifyContent={justifyContent}
            alignItems={alignItems}
            flexDirection={flexDirection}
            flex={flex}
            fontSize={fontSize}
            textAlign={textAlign}
            gap={gap}
            margin={margin}
        >
            {children}
        </StyledFlexBox>
    )
}

export default FlexBox

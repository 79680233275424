import { useEffect } from "react";
import { clearData } from "../../utils/functions";

const EmailSent = () => {
    useEffect(() => {
        clearData();
    }, []);
    return <div>Email został wysłany</div>;
};

export default EmailSent;

import { useSelector } from "react-redux";
import { selectMainProduct } from "./features/basketSlice";

const MainProductRoute = ({ children }) => {
    const mainProduct = useSelector(selectMainProduct);

    if (mainProduct) {
        return children;
    }
    return <div>Wystąpił błąd w formularzu</div>;
};

export default MainProductRoute;

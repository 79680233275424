import Layout from "../../components/Layout/Layout";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
    addAdditionalProduct,
    addService,
    selectMinimalMonthsParsed,
    selectShopUuid,
    selectIsCompany,
    selectMainProduct
} from "../../features/basketSlice";
import { useQuery } from "react-query";
import ProductService from "../../services/ProductsService";
import { useMemo } from "react";
import styled from "styled-components";
import Product2 from '../../components/Product/Product2'
import CompanyRow from "../../components/CompanyRow/CompanyRow";
import PeriodRow from "../../components/PeriodRow/PeriodRow";
import ProductCalculator from "../../components/ProductCalculator/ProductCalculator";
import MainProduct from "../../components/MainProduct/MainProduct";
import Button from "../../components/Buttons/Button";
import NextArrowIcon from "../../components/Icons/components/NextArrowIcon";
import BackArrowIcon from "../../components/Icons/components/BackArrowIcon";
import SocialBox from "../../components/SocialBox/SocialBox";
import Loader from "../../components/Loader/Loader";


const Product = () => {

    // -------------------------------------------------
    // ---------------- SOME HOOKS ---------------------
    // -------------------------------------------------

    const history = useHistory();
    const { product: id, category } = useParams();
    const dispatch = useDispatch();
    const shop_uuid = useSelector(selectShopUuid);
    const months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany);
    const mainProduct = useSelector(selectMainProduct)

    // ---------------------------------------------------------------
    // ---------------- GET PRODUCT DETAILS DATA ---------------------
    // ---------------------------------------------------------------

    const { data, isLoading, isSuccess } = useQuery(
        ["productDetails", id, shop_uuid, months, mainProduct.id],
        ProductService.getProductDetails(id, shop_uuid, months, mainProduct.id),
        {
            keepPreviousData: true
        }
    );


    // ---------------------------------------------------------------
    // PICK CALCULATION DATA DEPEND ON "COMPANY/NO CAMPANY SWITCH"
    // ---------------------------------------------------------------

    const calculationData = useMemo(() => {
        if (isSuccess && data) {
            return isCompany ? data.calculations.company_option.option_formatted : data.calculations.natural_person_option.option_formatted
        }
        else {
            return null
        }
        // eslint-disable-next-line 
    }, [isCompany, data, isSuccess])


    // ---------------------------------------------------------------
    // ---------------- BUTTONS HANDLERS -----------------------------
    // ---------------------------------------------------------------

    const handleAddToCart = () => {
        if (category === 'accessories') {
            dispatch(addAdditionalProduct(data));
        }
        if (category === 'all_services') {
            dispatch(addService(data));
        }

        history.push("/basket");
    };

    const handleBack = () => {
        history.goBack()
    }

    const handleBackToBasket = () => {
        history.push('/basket')
    }


    // ---------------------------------------------------------------
    // ---------------- CHECK IF LOADING VIEW ------------------------
    // ---------------------------------------------------------------

    if (isLoading) {
        return <Loader fixed />;
    }

    return (
        <Layout desktopTitle={category === "main" ? "Produkt główny" : 'Akcesoria i produkty dodatkowe'}>
            <Row>
                <Col1>
                    <Product2 data={data} category={category} />
                </Col1>
                <Col2>
                    <Col2Bg>
                        <CompanyRow />
                        <PeriodRow />
                        <ProductCalculator data={calculationData} />
                        <MainProduct />
                        <Buttons>
                            {
                                category !== 'main' && (
                                    <Button
                                        RightIcon={<NextArrowIcon />}
                                        onClick={handleAddToCart}
                                        disabled={data?.availabilitySymbol === "OUT_OF_STOCK"}
                                    >
                                        Dodaj do koszyka
                                    </Button>
                                )
                            }
                            <Button
                                buttonType='outline'
                                LeftIcon={<BackArrowIcon />}
                                onClick={handleBack}
                            >
                                Wstecz
                            </Button>
                            <Button
                                buttonType='outline'
                                LeftIcon={<BackArrowIcon />}
                                onClick={handleBackToBasket}
                            >
                                Wróć do koszyka
                            </Button>
                        </Buttons>
                        <SocialBox />
                    </Col2Bg>
                </Col2>
            </Row>
        </Layout>
    );
};

export default Product;

const Row = styled.div`
    display: flex;
    gap: 25px;
`

const Col1 = styled.div`
    flex: 7;
`

const Col2 = styled.div`
    flex: 5;
    flex-direction: column;
    gap: 20px;
`

const Col2Bg = styled.div`
    background-color: ${props => props.theme.white};
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 20px;
`

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

import React from 'react'
import styled from 'styled-components'
import SectionHeader from '../../SectionHeader/SectionHeader'
import { useQuery } from 'react-query'
import RecommendedProductsService from '../../../services/RecommendedProductsService'
import Loader from '../../Loader/Loader'
import { useSelector } from 'react-redux'

import {
    selectMinimalMonthsParsed,
} from "../../../features/basketSlice";
import ProductRow from './ProductRow'
import { selectMobile } from '../../../features/appSlice'
import ProductRowMobile from './ProductRowMobile'

const RecomendedProducts = () => {

    const shopUuid = useSelector(state => state.basket.shop_uuid);
    const mainProductId = useSelector(state => state.basket.mainProduct.id);
    const minimalMonths = useSelector(selectMinimalMonthsParsed);
    const isMobile = useSelector(selectMobile)

    const { data, isSuccess, isLoading } = useQuery(
        `recommended-products-${minimalMonths}`,
        RecommendedProductsService.getAllRecommendedProducts('ACCESSORIES', shopUuid, minimalMonths, mainProductId, { limit: 4 })
    )
    return (
        <>
            {
                isLoading && <Loader />
            }
            {
                isSuccess && (
                    <>
                        <SectionHeader marginTop={35}>
                            Sprawdź rekomendowane akcesoria
                        </SectionHeader>
                        <Wrapper>
                            {
                                data.items.map((product, index) =>
                                    isMobile ? (
                                        <ProductRowMobile key={index} product={product} />
                                    ) : (
                                        <ProductRow key={index} product={product} type={'accessories'} />
                                    )
                                )
                            }
                        </Wrapper>
                    </>
                )
            }
        </>
    )
}

export default RecomendedProducts

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
`
import React, { useMemo, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import RecommendedProductsService from '../../services/RecommendedProductsService'
import { useSelector } from 'react-redux'
import {
    selectMainProduct,
    selectShopUuid,
    selectMinimalMonthsParsed
} from "../../features/basketSlice";
import Loader from '../../components/Loader/Loader'
import { selectMobile } from '../../features/appSlice'
import { Link, useParams } from 'react-router-dom'
import Button from '../../components/Buttons/Button'
import BackArrowIcon from '../../components/Icons/components/BackArrowIcon'
import MainProduct from './components/MainProduct'
import ProductRow from '../../components/Product/ProductTitle/ProductRow'
import { selectCategory } from '../../features/basketSlice'
import CompanyRow from '../../components/CompanyRow/CompanyRow'
import PeriodRow from '../../components/PeriodRow/PeriodRow'
import ProductRowMobile from '../../components/Product/ProductTitle/ProductRowMobile'
import Search from '../../components/Search/Search'


const CategoryProductsList = () => {
    const [value, setValue] = useState('')
    const [items, setItmes] = useState([])
    const { type, categoryId } = useParams()
    const category = useSelector(selectCategory)

    const params = useMemo(() => {
        return categoryId !== 'all' ? { category_id: categoryId } : {}
    }, [categoryId])

    const TYPE = useMemo(() => {
        return type.toUpperCase()
    }, [type])

    const isMobile = useSelector(selectMobile)
    const shopUuid = useSelector(selectShopUuid);
    const mainProduct = useSelector(selectMainProduct);
    const minimalMonths = useSelector(selectMinimalMonthsParsed);

    // eslint-disable-next-line
    const { data, isLoading, isSuccess } = useQuery(
        [`categoryProducts`, TYPE, shopUuid, minimalMonths, mainProduct.id, params],
        RecommendedProductsService.getAllCategoryProducts(TYPE, shopUuid, minimalMonths, mainProduct.id, params),
        {
            onSuccess: (data) => {
                setItmes(data)
            },
            select: (data => data.items)
        }
    )

    const filteredItems = useMemo(() => {
        return value ? items.filter(item => item.details.name.toLowerCase().includes(value.toLowerCase())) : items
    }, [value, items])



    return (
        <Layout desktopTitle="Konfigurator oferty">

            {
                isMobile ? (
                    <Mobile>
                        <Header>{category}</Header>

                        <Search value={value} setValue={setValue} />
                        {
                            isLoading && <Loader />
                        }
                        {
                            isSuccess && (data.length > 0 ?
                                filteredItems.map((product, index) => <ProductRowMobile key={index} product={product} type={type} />) :
                                <PorductNotFound>
                                    To raczej niemożliwe że nie są dostępne żadne akcesoria, daj nam znać czego potrzebujesz, poszukamy.
                                </PorductNotFound>)
                        }
                        <Buttons>
                            <Link to={`/categories/${type}`}>
                                <Button buttonType='outline' LeftIcon={<BackArrowIcon />}>
                                    Wstecz
                                </Button>
                            </Link>
                            <Link to='/basket'>
                                <Button buttonType='outline'>
                                    Powrót do koszyka
                                </Button>
                            </Link>
                        </Buttons>
                    </Mobile>
                ) : (
                    <Desktop>
                        <Header>{category}</Header>
                        <Row>

                            <Col1>

                                <Search value={value} setValue={setValue} />
                                {
                                    isLoading && <Loader />
                                }

                                {
                                    isSuccess && (data.length > 0 ?
                                        filteredItems.map((product, index) => <ProductRow key={index} product={product} type={type} />) :
                                        <PorductNotFound>
                                            To raczej niemożliwe że nie są dostępne żadne akcesoria, daj nam znać czego potrzebujesz, poszukamy.
                                        </PorductNotFound>)
                                }
                            </Col1>
                            <Col2>
                                <Col1Background>
                                    {/* IS COMPANY*/}
                                    <CompanyRow />

                                    {/* PERIOD */}
                                    <PeriodRow />

                                    {/* MAIN PRODUCT */}
                                    <MainProduct />

                                    {/* BUTTONS */}
                                    <Buttons>
                                        <Link to={`/categories/${type}`}>
                                            <Button buttonType='outline' LeftIcon={<BackArrowIcon />}>
                                                Wstecz
                                            </Button>
                                        </Link>
                                        <Link to='/basket'>
                                            <Button buttonType='outline'>
                                                Powrót do koszyka
                                            </Button>
                                        </Link>
                                    </Buttons>
                                </Col1Background>
                            </Col2>
                        </Row>
                    </Desktop>
                )
            }
        </Layout>
    )
}

export default CategoryProductsList

const Col1 = styled.div`
    flex: 5;
    display:flex;
    flex-direction: column;
    gap: 40px;
`

const Col2 = styled.div`
    flex: 3;
    display:flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 20px;
`



const Desktop = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Col1Background = styled.div`
    background-color: ${props => props.theme.white};
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 20px;
    border-radius: 20px;
`

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Mobile = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Header = styled.div`
    font-weight: bold;
    font-size: 28px;
`

const Row = styled.div`
    display: flex;
    gap: 20px;
`

const PorductNotFound = styled.div`
    font-size: 20px;
    min-height: 200px;
`

import styled from "styled-components";
import { theme } from '../../theme'

const Root = styled.span`
    display: inline-block;
    font-size: 0.6428571428571429em;
    padding: 5px 8px;
    border-radius: 50px;
    color: ${({ theme }) => theme.white};
    background: ${({ background }) => background};
    font-weight: 900;
    letter-spacing: 0.025em;
    text-transform: uppercase;
`;

const TYPE_COLORS = {
    primary: theme.primary,
    danger: theme.danger,
    success: theme.success,
};
const Badge = ({ children = "Dostępny", type = "success" }) => (
    <Root style={{ fontSize: 10 }} background={TYPE_COLORS[type]}>{children}</Root>
);

export default Badge;

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectIsCompany, setCompany } from "../../features/basketSlice";
import Toggle from "../../components/Inputs/Toggle";

const CompanyToggle = () => {
    const dispatch = useDispatch();
    const isCompany = useSelector(selectIsCompany);

    return (
        <Toggle value={isCompany} onChange={(e) => dispatch(setCompany(e))} />
    );
};

export default CompanyToggle;

import React from 'react'
import { useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import SkipFormService from '../../services/SkipFormService'
import Loader from '../../components/Loader/Loader'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setTransaction } from '../../features/transactionSlice'

const SkipForm = () => {
    const dispatch = useDispatch();
    const { token1, token2 } = useParams()
    const history = useHistory()

    const { mutate: skipForm } = useMutation({
        mutationFn: SkipFormService.skipForm(token1, token2),
        onSuccess: (data) => {
            dispatch(setTransaction(data));
            history.push('/rent/online-application/1')
        }
    })

    useEffect(() => {
        skipForm()
    }, [skipForm])


    return (
        <Loader fixed />
    )
}

export default SkipForm
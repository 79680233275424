import styled from "styled-components";
import guitarImg from "./img.png";
import Swiper from "./Swiper";

const Root = styled.div``;
const Img = styled.img`
    max-height: 395px;
    height: 100%;
    max-width: 100%;
`;
const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ImageSwiper = ({
    images = [guitarImg, guitarImg, guitarImg, guitarImg],
}) => (
    <Root>
        <Swiper
            slides={images.map((url, index) => (
                <ImageContainer key={index}>
                    <Img src={url} alt={url} />
                </ImageContainer>
            ))}
        />
    </Root>
);

export default ImageSwiper;

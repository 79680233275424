import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import ProductBox from "../../components/ProductBox/ProductBox";
import ProductBoxAlternative from "../../components/ProductBox/ProductBoxAlternative";
import {
    selectIsCompany, selectMainProduct,
    // selectMainProduct,
    selectMinimalMonthsParsed,
    selectShopUuid,
} from "../../features/basketSlice";
import ProductService from "../../services/ProductsService";

const ConnectedProductBox = ({ isHideRemover = false, alternativeView, product, removeHandler = () => { }, hidePrice = false, url }) => {
    // const mainProduct = useSelector(selectMainProduct);
    const shop_uuid = useSelector(selectShopUuid);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany);
    const mainProduct = useSelector(selectMainProduct);

    const { data: monthCost, isLoading: isLoadingMonthCost } = useQuery(
        [
            "product-calculation",
            product.id,
            shop_uuid,
            minimal_months,
            isCompany,
            mainProduct.id
        ],
        ProductService.getProductCalculation(
            product.id,
            shop_uuid,
            minimal_months,
            mainProduct.id
        ),
        {
            select: (data) =>
                isCompany
                    ? data.calculations.company_option.option_formatted
                        .gross_month_cost
                    : data.calculations.natural_person_option.option_formatted
                        .gross_month_cost,
            staleTime: 60 * 1000,
        }
    );


    return (
        <>
            {
                alternativeView ? (
                    <ProductBoxAlternative
                        isLoadingMonthCost={isLoadingMonthCost}
                        name={product.name}
                        installment={monthCost}
                        imageSrc={product.image_url}
                    />
                ) : (
                    <ProductBox
                        hidePrice={hidePrice}
                        name={product.name}
                        imageSrc={product.image_url}
                        availability={product.availabilityText}
                        status={product.conditionText}
                        url={url}
                        price={
                            isCompany
                                ? product.calculations.company_option.option_formatted
                                    .total_gross_price
                                : product.calculations.natural_person_option
                                    .option_formatted.total_gross_price
                        }
                        installment={monthCost}
                        onRemove={isHideRemover ? null : () => removeHandler()}

                    />
                )
            }
        </>
    );
};

export default ConnectedProductBox;

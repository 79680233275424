import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import Badge2 from '../../../components/Badge/Badge2'
import { badgeStatus } from '../../../utils/functions'
import FlexBox from '../../../components/FlexBox/FlexBox'
import ProductService from '../../../services/ProductsService'

import {
    removeAdditionalProduct,
    removeService,
    selectIsCompany,
    selectMinimalMonthsParsed,
    selectShopUuid,
    selectMainProduct
} from "../../../features/basketSlice"
import { useQuery } from 'react-query'
import Spiner from '../../../components/Spiner/Spiner'
import CloseIcon from '../../../components/Icons/components/CloseIcon'
import { useHistory } from 'react-router-dom'
import { selectMobile } from '../../../features/appSlice'

const SelectedProductRow = ({ type, product, ...props }) => {


    const history = useHistory()
    const dispatch = useDispatch();

    const { id, image_url, name, availabilityText, conditionText, availabilitySymbol } = product
    const shop_uuid = useSelector(selectShopUuid);
    const months = useSelector(selectMinimalMonthsParsed);
    const mainProduct = useSelector(selectMainProduct)
    const isCompany = useSelector(selectIsCompany)

    const isMobile = useSelector(selectMobile)


    // ---------------------------------------------------------------
    // ---------------- GET PRODUCT DETAILS DATA ---------------------
    // ---------------------------------------------------------------

    const { data, isLoading, isSuccess } = useQuery(
        ["productDetails", id, shop_uuid, months, mainProduct.id],
        ProductService.getProductDetails(id, shop_uuid, months, mainProduct.id),
    );

   
    // ---------------------------------------------------------------
    // PICK CALCULATION DATA DEPEND ON "COMPANY/NO CAMPANY SWITCH"
    // ---------------------------------------------------------------

    const calculationData = useMemo(() => {
        if (isSuccess && data) {
            return isCompany ? data.calculations.company_option.option_formatted : data.calculations.natural_person_option.option_formatted
        }
        else {
            return null
        }
        // eslint-disable-next-line 
    }, [isCompany, data, isSuccess])


    const handleRedirect = () => {
        history.push(`/products-collections/accessories/${id}`)
    }

    const handleRemoveProduct = (e) => {
        e.stopPropagation()

        if (type === 'all_services') {
            dispatch(removeService(id))

        }
        if (type === 'accesories') {
            dispatch(removeAdditionalProduct(id))

        }
    }

    return (
        <StyledRecomendedProduct isMobile={isMobile} onClick={handleRedirect} {...props} >
            <ImageWrapper>
                <Image src={image_url} />
            </ImageWrapper>
            <Info>
                <Name>{name}</Name>
                <FlexBox flexDirection={isMobile ? 'column-reverse' : 'column'} gap='10px' >
                    {(availabilitySymbol || conditionText) && (
                        <FlexBox alignItems='center' gap='10px'>
                            {availabilitySymbol && <Badge2 status={badgeStatus(availabilitySymbol)}>{availabilityText}</Badge2>}
                            {conditionText && <Status>{conditionText}</Status>}
                        </FlexBox>
                    )}
                    <Price>
                        {
                            isMobile ? 'Rata:' : "Rata miesięczna:"
                        }
                        &nbsp;
                        {isLoading ? <Spiner /> : <b>{`${calculationData.gross_month_cost} zł`}</b>}

                    </Price>
                </FlexBox>
            </Info>
            <RemoveButton onClick={handleRemoveProduct}>
                <CloseIcon width={20} height={20} />
            </RemoveButton>
        </StyledRecomendedProduct>
    )
}

export default SelectedProductRow


const Price = styled.div`
    font-size: 17px;
    min-height: 24px;
    display: flex;
    align-items: center;
    b {
        font-size: 21px;
    }
`

const Name = styled.div`
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 20px;
`

const mobileStyle = css`
    align-items: flex-start;
    ${Price} {
        font-size: 14px;
        b {
            font-size: 16px;
        }
    }
    ${Name} {
        font-size: 14px;
    }
`

const StyledRecomendedProduct = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 20px;
    background-color: ${props => props.theme.white};
    border-radius: 20px;
    cursor: pointer;
    ${props => props.isMobile && mobileStyle}
`

const ImageWrapper = styled.div`
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    align-self: center;

`
const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
`
const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap:10px;
    
`




const Status = styled.div`
    color: #798090;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
`


const RemoveButton = styled.button`
    display: flex;
    color: ${props => props.theme.danger};
    padding: 10px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    margin-left: auto;
    
`
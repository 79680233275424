import React from 'react'
import styled from 'styled-components'

import UploadIcon from "../Icons/components/UploadIcon";

const ShareBasketLink = (props) => {
    return (
        <StyledLink {...props}>
            <UploadIcon className='icon' width={20} height={20} />
            <Text>
                Udostępnij koszyk
            </Text>
        </StyledLink>
    )
}

export default ShareBasketLink

const StyledLink = styled.div`    
    display: inline-flex;
    align-items: center;
    max-width: max-content;
    gap: 10px;
    cursor: pointer;
    .icon {
        color: ${props => props.theme.primary};
    }

`

const Text = styled.div`
    font-size: 17px;
    text-decoration: underline;
`



import styled from "styled-components";
import CheckboxIcon from "../Icons/components/CheckboxIcon";

const Root = styled.label`
    color: ${({ theme }) => theme.primary};
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    opacity: ${props => props.disabled ? .5 : 1};
`;
const Input = styled.input`
    display: none;
`;
const Label = styled.span`
    margin-left: 8px;
    color: ${({ theme }) => theme.black};
    white-space: pre-wrap;
    font-size: ${props => props.smallLabel ? `14px` : `17px`};
`;

const RequiredIcon = styled.span`
    color: ${({ theme }) => theme.danger};
    margin: 0 2px;
`;

const Checkbox = ({
    requiredIcon,
    value,
    onChange = () => console.error("checkbox onChange not defined"),
    label,
    smallLabel,
    disabled = false,
}) => (
    <Root disabled={disabled}>
        <CheckboxIcon width={20} height={20} active={value} />
        <Input
            type="checkbox"
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
            disabled={disabled}
        />
        {label && <Label smallLabel={smallLabel}>{requiredIcon && <RequiredIcon>*</RequiredIcon>}{label}</Label>}
    </Root>
);

export default Checkbox;

import RentSubtitle from "../RentSteps/components/RentSubtitle";
import RentInput from "../RentSteps/components/RentInput";
import React from "react";
import styled from "styled-components";
import RentTitle from "../RentSteps/components/RentTitle";

const AuthologicFormMobile = ({customerType, register, errors, labels}) => {

    const LABELS = labels;

    return (
        <Container>
            <RentTitle>Pozostałe dane:</RentTitle>
            {customerType !== 'LEGAL_PERSON' ?
                <>
                    {customerType === "INDIVIDUAL_ENTREPRENEUR" &&
                        <RentInput
                            label={LABELS?.nip[customerType]}
                            name="nip"
                            error={errors.nip}
                            register={register}
                        />
                    }
                    <RentInput
                        label={
                            LABELS.phone[
                            customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                        }
                        description="Numer telefonu będzie potrzebny w trakcie realizacji transakcji do potwierdzenia warunków umowy oraz przy odbiorze sprzętu."
                        register={register}
                        name="phone"
                        error={errors["c17"] || errors.phone}
                    />
                </> : <>
                    <SubtitleContainer>
                        <RentSubtitle>Dane podmiotu:</RentSubtitle>
                    </SubtitleContainer>
                    <RentInput
                        label={LABELS?.nip[customerType]}
                        name="nip"
                        error={errors.nip}
                        register={register}
                    />
                    <SubtitleContainer>
                        <RentSubtitle>Dane osobowe osoby reprezentującej Najemcę:</RentSubtitle>
                    </SubtitleContainer>
                    <RentInput
                        label={
                            LABELS.phone[
                            customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                        }
                        description="Numer telefonu będzie potrzebny w trakcie realizacji transakcji do potwierdzenia warunków umowy oraz przy odbiorze sprzętu."
                        register={register}
                        name="phone"
                        error={errors["c17"] || errors.phone}
                    />
                </>
            }
        </Container>
    )
};
export default AuthologicFormMobile;

const SubtitleContainer = styled.div`
  margin-bottom: 28px;
  font-size: 1.75rem;
  text-align: left;
`;

const Container = styled.div`
  margin-bottom: 40px;
`;
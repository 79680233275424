import styled from "styled-components";

const Root = styled.div`
    background-color: ${props => props.theme.white};
    padding: 20px;
    border-radius: 20px;
`;
const Card = ({ children }) => {
    return <Root>{children}</Root>;
};

export default Card;

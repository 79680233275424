import styled from "styled-components";
import { DESKTOP_CONTAINER_WIDTH } from "../../variables";

const NegativeContainer = styled.div`
    max-width: ${DESKTOP_CONTAINER_WIDTH}px;
    margin: 0 -25px;
    @media screen and (max-width: 420px) {
        margin: 0 -15px;
    }
`;

export default NegativeContainer;

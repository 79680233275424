import React from 'react'
import styled, { css } from 'styled-components'


const Badge2 = ({ status = 'primary', children }) => {
    return (
        <StyledBadge status={status}>{children}</StyledBadge>
    )
}

export default Badge2


const successStyle = css`
    background: ${props => props.theme.primary};
`

const dangerStyle = css`
    background: ${props => props.theme.danger};
`

const getTypeStyle = (status) => {
    switch (status) {
        case 'success': return successStyle
        case 'danger': return dangerStyle
        default: return successStyle
    }
}

const StyledBadge = styled.div`
    ${props => getTypeStyle(props.status)};
    padding: 5px 8px;
    display: inline-block;
    border-radius: 50px;
    color: ${props => props.theme.white};
    text-transform: uppercase;
    font-size: 10px

`
const BackIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      {...props}
    >
      <g data-name="&gt;-ico" fillRule="evenodd">
        <path
          data-name="Path 129"
          d="M11.57 14.646a.768.768 0 01-1.088 0L7.1 11.269a1.794 1.794 0 010-2.538l3.378-3.377a.769.769 0 111.088 1.087L8.188 9.819a.256.256 0 000 .362l3.382 3.378a.769.769 0 010 1.087z"
        />
        <path
          data-name="Path 130"
          d="M10 18.462c2.169 0 5.134-.2 6.985-.343a1.228 1.228 0 001.134-1.134c.143-1.851.343-4.816.343-6.985s-.2-5.134-.343-6.985a1.228 1.228 0 00-1.134-1.134c-1.851-.144-4.816-.343-6.985-.343s-5.134.2-6.985.343a1.228 1.228 0 00-1.134 1.134C1.737 4.866 1.538 7.831 1.538 10s.2 5.134.343 6.985a1.228 1.228 0 001.134 1.134c1.851.143 4.816.343 6.985.343zm7.1 1.191C15.252 19.8 12.235 20 10 20s-5.252-.2-7.1-.347A2.767 2.767 0 01.347 17.1C.2 15.252 0 12.235 0 10s.2-5.252.347-7.1A2.767 2.767 0 012.9.347C4.748.2 7.766 0 10 0s5.252.2 7.1.347A2.767 2.767 0 0119.653 2.9C19.8 4.748 20 7.766 20 10s-.2 5.252-.347 7.1a2.767 2.767 0 01-2.553 2.553z"
        />
      </g>
    </svg>
);

export default BackIcon;

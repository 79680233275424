import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import Button from "../../components/Buttons/Button";
import NextArrowIcon from "../../components/Icons/components/NextArrowIcon";
import Layout from "../../components/Layout/Layout";
import MainProductCalculator from "../../components/Product/ProductTitle/MainProductCalculator";
import FooterSummary from "../../components/Product/ProductTitle/FooterSummary";
import WhyWorth from "../../components/Product/ProductTitle/WhyWorth";
import { Link } from "react-router-dom";
import { selectMobile } from '../../features/appSlice'
import RecomendedProducts from "../../components/Product/ProductTitle/RecomendedProducts";
import MainProduct from './components/MainProduct'
import SocialBox from "../../components/SocialBox/SocialBox";
import CompanyRow from "../../components/CompanyRow/CompanyRow";
import RepurchaseBox from "../../components/RepurchaseBox/RepurchaseBox";
import MainProductMobile from "./components/MainProductMobile";


const Home = () => {
    const isMobile = useSelector(selectMobile)

    return (
        <Layout desktopTitle="Główny przedmiot najmu" >

            <Row isMobile={isMobile}>
                <Col1>
                    {
                        isMobile ? <MainProductMobile /> : <MainProduct />
                    }
                </Col1>
                <Col2>
                    <Col2Bg>
                        <CompanyRow />
                        <MainProductCalculator />
                        <RepurchaseBox />

                        <Link to="/basket">
                            <Button RightIcon={<NextArrowIcon />}>
                                Dalej
                            </Button>
                        </Link>

                    </Col2Bg>
                    {
                        !isMobile && <SocialBox />
                    }
                </Col2>
            </Row>
            <RecomendedProducts />
            <WhyWorth />
            {
                isMobile && <SocialBox />
            }
            {isMobile && <FooterSummmaryWrapper>
                <FooterSummary />
            </FooterSummmaryWrapper>}
        </Layout >
    );
};

export default Home;

const Col1 = styled.div`
    flex: 5;
    display:flex;
    flex-direction: column;
    gap: 40px;
`

const Col2 = styled.div`
    flex: 3;
    display:flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 20px;
`

const mobileStyle = css`
    flex-direction: column;
    ${Col1} {
        flex: 1;
    }
    ${Col2} {
        flex: 1;
    }
`

const Row = styled.div`
    display: flex;
    gap: 20px;
    ${props => props.isMobile && mobileStyle};
`

const Col2Bg = styled.div`
    background-color: ${props => props.theme.white};
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 20px;
`

const FooterSummmaryWrapper = styled.div`
    margin-top: 35px;
`
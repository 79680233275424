const MapPinIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        {...props}
    >
        <g data-name="Group 948">
            <g data-name="&gt;-ico">
                <path
                    data-name="Path 130"
                    d="M10 1.538c-2.169 0-5.134.2-6.985.343a1.228 1.228 0 00-1.134 1.134C1.738 4.866 1.538 7.831 1.538 10s.2 5.134.343 6.985a1.228 1.228 0 001.134 1.134c1.851.144 4.816.343 6.985.343s5.134-.2 6.985-.343a1.228 1.228 0 001.134-1.134c.144-1.851.343-4.816.343-6.985s-.2-5.134-.343-6.985a1.228 1.228 0 00-1.134-1.134c-1.851-.143-4.816-.343-6.985-.343zM2.9.347C4.748.2 7.765 0 10 0s5.252.2 7.1.347A2.767 2.767 0 0119.653 2.9C19.8 4.748 20 7.765 20 10s-.2 5.252-.347 7.1a2.767 2.767 0 01-2.553 2.553C15.252 19.8 12.234 20 10 20s-5.252-.2-7.1-.347A2.767 2.767 0 01.347 17.1C.2 15.252 0 12.234 0 10s.2-5.252.347-7.1A2.767 2.767 0 012.9.347z"
                    fill="currentColor"
                    fillRule="evenodd"
                />
            </g>
            <g fill="currentColor">
                <path
                    data-name="Path 940"
                    d="M10 6.989A1.741 1.741 0 008.1 8.5a1.741 1.741 0 001.9 1.511A1.741 1.741 0 0011.9 8.5 1.741 1.741 0 0010 6.989zm0 2.495A1.133 1.133 0 018.761 8.5 1.133 1.133 0 0110 7.516a1.133 1.133 0 011.239.984A1.134 1.134 0 0110 9.481z"
                />
                <path
                    data-name="Path 941"
                    d="M9.999 5.989a2.9 2.9 0 00-2.9 2.889 5.152 5.152 0 001.116 2.811l.086.128a24.535 24.535 0 001.513 1.972l.187.219.187-.219a24.587 24.587 0 001.512-1.97l.087-.13a5.161 5.161 0 001.116-2.813 2.9 2.9 0 00-2.904-2.887zm1.378 5.419l-.089.131a20.026 20.026 0 01-1.288 1.7 22.62 22.62 0 01-1.288-1.7l-.086-.131a4.77 4.77 0 01-1.036-2.54 2.409 2.409 0 114.818 0 4.7 4.7 0 01-1.031 2.54z"
                />
            </g>
        </g>
    </svg>
);

export default MapPinIcon;

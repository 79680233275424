const Person = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="67.94" height="91.275"
        viewBox="0 0 67.94 91.275"
        stroke={props.color ? props.color : '#0486D6'}
        {...props}
    >
        <g id="Group_1332" data-name="Group 1332" transform="translate(2 2)">
            <ellipse id="Ellipse_384" data-name="Ellipse 384" cx="22.295" cy="22.295" rx="22.295" ry="22.295" transform="translate(9.675 0)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <path id="Path_1015" data-name="Path 1015" d="M100.642,108.109a31.97,31.97,0,0,0-63.94,0h0v10.717h63.94V108.109Z" transform="translate(-36.702 -31.55)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <path id="Path_1016" data-name="Path 1016" d="M49.53,44.253H66.4A10.071,10.071,0,0,0,76.468,34.182" transform="translate(-38.822 -24.618)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <path id="Path_1017" data-name="Path 1017" d="M97.559,44.253H92.3A10.07,10.07,0,0,1,82.227,34.182" transform="translate(-44.224 -24.618)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
        </g>
    </svg>

);

export default Person;

const Businessman = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg"
        width="66.97" height="89.951"
        viewBox="0 0 66.97 89.951"
        stroke={props.color ? props.color : '#0486D6'}
        {...props}
    >
        <g id="Group_1336" data-name="Group 1336" transform="translate(-34.702 -20.725)">
            <g id="Group_1336-2" data-name="Group 1336" transform="translate(36.702 22.725)">
                <circle id="Ellipse_386" data-name="Ellipse 386" cx="21.956" cy="21.956" r="21.956" transform="translate(9.528)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <path id="Path_1025" data-name="Path 1025" d="M99.672,107.624a31.485,31.485,0,0,0-62.97,0h0v10.554h62.97V107.624Z" transform="translate(-36.702 -32.227)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <path id="Path_1026" data-name="Path 1026" d="M49.53,44.1H66.141a9.918,9.918,0,0,0,9.918-9.918" transform="translate(-38.984 -24.763)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <path id="Path_1027" data-name="Path 1027" d="M97.327,44.1H92.145a9.918,9.918,0,0,1-9.918-9.918" transform="translate(-44.801 -24.763)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            </g>
            <path id="Path_1028" data-name="Path 1028" d="M73.475,118.332l-7.049-7.049,7.049-33.356,7.049,33.356Z" transform="translate(-5.288 -9.82)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
        </g>
    </svg>


);

export default Businessman;



import styled from "styled-components";
import guitar1 from "./guitar_1.png";
import guitar2 from "./guitar_2.png";
import guitar3 from "./guitar_3.png";

const StyledGalleryBox = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 1rem;
`;

const MinorPhotos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
`;

const MinorPhoto = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.white};
    max-height: 200px;
`;

const MainPhoto = styled.div`
    flex: 3;
    display: flex;
    align-items: center;
    background: ${(props) => props.theme.white};
    max-height: 650px;
`;

const Img = styled.img`
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
    object-fit: contain;
`;

const GalleryBox = ({ photosArr = false }) => {
    const photos = photosArr ? photosArr : [guitar1, guitar2, guitar3];

    return (
        <StyledGalleryBox>
            <MinorPhotos>
                {photos.slice(1, 4).map((photo, index) => (
                    <MinorPhoto
                        key={index}
                    >
                        <Img src={photo} />
                    </MinorPhoto>
                ))}
            </MinorPhotos>
            <MainPhoto>
                <Img src={photos[0]} />
            </MainPhoto>
        </StyledGalleryBox>
    );
};

export default GalleryBox;

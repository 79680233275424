import React, { useState } from 'react'
import styled from 'styled-components'
import MessangerIcon from '../Icons/components/MessangerIcon'
import QuestionMarkIcon from '../Icons/components/QuestionMarkIcon'
import ShareLinkModal from "../../views/BasketView/components/ShareLinkModal";
import ShareBasketLink from '../ShareBasketLink/ShareBasketLink'

const SocialBox = () => {
    const [isModal, setIsModal] = useState(false)

    const handleOpenModal = () => {
        setIsModal(true)
    }

    const handleCloseModal = () => {
        setIsModal(false)
    }
    return (
        <StyledSocialBox>
            <Box>
                <StyledNoramlLink href="https://www.facebook.com/messages/t/623742458256865">
                    <MessangerIcon className='icon' width={20} height={20} />
                    <Text>
                        Porozmawiaj z nami
                    </Text>
                </StyledNoramlLink>
                <StyledLink href="https://vibe.pl/jak-to-dziala/" target="_blank" rel="noopener noreferrer">
                    <QuestionMarkIcon className='icon' width={20} height={20} />
                    <Text>
                        Sprawdź jak to działa
                    </Text>
                </StyledLink>
                <StyledShareBasketLink onClick={handleOpenModal} />
                <ShareLinkModal show={isModal} closeModal={handleCloseModal} />
            </Box>
        </StyledSocialBox>
    )
}

export default SocialBox

const StyledSocialBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 40px 0px;
    .icon {
        color: ${props => props.theme.primary};
    }
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`


const Text = styled.div`
    font-size: 17px;
    text-decoration: underline;
`

const StyledLink = styled.a`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
`
const StyledNoramlLink = styled.a`
    display: flex;
    align-items: center;
    gap: 10px;
`

const StyledShareBasketLink = styled(ShareBasketLink)`
     margin-top: 20px;
`


const BackArrowIcon = (props) => (
    <svg
        data-name="&gt;-ico"
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 8.322 15.544"
        {...props}
    >
        <path
            data-name="Path 129"
            d="M7.963 15.184a1.226 1.226 0 01-1.736 0L.838 9.797a2.863 2.863 0 010-4.049L6.227.36a1.227 1.227 0 011.736 1.734L2.574 7.483a.408.408 0 000 .578l5.389 5.389a1.227 1.227 0 010 1.734z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export default BackArrowIcon;

import React from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Loader from '../../../components/Loader/Loader';
import ProductDetails from '../../../components/Product/ProductTitle/ProductDetails';
import ProductTitle from '../../../components/Product/ProductTitle/ProductTitle';
import ImageSwiper from '../../../components/Swiper/ImageSwiper';

import {
    selectMainProduct,
    selectMinimalMonthsParsed,
    selectShopUuid,
} from "../../../features/basketSlice";
import ProductService from '../../../services/ProductsService';
import Card from '../../../components/Card/Card';

const MainProductMobile = () => {

    const mainProduct = useSelector(selectMainProduct);
    const shop_uuid = useSelector(selectShopUuid);
    const minimal_months = useSelector(selectMinimalMonthsParsed);

    const { data, isLoading, isSuccess } = useQuery(
        ["product", mainProduct?.id, shop_uuid, minimal_months],
        ProductService.getProductData(
            mainProduct?.id,
            shop_uuid,
            minimal_months
        ),
        {
            keepPreviousData: true
        }
    );

    return (

        <>
            {
                isLoading && <Loader />
            }
            {
                isSuccess && data && (
                    <>
                        <Card>
                            <ImageSwiper
                                images={[
                                    mainProduct.image_url,
                                    ...mainProduct.imageUrls,
                                ]}
                            />
                        </Card>
                        <Card>
                            <ProductTitle
                                label="Twój główny przedmiot najmu"
                                name={mainProduct.name}
                                availabilityText={mainProduct.availabilityText}
                                availabilitySymbol={
                                    mainProduct.availabilitySymbol
                                }
                            />
                            <ProductDetails
                                location={mainProduct.localizations[0].city}
                                condition={mainProduct.conditionText}
                                description={mainProduct.description}
                                openDefault={false}
                                type={data.type}
                                extraContent={
                                    <ProductDetails
                                        price={mainProduct.formattedGrossPrice}
                                        type={data.type}
                                    />
                                }
                            />
                        </Card>
                    </>

                )
            }

        </>

    )
}

export default MainProductMobile
import styled from "styled-components";
import Draggable from "react-draggable";
import { useCallback, useEffect, useRef, useState } from "react";

const Root = styled.div`
    height: 44px;
    width: 100%;
    position: relative;
    border-radius: 25px;
    background: #f9f9f9;
    box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.16);
`;

const Labels = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;
    line-height: 44px;
    & > div {
        flex: 1;
        text-align: center;
        position: relative;
        cursor: pointer;

        & span {
            display: inline-block;
            font-size: 0.8571428571428571em;
            font-weight: bold;
            letter-spacing: 0.025em;
            line-height: 16px;
            &:before,
            &:after {
                content: "";
                display: block;
                width: 1px;
                height: 6px;
                background: white;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
            }
            &:before {
                top: 2px;
            }
            &:after {
                bottom: 2px;
            }
        }
    }
`;
const Circle = styled.div`
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background: ${({ theme }) => theme.white};
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
    position: absolute;
    cursor: pointer;
    top: -10px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${({ isMoving }) => (!!isMoving ? "none" : "all 0.5s")};
    &:hover {
        transform: scale(1.1);
    }
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
        right: 3px;
        bottom: 3px;
        border: 1px solid ${({ theme }) => theme.grey};
        border-radius: 50%;
    }
    & strong {
        font-size: 1.5714285714285714em;
        line-height: 22px;
    }
    & span {
        font-size: 0.5714285714285714em;
        color: #798090;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.025em;
    }
`;

const PeriodInput = ({
    values = ["1+", "3+", "6+", "12+", "24+"],
    onChange = (e) => console.log(e),
    value: _value = 2,
}) => {
    const rootRef = useRef(null);
    const dragRef = useRef(null);
    const resizeTimeout = useRef(null);

    const [containerWidth, setContainerWidth] = useState(null);
    const steps = Math.ceil(containerWidth / values.length);
    const stepWidth = containerWidth / values.length;
    const margin = stepWidth / 2 - 65 / 2;
    const bounds = {
        left: 0,
        right: containerWidth - 65,
    };
    const [value, setValue] = useState();

    // const [value, setValue] = useState(0);

    const [isMoving, setMoving] = useState(true);

    const calcIndex = useCallback(
        (value) => {
            return Math.floor(
                (containerWidth -
                    margin -
                    65 -
                    (containerWidth - 65 - value) +
                    steps / 2) /
                steps
            );
        },
        [containerWidth, margin, steps]
    );
    const handleStart = () => {
        setMoving(true);
    };
    const handleDrag = (e, { x }) => {
        setValue(x);
    };
    const handleStop = (e, { x }) => {
        setMoving(false);
        setTimeout(() => {
            const index = calcIndex(value);
            setValue(index * steps + margin);
        });
    };
    const handleClick = (index) => {
        setMoving(false);
        setValue(index * steps + margin);
    };
    useEffect(() => {
        const { width } = rootRef.current.getBoundingClientRect();
        setContainerWidth(width);
    }, [rootRef]);
    useEffect(() => {
        setValue(_value * steps + margin);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerWidth, steps, margin]);
    useEffect(() => {
        const index = calcIndex(value);

        if (value !== undefined /* && value >= 0 */ /* && _value !== index */) {
            onChange(index);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        setValue(_value * steps + margin);

        const onResize = () => {
            clearTimeout(resizeTimeout.current);
            const { width } = rootRef.current.getBoundingClientRect();
            setMoving(true);
            setContainerWidth(width);
            resizeTimeout.current = setTimeout(() => {
                setMoving(false);
            }, 100);
        };
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize)
            clearTimeout(resizeTimeout.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    const getValue = useCallback(
        (v) => {
            const index = calcIndex(v);
            return values[index];
        },
        [values, calcIndex]
    );
    return (
        <Root ref={rootRef}>
            <Labels>
                {values.map((label, index) => (
                    <div key={index} onClick={() => handleClick(index)}>
                        <span>{label}</span>
                    </div>
                ))}
            </Labels>
            <Draggable
                axis="x"
                handle=".handle"
                position={{ x: value, y: 0 }}
                grid={[1, 1]}
                scale={1}
                bounds={bounds}
                onStart={handleStart}
                onDrag={handleDrag}
                onStop={handleStop}
                nodeRef={dragRef}
            >
                <Circle className="handle" isMoving={isMoving} ref={dragRef}>
                    <strong>{getValue(value)}</strong>
                </Circle>
            </Draggable>
        </Root>
    );
};

export default PeriodInput;

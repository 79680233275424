import { useState } from "react";
import styled from "styled-components";
import { Swiper as NativeSwiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

const Root = styled.div`

    .swiper-slide {
        height: 100% !important;
    }
`
const Pagination = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
`;
const AbsolutePagination = styled(Pagination)`
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    z-index: 1;
`;
const Circle = styled.div`
    width: 10px;
    height: 10px;
    transition: all 0.3s;
    background: ${({ active }) => (!!active ? "#798090" : "transparent")};
    border: 1px solid #798090;
    border-radius: 50%;
    margin: 0 3.5px;
`;

const Swiper = ({
    slides = [],
    isAbsoluteNavigation = false,
    showNavigation = true,
    ...props
}) => {
    const [active, setActive] = useState(0);
    return (
        <Root>
            <NativeSwiper
                spaceBetween={50}
                slidesPerView={1}
                autoHeight={true}
                onSlideChange={({ activeIndex }) => setActive(activeIndex)}
                {...props}
            >
                {slides.map((Component, index) => (
                    <SwiperSlide key={index}>{Component}</SwiperSlide>
                ))}
                {showNavigation && (
                    <>
                        {!!isAbsoluteNavigation && (
                            <AbsolutePagination>
                                {Array(slides.length)
                                    .fill()
                                    .map((_, index) => (
                                        <Circle
                                            active={index === active}
                                            key={index}
                                        />
                                    ))}
                            </AbsolutePagination>
                        )}
                        {!isAbsoluteNavigation && (
                            <Pagination>
                                {Array(slides.length)
                                    .fill()
                                    .map((_, index) => (
                                        <Circle
                                            active={index === active}
                                            key={index}
                                        />
                                    ))}
                            </Pagination>
                        )}
                    </>
                )}
            </NativeSwiper>
        </Root>
    );
};

export default Swiper;

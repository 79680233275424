import styled from "styled-components";
// import ProductImage from "../ProductBox/ProductImage";

const Root = styled.div`
    display: flex;
    align-items: flex-start;
    background-color: ${({ theme }) => theme.white};
    padding: 10px;
    border-radius: 15px;
`;

const DescriptionContainer = styled.div`
    flex: 1;
    margin-left: 15px;
    line-height: 19px;
    line-height: 19px;
`;
const Name = styled.div``;
const Address = styled.div`
    max-width: 180px;
`;

const PlaceBox = ({ location, address = "Nowy", imageSrc, onClick }) => (
    <Root onClick={onClick}>
        {/* <ProductImage size={50} src={imageSrc} /> */}
        <DescriptionContainer>
            {location.shop_name && <Name>{location.shop_name}</Name>}
            {address && (
                <>
                    <Address>{`${location.city}, ul. ${location.street}`}</Address>
                    {/* <Address>{`${location.postal_code}, tel. ${location.phone}`}</Address> */}
                    <Address>{`${location.postal_code}`}</Address>
                </>
            )}
        </DescriptionContainer>
    </Root>
);

export default PlaceBox;

import { useSelector } from "react-redux";
import { selectMobile } from "../../features/appSlice";
import ProductDesktop from "./ProductDesktop";
import ProductMobile from "./ProductMobile";

const ProductsCategories = ({ navProps }) => {
    const isMobile = useSelector(selectMobile);

    if (isMobile) {
        return <ProductMobile navProps={navProps} />;
    }
    return <ProductDesktop navProps={navProps} />;
};

export default ProductsCategories;

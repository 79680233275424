const NextArrowIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 8.322 15.544"
        {...props}
    >
        <g data-name="&gt;-ico">
            <path
                data-name="Path 129"
                d="M.359.36a1.226 1.226 0 011.736 0l5.389 5.387a2.863 2.863 0 010 4.049l-5.389 5.388A1.227 1.227 0 01.359 13.45l5.389-5.389a.408.408 0 000-.578L.359 2.094a1.227 1.227 0 010-1.734z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </g>
    </svg>
);

export default NextArrowIcon;

import styled from "styled-components";
import CurrencyIcon from "../Icons/components/CurrencyIcon";
import Loader from "../Loader/Loader";
import ProductImage from "./ProductImage";

const Root = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
`;

const DescriptionContainer = styled.div`
    flex: 1;
    margin-left: 15px;

`;
const Name = styled.div`
    font-size: 18px;
    margin-bottom: 8px;
    text-align: left;
`;

const IconWrapper = styled.span`
    color: ${props => props.theme.primary};
    margin-right: 10px;
`;

const Text = styled.p`
    font-size: 14px;
    margin-right: 10px;
`;

const Installment = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

const InstallmentWraper = styled.div`
    display: flex;
    align-items: center;
`;

const LoaderContainer = styled.div`
    position: absolute;
    top: 0;
    transform: translate(0%, -25%);
    width: 100%;
`;


const ProductBoxAlternative = ({
    name,
    quantity,
    installment,
    availabilitySymbol,
    availability,
    status,
    price,
    onRemove = null,
    url = "/",
    imageSize,
    imageSrc,
    isLoadingMonthCost
}) => {
    return (
        <Root>
            <ProductImage size={imageSize} src={imageSrc} />
            <DescriptionContainer>
                <Name>
                    <strong>{name}</strong>
                </Name>

                <InstallmentWraper>
                    <IconWrapper>
                        <CurrencyIcon width={20} height={20} />
                    </IconWrapper>
                    <Text>Rata miesięczna:</Text>
                    <div style={{ position: 'relative' }}>
                        <Installment>{installment} zł brutto</Installment>
                        {
                            !installment && (
                                <LoaderContainer>
                                    <Loader />
                                </LoaderContainer>
                            )
                        }
                    </div>
                </InstallmentWraper>
            </DescriptionContainer>
        </Root >
    );
};

export default ProductBoxAlternative;

import styled from "styled-components";
import { DESKTOP_CONTAINER_WIDTH } from "../../variables";

const Container = styled.div`
    padding: 0 1.7857142857142858em;
    max-width: ${DESKTOP_CONTAINER_WIDTH}px;
    margin: auto;
    @media screen and (max-width: 420px) {
        padding: ${({ mainContainer }) =>
            mainContainer ? "0" : "0 15px"};
    }
`;

export default Container;

import { useSelector } from "react-redux";
import { selectMobile } from "../../../features/appSlice";
import DesktopContractData from "./DesktopContractData";
import MobileContractData from "./MobileContractData";
import AuthologicIntegrationProvider from "../../mObywatelIntegration/AuthologicIntegration";

export const NIP_ERROR =
    "Sprawdź, czy wprowadzony numer NIP jest prawidłowy. Wprowadź NIP jako ciąg liczb, bez spacji, myślników, ani innych dodatkowych znaków";

export const LABELS = {
    nip: {
        LEGAL_PERSON: "NIP Spółki / Fundacji / Instytucji",
        INDIVIDUAL_ENTREPRENEUR: "NIP",
    },
    companyName: {
        LEGAL_PERSON: "Pełna nazwa Spółki / Fundacji / Instytucji",
        INDIVIDUAL_ENTREPRENEUR: "Pełna nazwa działalności gospodarczej",
    },
    firstName: {
        LEGAL_PERSON: "Imię osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "Imię",
        NATURAL_PERSON: "Imię",
    },
    lastName: {
        LEGAL_PERSON: "Nazwisko osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "Nazwisko",
        NATURAL_PERSON: "Nazwisko",
    },
    phone: {
        LEGAL_PERSON: "Telefon komórkowy osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "Numer telefonu",
        NATURAL_PERSON: "Numer telefonu",
    },
    personalNumber: {
        LEGAL_PERSON: "PESEL osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "PESEL",
        NATURAL_PERSON: "PESEL",
    },
    idCard: {
        LEGAL_PERSON:
            "Seria i numer dowodu osobistego osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "Seria i numer dowodu osobistego",
        NATURAL_PERSON: "Seria i numer dowodu osobistego",
    },
    address: {
        LEGAL_PERSON:
            "Adres kontaktowy lub adres wysyłki",
        INDIVIDUAL_ENTREPRENEUR: "Adres zamieszkania lub adres wysyłki",
        NATURAL_PERSON: "ADRES ZAMIESZKANIA",
    },
};

const ContractData = ({ navProps }) => {
    const isMobile = useSelector(selectMobile);
    if (isMobile) {
        return (
            <AuthologicIntegrationProvider>
                <MobileContractData navProps={navProps} />;
            </AuthologicIntegrationProvider>)
    }
    return (
        <AuthologicIntegrationProvider>
            <DesktopContractData navProps={navProps} />;
        </AuthologicIntegrationProvider>)
};

export default ContractData;

import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import styled from "styled-components";

import MinimalMonthPeriod from "../../../connectedComponents/MonthPeriod/MinimalMonthPeriod";
import {
    selectIsCompany,
    selectMainProduct,
    selectMinimalMonthsParsed,
    selectRealMonthsParsed,
} from "../../../features/basketSlice";
import ProductService from "../../../services/ProductsService";
import { polishPlural } from "../../../utils/functions";
import CalendarIcon from "../../Icons/components/CalendarIcon";
import SectionHeader from "../../SectionHeader/SectionHeader";
import Tooltip from "../../Tooltip/Tooltip";
import PriceRow from "../PriceRow";
import Deposit from "./Deposit";

const Root = styled.div`
    margin-bottom: 24px;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.primary};
`;

const IconLabel = styled.div`
    line-height: 26px;
    margin-left: 8px;
    color: ${({ theme }) => theme.black};
    font-size:17px;
`;
const PriceContainer = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;
const Price = styled.div`
    text-align: right;
    line-height: 26px;
`;
const PriceGross = styled.div`
    font-size: 18px;
`;

const MonthContainer = styled(PriceContainer)`
    margin-top: 15px;
`;


const MainProductCalculator = () => {
    const mainProduct = useSelector(selectMainProduct);
    const real_months = useSelector(selectRealMonthsParsed);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany);
    const { data, isLoading } = useQuery(
        [
            "calculation",
            { main_product_id: mainProduct.id, real_months, minimal_months, isCompany },
        ],
        ProductService.getCalculation({
            main_product_id: mainProduct.id,
            real_months,
            minimal_months,
        }),
        {
            select: (d) => {
                if (isCompany) {
                    return d.company_option;
                }
                return d.natural_person_option;
            }
        }
    );


    const gross_month_cost = data?.option_formatted?.gross_month_cost;
    const net_month_cost = data?.option_formatted?.net_month_cost;
    const isLoadingTotal = isLoading

    return (
        <Root>
            <SectionHeader
                Icon={
                    <Tooltip
                        description={`
Po upływie minimalnego okresu najmu możesz zwrócić produkt, lub kontynuować jego wypożyczanie tak długo jak chcesz. W momencie zwrotu przedmiotu najmu, umowa ulega zakończeniu.

Pamiętaj, że w każdej chwili możesz wykupić przedmiot najmu, zarówno w trakcie trwania minimalnego okresu najmu, jak i po jego upływie.

Dla każdego produktu, po upływie określonego czasu trwania umowy najmu, produkt przechodzi na Twoją własność za symboliczne 1zł.
            `}
                    />
                }
            >
                Wybierz minimalny okres najmu:
            </SectionHeader>

            <MinimalMonthPeriod />
            <div>
                <PriceRow
                    gross_month_cost={gross_month_cost}
                    net_month_cost={net_month_cost}
                    isLoading={isLoadingTotal}
                />
            </div>

            <MonthContainer>
                <IconContainer>
                    <CalendarIcon width={20} height={20} />
                    <IconLabel>Minimalny okres najmu:</IconLabel>
                </IconContainer>
                <Price>
                    <PriceGross>
                        <strong>
                            {minimal_months}{" "}
                            {polishPlural(
                                "Miesiąc",
                                "Miesiące",
                                "Miesięcy",
                                minimal_months
                            )}
                        </strong>
                    </PriceGross>
                </Price>
            </MonthContainer>
            <DepositContainer>
                <Deposit price={data?.option_formatted?.net_administrative_fee} />
            </DepositContainer>
        </Root>
    );
};

export default MainProductCalculator;

const DepositContainer = styled.div`
    margin-top: 20px;
`
import { axios } from "../axios";
import { parseParams } from "../utils/functions";

const RecommendedProductsService = {
    getAllRecommendedProducts: (type, shop_uuid, months, mainProductId, params) => () => {
        return axios
            .get(`/init/recommended-products/${type}/${shop_uuid}/${months}/${mainProductId}/${params ? parseParams(params) : ''}`)
            .then((res) => {
                return res.data;
            });
    },
    getAllRecommendedCategories: (type, shop_uuid, mainProductId) => () => {
        return axios.get(`/init/recommended-categories/${type}/${shop_uuid}/${mainProductId}`).then(res => res.data)
    },

    getAllCategoryProducts: (type, shop_uuid, months, mainProductId, params) => () => {
        return axios.get(`/init/products/${type}/${shop_uuid}/${months}/${mainProductId}/${params ? parseParams(params) : ''}`).then(res => res.data)
    },


};

export default RecommendedProductsService;

import { useHistory } from "react-router";
// eslint-disable-next-line no-unused-vars
import { Link } from "react-router-dom";
import styled from "styled-components";
import RefreshIcon from "../../../components/Icons/components/RefreshIcon";
import PinInput from "../../../components/Inputs/PinInput";
import RentLayout from "../components/RentLayout";
import RentTitle from "../components/RentTitle";
import { useMutation, useQuery } from "react-query";
import Step5Service from "../../../services/Step5Service";
import { useSelector } from "react-redux";
import { selectTransactionId } from "../../../features/transactionSlice";
import Loader from "../../../components/Loader/Loader";
import { toast } from "react-toastify";
import Step6Service from "../../../services/Step6Service";
import { getDataFromAgreementNumber } from "../../../utils/functions";
import { selectMobile } from '../../../features/appSlice'
import Button from "../../../components/Buttons/Button";

const Subtitle = styled.div`
    margin: -13px 0 17px;
    line-height: 18px;
    font-size: 17px;
`;
const Text = styled.p`
    margin-bottom: 42px;
    line-height: 20px;
    white-space: pre-wrap;
    font-size: 17px;
`;
const PinContainer = styled.div`
    & > div:first-child {
        display: flex;
        justify-content: center;
    }
`;
const Links = styled.div`
    display: ${props => props.isMobile ? 'flex' : 'inline-flex'};
    align-items: center;
    margin-top: 42px;
    text-decoration: underline;
    svg {
        font-size: 20px;
        color: ${({ theme }) => theme.primary};
        margin-right: 10px;
    }
`;

const Wrapper = styled.div`
    max-width: ${props => props.isMobile ? '100%' : '590px'};
    text-align: ${props => !props.isMobile && 'center'};
    margin: auto;
`;

const SendSMSButton = styled(Button)`
    margin-bottom: 42px;

`

const OnlineContract = ({ navProps }) => {
    const isMobile = useSelector(selectMobile)
    const history = useHistory();
    const transactionId = useSelector(selectTransactionId);
    const { data, isLoading: isGettingData } = useQuery(
        "payment-summary",
        Step6Service.getData(transactionId)
    );
    const { mutateAsync: sendSms, isLoading: isSendingSms, } = useMutation(
        Step5Service.sendSms(transactionId)
    );
    const { mutateAsync: verifySms, isLoading: isVeryfingSms } = useMutation(
        Step5Service.verifySmsCode(transactionId),
        {
            onError: () => {
                toast.error("Kod jest nieprawidłowy");
            },
        }
    );

    const handleSendSMS = () => {
        sendSms()
    }



    const onPinFill = async (e) => {
        await verifySms({ code: e });
        history.push("/rent/online-payment");
    };

    const sendAgain = async () => {
        await sendSms();
        return false;
    };

    if (isSendingSms || isVeryfingSms || isGettingData) {
        return <Loader fixed />;
    }
    return (
        <RentLayout showNav={false} {...navProps}>
            <Wrapper isMobile={isMobile}>
                <RentTitle>Akceptacja umowy</RentTitle>
                <Subtitle>
                    <div>
                        <strong>nr {data?.agreement_number}</strong>
                    </div>
                    <div>{`z dnia ${getDataFromAgreementNumber(
                        data?.agreement_number
                    )}`}</div>
                </Subtitle>
                <Text>
                    <span>
                        Aby zaakceptować Twoją Umowę Najmu nr {data?.agreement_number} wraz z Ogólnymi Warunkami Najmu (OWN) i przejść do cyklicznej płatności online, wygeneruj jednorazowy kod SMS, który zostanie wysłany na Twój numer telefonu, a następnie wprowadź go poniżej.
                    </span>
                </Text>
                <SendSMSButton
                    onClick={handleSendSMS}
                >
                    Wygeneruj kod SMS
                </SendSMSButton>
                <Text>Wprowadź wygenerowany kod SMS w poniższe pola:</Text>
                <PinContainer>
                    <PinInput onPinFill={onPinFill} isMobile={isMobile} />
                </PinContainer>
                <Links to="#" isMobile={isMobile}>
                    <RefreshIcon />
                    <p style={{ cursor: 'pointer' }} onClick={sendAgain}>Kod SMS nie dotarł? Wygeneruj go ponownie</p>
                </Links>
            </Wrapper>
        </RentLayout>
    );
};

export default OnlineContract;

import { axios } from "../axios";
import { store } from "../store";

const Step6Service = {

    getData: (transation_id, schedule_id) => () => {
        const token = store.getState().transaction.transaction.token;
        const scheduleQuery = schedule_id ? `?schedule_id=${schedule_id}` : '';
        return axios
            .get(
                `/transaction/step/6/summary/${transation_id}${scheduleQuery}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
    registerTransaction: (transation_id) => () => {
        const token = store.getState().transaction.transaction.token;

        return axios
            .post(
                `/transaction/step/6/przelewy24/register/${transation_id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
    registerExtraTransaction: (transation_id,schedule_id) => () => {
        const token = store.getState().transaction.transaction.token;

        return axios
            .post(
                `/transaction/przelewy24/register-new-recurring/${transation_id}/${schedule_id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
    payAgainLogin: (token1, token2) => () => {
        return axios
            .post(`/init/transaction/payment-login/${token1}/${token2}`)
            .then((res) => {
                return res.data;
            });
    },
    checkPaymentStatus: (session_id) => () => {

        return axios
            .get(`/init/transaction/check-payment/${session_id}`)
            .then((res) => {
                return res.data;
            });
    },
    assignVoucher: () => ({transaction_id, code}) => {
        const token = store.getState().transaction.transaction.token;
        return axios
            .post(
                `/transaction/step/6/voucher/${transaction_id}`,
                {code: code},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
    removeVoucher: () => ({transaction_id}) => {
        const token = store.getState().transaction.transaction.token;
        return axios
            .delete(
                `/transaction/step/6/voucher/${transaction_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    }
};

export default Step6Service;

import { useHistory } from "react-router";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout";
import { useSelector } from "react-redux";
import { selectMobile } from "../../features/appSlice";
import Guitar from './guitar.svg'
import Button from "../../components/Buttons/Button";
import BackArrowIcon from "../../components/Icons/components/BackArrowIcon";

const Container404 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${props => props.isMobile && `min-height: calc(100vh - 89px)`};
`;

const Wrapper = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    @media (max-width: 500px) {
        flex-direction: column;
        text-align: center;
      }
`;

const Title = styled.div`
    font-size: 40px;
    font-weight: bold;
    color: #18B5F5;
`;

const Desc = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

const Error = styled.div`
    font-size: 28px;
    font-weight: bold;
    margin: 14px 0;
`;

const ButtonWrapper = styled.div`
    ${props => props.isMobile ? `margin: 0 20px 37px 20px` : `margin: 40px auto 0 auto`};
    ${props => !props.isMobile && `width: 400px`}
`;

const NotFound = () => {

    const history = useHistory()
    const isMobile = useSelector(selectMobile)

    const handleClick = () => {
        history.goBack()
    }

    return (
        <Layout>
            <Container404 isMobile={isMobile}>
                <Wrapper>
                    <div>
                        <img src={Guitar} alt="guitar" />
                    </div>
                    <div>
                        <Title>
                            Ooops!
                        </Title>
                        <Desc>
                            Pękła struna...
                        </Desc>
                        <Error>
                            404
                        </Error>
                        <Desc>
                            Podana strona nie istnieje
                        </Desc>
                    </div>
                </Wrapper>
                <ButtonWrapper isMobile={isMobile}>
                    <Button
                        onClick={handleClick}
                        LeftIcon={<BackArrowIcon />}
                    >
                        Powrót do poprzedniej strony
                    </Button>
                </ButtonWrapper>
            </Container404>
        </Layout>
    );
};

export default NotFound;

import { useSelector } from "react-redux";
import { selectMobile } from "../../features/appSlice";
import AddServiceDesktop from "./AddServiceDesktop";
import AddServiceMobile from "./AddServiceMobile";

const ProductsCategories = ({ navProps }) => {
    const isMobile = useSelector(selectMobile);

    if (isMobile) {
        return <AddServiceMobile navProps={navProps} />;
    }
    return <AddServiceDesktop navProps={navProps} />;
};

export default ProductsCategories;

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Badge2 from '../../Badge/Badge2'
import FlexBox from '../../FlexBox/FlexBox'
import { Link } from 'react-router-dom'
import { badgeStatus } from '../../../utils/functions'

const ProductRow = ({ type, product, ...props }) => {



    const { id, image_url, name, availabilityText, conditionText, availabilitySymbol } = product.details

    const isCompany = useSelector(state => state.basket.isCompany)

    const price = useMemo(() => {
        return isCompany ?
            product.rental_options.company_option.option_formatted.gross_month_cost :
            product.rental_options.natural_person_option.option_formatted.gross_month_cost
    }, [product, isCompany])

    return (
        <StyledRecomendedProduct to={`/products-collections/${type}/${id}`} {...props}>
            <ImageWrapper>
                <Image src={image_url} />
            </ImageWrapper>
            <Info>
                <Name>{name}</Name>
                {
                    (availabilitySymbol || availabilityText) && (
                        <FlexBox alignItems='center' gap='10px'>
                            {availabilitySymbol && <Badge2 status={badgeStatus(availabilitySymbol)}>{availabilityText}</Badge2>}
                            {conditionText && <Status>{conditionText}</Status>}
                        </FlexBox>
                    )
                }

            </Info>
            <Price>
                Rata miesięczna: <b>{price} zł</b>
            </Price>
        </StyledRecomendedProduct>
    )
}

export default ProductRow

const StyledRecomendedProduct = styled(Link)`
    display: flex;
    align-items: center;
    padding: 20px 15px;
    gap: 20px;
    flex-wrap: wrap;
    padding: 20px;
    border-radius: 20px;
    background-color: ${props => props.theme.white};
`

const ImageWrapper = styled.div`
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    min-width: 60px;
    min-width: 60px;
    height: 60px;
    overflow: hidden;

`
const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
`
const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap:10px;
    width: 100%;
    max-width: 300px;
`

const Name = styled.div`
    font-weight: bold;
    font-size: 20px;

`
const Price = styled.div`
    font-size: 17px;
    margin-left: auto;
    white-space: nowrap;
    b {
        font-size: 21px;
    }
`

const Status = styled.div`
    color: #798090;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
`
import styled from "styled-components";
import CurrencyIcon from "../Icons/components/CurrencyIcon";
import Loader from "../Loader/Loader";

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.primary};
`;

const IconLabel = styled.div`
    line-height: 26px;
    margin-left: 8px;
    color: ${({ theme }) => theme.black};
    font-size:17px;
`;
const PriceContainer = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;
const PriceGross = styled.div`
    font-size: 21px;
    transition: all 0.3s;
`;
const PriceNet = styled.div`
    color: #798090;
    transition: all 0.3s;
    font-size:14px;
`;
const Price = styled.div`
    text-align: right;
    line-height: 26px;
    position: relative;
    ${PriceGross} {
        opacity: ${(props) => (props.isLoading ? 0.2 : 1)};
    }
    ${PriceNet} {
        opacity: ${(props) => (props.isLoading ? 0.2 : 1)};
    }
`;
const LoaderContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
`;
const PriceRow = ({ gross_month_cost, net_month_cost, isLoading = false }) => (
    <PriceContainer>
        <IconContainer>
            <CurrencyIcon width={20} height={20} />
            <IconLabel>Rata miesięczna:</IconLabel>
        </IconContainer>
        <Price isLoading={isLoading} style={{minWidth:100,minHeight:52}}>
            {isLoading && (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            )}
            <PriceGross>
                {gross_month_cost && <strong>{gross_month_cost} zł brutto</strong>}
            </PriceGross>
            <PriceNet>
                {net_month_cost && <strong>{net_month_cost} zł netto</strong>}
            </PriceNet>
        </Price>
    </PriceContainer>
);

export default PriceRow;

import { useMemo } from "react";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import styled from "styled-components";
import MinimalMonthPeriod from "../../../connectedComponents/MonthPeriod/MinimalMonthPeriod";
import RealMonthPeriod from "../../../connectedComponents/MonthPeriod/RealMonthPeriod";
import {
    selectIsCompany,
    selectMinimalMonthsParsed,
} from "../../../features/basketSlice";
import { polishPlural } from "../../../utils/functions";
import Button from "../../Buttons/Button";
import Collapse from "../../Collapse/Collapse";
import CalendarIcon from "../../Icons/components/CalendarIcon";
import NextArrowIcon from "../../Icons/components/NextArrowIcon";
import QuestionMarkIcon from "../../Icons/components/QuestionMarkIcon";
import SectionHeader from "../../SectionHeader/SectionHeader";
import Tooltip from "../../Tooltip/Tooltip";
import PriceRow from "../PriceRow";

const CollapseContainer = styled.div`
    margin-top: 26px;
`;
const CollapseContent = styled.div`
    padding: 30px 0 0;
`;
const Root = styled.div`
    margin-bottom: 24px;
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.primary};
`;

const IconLabel = styled.div`
    line-height: 26px;
    margin-left: 8px;
    color: ${({ theme }) => theme.black};
    font-size: 17px;
`;
const PriceContainer = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;
const Price = styled.div`
    text-align: right;
    line-height: 26px;
`;
const PriceGross = styled.div`
    font-size: 1.2857142857142858em;
`;
const RotatedIcon = styled.div`
    transition: all 0.4s;
    transform: ${({ isOpen }) =>
        !!isOpen ? `rotateZ(90deg)` : `rotateZ(${0});`};
`;
const MonthContainer = styled(PriceContainer)`
    margin-top: 15px;
`;

const PurchaseSummary = styled.div`
    margin-top: 38px;
    line-height: 18px;
`;
const PurchasePrice = styled.div`
    margin-top: 4px;
    font-size: 1.4285714285714286em;
    line-height: 26px;
    display: flex;
`;

const Calculator = ({
    showOptions = true,
    company_option,
    natural_person_option,
    isLoading = false,
}) => {
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany);



    const gross_month_cost = useMemo(() => {
        if (company_option) {
            if (isCompany) {
                return company_option.option_formatted.gross_month_cost;
            } else {
                return natural_person_option.option_formatted.gross_month_cost;
            }
        }
        return null;
    }, [isCompany, company_option, natural_person_option]);


    const net_month_cost = useMemo(() => {
        if (company_option) {
            if (isCompany) {
                return company_option.option_formatted.net_month_cost;
            } else {
                return natural_person_option.option_formatted.net_month_cost;
            }
        }
        return null;
    }, [isCompany, company_option, natural_person_option]);

    const gross_optional_main_item_repurchase = useMemo(() => {
        if (company_option) {
            if (isCompany) {
                return company_option.option_formatted
                    .gross_optional_main_item_repurchase;
            } else {
                return natural_person_option.option_formatted
                    .gross_optional_main_item_repurchase;
            }
        }
        return null;
    }, [isCompany, company_option, natural_person_option]);

    return (
        <Root>
            <SectionHeader Icon={<QuestionMarkIcon />}>
                Wybierz minimalny okres najmu:
            </SectionHeader>

            <MinimalMonthPeriod />
            <div>
                <PriceRow
                    gross_month_cost={gross_month_cost}
                    net_month_cost={net_month_cost}
                    isLoading={isLoading}
                />
            </div>
            <MonthContainer>
                <IconContainer>
                    <CalendarIcon width={20} height={20} />
                    <IconLabel>Minimalny okres najmu:</IconLabel>
                </IconContainer>
                <Price>
                    <PriceGross>
                        <strong style={{ fontSize: "18px" }}>
                            {minimal_months}{" "}
                            {polishPlural(
                                "Miesiąc",
                                "Miesiące",
                                "Miesięcy",
                                minimal_months
                            )}
                        </strong>
                    </PriceGross>
                </Price>
            </MonthContainer>
            {!!showOptions && (
                <CollapseContainer>
                    <Collapse
                        ActionComponent={({ toggleOpen, isOpen }) => (
                            <Button
                                buttonType="outline"
                                RightIcon={
                                    <RotatedIcon isOpen={isOpen}>
                                        <NextArrowIcon />
                                    </RotatedIcon>
                                }
                                onClick={toggleOpen}
                            >
                                Opcje wykupu
                            </Button>
                        )}
                    >
                        <CollapseContent>
                            <SectionHeader
                                Icon={<Tooltip description="Lorem" />}
                            >
                                <div>Opcja wykupu*</div>
                                <div>
                                    Wybierz miesiąc od rozpoczęcia umowy najmu
                                </div>
                            </SectionHeader>
                            <RealMonthPeriod />
                            <PurchaseSummary>
                                <div>
                                    <strong>
                                        Cena wykupu w 12 miesiącu najmu:
                                    </strong>
                                </div>
                                <PurchasePrice>
                                    {!gross_optional_main_item_repurchase && (
                                        <ContentLoader
                                            speed={2}
                                            width={140}
                                            height={27}
                                            viewBox="0 0 140 27"
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#ecebeb"
                                        >
                                            <rect
                                                x="0"
                                                y="0"
                                                rx="3"
                                                ry="3"
                                                width="140"
                                                height="27"
                                            />
                                        </ContentLoader>
                                    )}
                                    {gross_optional_main_item_repurchase && (
                                        <strong>
                                            {
                                                gross_optional_main_item_repurchase
                                            }{" "}
                                            zł brutto
                                        </strong>
                                    )}
                                </PurchasePrice>
                            </PurchaseSummary>
                        </CollapseContent>
                    </Collapse>
                </CollapseContainer>
            )}
        </Root>
    );
};

export default Calculator;

import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";
import NavButton from "../../../components/Buttons/NavButton";
import Tile from "../../../components/Icons/components/Tile";
import { Close } from "../../../components/Icons/Icons.stories";
import Checkbox from "../../../components/Inputs/Checkbox";
import FileInput from "../../../components/Inputs/FileInput";
import Loader from "../../../components/Loader/Loader";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { selectTransactionId } from "../../../features/transactionSlice";
import Step2Service from "../../../services/Step2Service";
import UploadService from "../../../services/UploadService";
import { toBase64 } from "../../../utils/functions";
// import InputDescription from "../components/InputDescription";
import RentInput from "../components/RentInput";
import RentLayout from "../components/RentLayout";
import RentSubtitle from "../components/RentSubtitle";
import Consumer from '../../../components/Icons/components/Consumer'
import Chair from '../../../components/Icons/components/Chair'
import Chart from '../../../components/Icons/components/Chart'
import FlexBox from "../../../components/FlexBox/FlexBox";
import { useHistory } from "react-router";
import { theme } from "../../../theme";

const SubtitleContainer = styled.div`
    margin-bottom: 28px;
    font-size: 1.75rem;
    text-align: center;
`;

const UploadContainer = styled.div`
    position: relative;
    padding: 34px 3.571429em 32px;
    margin: 0 -3.571429em 36px;
    // background-color: #f7f7f7;
`;
const UploadDescription = styled.div`
    display: flex;
    // background-color: #f7f7f7;
    strong {
        padding-right: 42px;
    }
`;
const ButtonContainer = styled.div`
    margin: 28px 0 34px;
`;
const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Box = styled.div`
    background-color: ${({ theme }) => theme.white};
    line-height: 18px;
    border: 1px solid ${({ theme }) => theme.primary};
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    padding: 0 35px;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    margin: 25px 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    strong {
        display: block;
        margin-top: 1em;
        color: #c71b1b;
    }
`;
const CloseContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 14px;
    color: ${({ theme }) => theme.primary};
    font-size: 20px;
    cursor: pointer;
`;

const InputGroup = styled.div`
    font-size: 1.125rem;
    font-weight: bold;
    dislay: flex;
    flex: 1;
`;

const IconLabel = styled.label`
    display: inline-block;
    cursor: pointer;
    & > input {
        position: absolute;
        display: block;
        width: 0px;
        height: 0px;
        opacity: 0;
        top: -1000px;
        left: -1000px;
    }
`;

const RedStrongText = styled.p`
    font-weight: bold;
    color: #c71b1b;
    margin-top: 1rem;
`;

const DESCRIPTIONS = {
    EMPLOYMENT:
        // "Zaświadczenie o zatrudnieniu lub wyciąg z konta z poprzedniego miesiąca potwierdzający wypłatę wynagrodzenia.",
        "Prosimy o wgranie zaświadczenia o zatrudnieniu lub wyciąg z konta potwierdzający wypłatę wynagrodzenia za ostatnie 3 miesiące.",
    DIVIDENTS:
        // "Zaświadczenie o zatrudnieniu lub wyciąg z konta z poprzedniego miesiąca potwierdzający wypłatę wynagrodzenia.",
        "Prosimy o wgranie dokumentu potwierdzającego wypłatę wynagrodzenia.",
    RETIREMENT:
        // "Zaświadczenie o pobieraniu świadczeń w postaci renty bądź emerytury lub wyciąg z konta z poprzedniego miesiąca potwierdzający wypłatę świadczeń..",
        "Prosimy o wgranie zaświadczenia o pobieraniu świadczeń lub wyciąg z konta potwierdzający wypłatę świadczeń za ostatnie 3 miesiące.",
};
const DESCRIPTIONS_TOOLTIP = {
    EMPLOYMENT: `Dokumenty są wymagane do zweryfikowania Twojej przyszłej obsługi rat najmu. Możesz dosłać je na dalszym etapie procesowania wniosku, lecz czas oczekiwania na dokument wpłynie na czas zatwierdzania wniosku o najem. Zaświadczenie o zatrudnieniu musi zawierać podstawowe dane osobowe Wnioskodawcy oraz informacje o jego miesięcznych dochodach. Wyciąg z konta musi zawierać podstawowe dane osobowe Wnioskodawcy oraz opis transakcji pozwalający na stwierdzenie, iż chodzi o miesięczne wynagrodzenie Wnioskodawcy.`,
    DIVIDENTS: `Dokumenty są wymagane do zweryfikowania Twojej przyszłej obsługi rat najmu. Możesz dosłać je na dalszym etapie procesowania wniosku, lecz czas oczekiwania na dokument wpłynie na czas zatwierdzania wniosku o najem. Wyciąg z konta musi zawierać dane osobowe Wnioskodawcy oraz opis transakcji pozwalający na stwierdzenie, iż chodzi o wypłatę dywidendy, bądź innej formy wynagrodzenia z tytułu udziału w Spółce Kapitałowej na rzecz Wnioskodawcy.`,
    RETIREMENT: `Dokumenty są wymagane do zweryfikowania Twojej przyszłej obsługi rat najmu. Możesz dosłać je na dalszym etapie procesowania wniosku, lecz czas oczekiwania na dokument wpłynie na czas zatwierdzania wniosku o najem. Prześlij standardowe zaświadczenie o emeryturze lub rencie lub PIT 11A za ostatni rok lub wyciąg z konta potwierdzający wypłatę renty albo emerytury. Wyciąg z konta musi zawierać dane osobowe Wnioskodawcy oraz opis transakcji pozwalający na stwierdzenie, iż chodzi o wypłatę miesięcznej emerytury lub renty Wnioskodawcy.`
};

const schema = yup.object().shape({
    incomeType: yup
        .string()
        .oneOf(["EMPLOYMENT", "RETIREMENT", "DIVIDENTS"])
        .required(),
    later: yup.boolean(),
    employerNip: yup
        .string()
        .when("incomeType", {
            is: "EMPLOYMENT",
            then: yup.string().min(10).max(10).required(),
        })
        .when("incomeType", {
            is: "RETIREMENT",
            then: yup.string(),
        }),
    companyNip: yup.string().when("incomeType", {
        is: "DIVIDENTS",
        then: yup.string().min(10).max(10).required(),
    }),
});
const FinancialDataNaturalPerson = ({ navProps }) => {

    const history = useHistory();
    const [showModal, setModal] = useState();
    const {
        register,
        handleSubmit,
        control,
        watch,
        setError,
        formState: { isValid },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {},
    });
    const transactionId = useSelector(selectTransactionId);

    const employerNip = watch("employerNip");
    const companyNip = watch("companyNip");
    const incomeType = watch("incomeType");

    const {
        mutateAsync: saveContact,
        isLoading: isContractLoading,
    } = useMutation(Step2Service.saveContractOfEmployment(transactionId));

    const { mutateAsync: saveRent, isLoading: isRentLoading } = useMutation(
        Step2Service.savePensionOrRent(transactionId),
        {
            onError: (e) => {
                e.response.data.errors.forEach(
                    ({ message, invalid_property }) => {
                        setError(invalid_property, {
                            type: "manual",
                            message,
                        });
                    }
                );
                toast.error("Wpisano błędne dane");
            },
        }
    );
    const {
        mutateAsync: saveDividents,
        isLoading: isDividentsLoading,
    } = useMutation(Step2Service.saveDividends(transactionId));
    const { mutateAsync: saveFile, isLoading: isFileLoading } = useMutation(
        UploadService.uploadStatementNaturalPerson(transactionId)
    );

    const {
        mutateAsync: checkNipMutation,
        data: employerNipData,
        isLoading: isEmployerNipLoading,
        isError: isEmployerNipError,
    } = useMutation(Step2Service.checkNip(employerNip));

    const {
        mutateAsync: checkCompanyNipMutation,
        data: companyNipData,
        isLoading: isCompanyLoading,
        isError: isCompanyError,
    } = useMutation(Step2Service.checkNip(companyNip));

    useEffect(() => {
        if (employerNip?.length === 10) {
            checkNipMutation();
        }
    }, [checkNipMutation, employerNip]);

    useEffect(() => {
        if (companyNip?.length === 10) {
            checkCompanyNipMutation();
        }
    }, [checkCompanyNipMutation, companyNip]);

    const uploadFileLater = watch("later");
    const files = watch("file");

    const onSubmit = async (values) => {
        const acceptedFiles = values.file || [];

        if (!uploadFileLater && acceptedFiles.length > 0) {
            const base64 = await toBase64(acceptedFiles[0]);
            const filename = acceptedFiles[0].name;
            await saveFile({ filename, base64: base64.split("base64,")[1] });
        }

        if (values.incomeType === "EMPLOYMENT") {
            await saveContact({
                c22: employerNip,
            });
        } else if (values.incomeType === "RETIREMENT") {
            await saveRent();
        } else if (values.incomeType === "DIVIDENTS") {
            await saveDividents({
                c22: companyNip,
            });
        }
        // mutateAsync();
        history.push(navProps.nextStep);
        // showNextStep()
    };

    const showLoader =
        isContractLoading ||
        isRentLoading ||
        isDividentsLoading ||
        isFileLoading;

    const [income, setIncome] = useState()
    const handleClick = (e) => {
        setIncome(e.target.value)
    }

    const formTypeStore = useSelector(state => state.transaction.form)

    const [step, setStep] = useState(0)
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <RentLayout {...navProps} showNav={false} showLoader={showLoader}>
                <SubtitleContainer>
                    {step === 0 && <RentSubtitle>Główne źródło dochodu wnioskodawcy</RentSubtitle>}
                    {step === 1 && <RentSubtitle>Potwierdzenie dochodów</RentSubtitle>}
                </SubtitleContainer>
                {
                    step === 0 && (
                        <>
                            <FlexBox justifyContent='space-between' flex="1" margin="0 0 2rem 0">
                                <InputGroup>
                                    <IconLabel htmlFor="EMPLOYMENT">
                                        <Tile
                                            icon={<Consumer color={theme.primary} />}
                                            active={income === 'EMPLOYMENT'}
                                        />
                                        <input
                                            type='radio'
                                            id='EMPLOYMENT'
                                            value='EMPLOYMENT'
                                            {...register("incomeType")}
                                            onClick={handleClick}
                                        />
                                    </IconLabel>
                                    <p>Zatrudnienie</p>
                                </InputGroup>
                                <InputGroup>
                                    <IconLabel htmlFor="RETIREMENT"  >
                                        <Tile
                                            icon={<Chair color={theme.primary} />}
                                            active={income === 'RETIREMENT'}
                                        />
                                        <input
                                            type='radio'
                                            id='RETIREMENT'
                                            value='RETIREMENT'
                                            {...register("incomeType")}
                                            onClick={handleClick}
                                        />
                                    </IconLabel>
                                    <p>Renta lub emerytura</p>
                                </InputGroup>
                                <InputGroup>
                                    <IconLabel htmlFor="DIVIDENTS">
                                        <Tile
                                            icon={<Chart color={theme.primary} />}
                                            active={income === 'DIVIDENTS'}
                                        />
                                        <input
                                            type='radio'
                                            id='DIVIDENTS'
                                            value='DIVIDENTS'
                                            {...register("incomeType")}
                                            onClick={handleClick}
                                        />
                                    </IconLabel>
                                    <p>Dywidendy lub inne dochody osobiste od Firm</p>
                                </InputGroup>
                            </FlexBox>
                            <div>
                                {
                                    income === 'EMPLOYMENT' && (
                                        <>
                                            <RentInput
                                                label="NIP Pracodawcy"
                                                name="employerNip"
                                                register={register}
                                            />
                                            {isEmployerNipLoading && (
                                                <Loader />
                                            )}
                                            {isEmployerNipError && (
                                                <div>
                                                    Nieprawidłowy nip
                                                </div>
                                            )}
                                            {employerNipData?.c20 && (
                                                <RentInput
                                                    label="Nazwa Pracodawcy"
                                                    name="employerName"
                                                    value={
                                                        employerNipData?.c20
                                                    }
                                                    disabled
                                                />
                                            )}
                                        </>
                                    )
                                }
                                {
                                    income === 'DIVIDENTS' && (
                                        <>
                                            <RentInput
                                                label="NIP Spółki wypłacającej dywidendy"
                                                name="companyNip"
                                                register={register}
                                            />
                                            {isCompanyLoading && (
                                                <Loader />
                                            )}
                                            {isCompanyError && (
                                                <div>
                                                    Nieprawidłowy nip
                                                </div>
                                            )}
                                            {companyNipData?.c20 && (
                                                <RentInput
                                                    label="Nazwa Spółki"
                                                    name="companyNip"
                                                    value={
                                                        companyNipData?.c20
                                                    }
                                                    disabled
                                                />
                                            )}
                                        </>
                                    )
                                }
                            </div>
                        </>
                    )
                }
                {
                    step === 1 && (
                        <>
                            {incomeType && (
                                <UploadContainer>
                                    {showModal && (
                                        <Box>
                                            <CloseContainer
                                                onClick={() => setModal(false)}
                                            >
                                                <Close />
                                            </CloseContainer>
                                            {`Dokumenty możesz wysłać nam później, jednak są one niezbędne do procesu weryfikacji Twojej zdolności finansowej.\n`}

                                            <strong>
                                                Czas oczekiwania na dokument wpłynie na
                                                czas zatwierdzenia wniosku o najem.
                                            </strong>
                                        </Box>
                                    )}
                                    <UploadDescription>
                                        <strong>{DESCRIPTIONS[incomeType]}</strong>
                                        <div>
                                            <Tooltip
                                                description={
                                                    DESCRIPTIONS_TOOLTIP[incomeType]
                                                }
                                            />
                                        </div>
                                    </UploadDescription>
                                    <ButtonContainer>
                                        <Controller
                                            name="file"
                                            control={control}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <FileInput
                                                    value={value}
                                                    disabled={!!uploadFileLater}
                                                    onChange={(e) =>
                                                        onChange(e.target.files)
                                                    }
                                                />
                                            )}
                                        />
                                    </ButtonContainer>
                                    <CheckboxContainer>
                                        <Controller
                                            name="later"
                                            control={control}
                                            defaultValue={false}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <Checkbox
                                                    label="Doślę dokumenty w późniejszym terminie"
                                                    value={value}
                                                    onChange={(e) => onChange(e)}
                                                ></Checkbox>
                                            )}
                                        />
                                        <Tooltip
                                            description={
                                                <div>
                                                    <p>
                                                        Dokumenty możesz wysłać nam później, jednak są one niezbędne do procesu weryfikacji Twojej zdolności finansowej.
                                                    </p>
                                                    <RedStrongText>
                                                        Czas oczekiwania na dokument wpłynie na czas zatwierdzenia wniosku o najem.
                                                    </RedStrongText>

                                                </div>
                                            }
                                        />
                                        {/* <InputDescription
                                            onQuestionMarkClick={() => setModal(true)}
                                        /> */}
                                    </CheckboxContainer>
                                </UploadContainer>
                            )}
                        </>
                    )
                }

                <NavButton
                    submit
                    disabled={
                        showLoader ||
                        !isValid ||
                        !!isEmployerNipError ||
                        !!isCompanyError ||
                        !!isEmployerNipLoading ||
                        !!isCompanyLoading
                        || (step === 1 && (!uploadFileLater && (!files || files?.length === 0)))
                    }
                    backLink={step === 0 && '/rent/contract-data'}
                    onBackClicked={() =>
                        setStep((step) => Math.max(step - 1, 0))
                    }
                    hideBackButton={formTypeStore === 'mObywatel'}
                />
            </RentLayout>
        </form>
    );
};

export default FinancialDataNaturalPerson;

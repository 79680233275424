import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Buttons/Button";
import Container from "../../components/Container/Container";
import BackArrowIcon from "../../components/Icons/components/BackArrowIcon";
// import NextArrowIcon from "../../components/Icons/components/NextArrowIcon";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import LoadMore from "../../components/LoadMore/LoadMore";
import ProductBox from "../../components/ProductBox/ProductBox";
import SearchBar from "../../components/SearchBar/SearchBar";
import SearchNotFound from "../../components/SearchNotFound/SearchNotFound";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import CompanyToggle from "../../connectedComponents/CompanyToggle/CompanyToggle";
import {
    selectIsCompany,
    selectMinimalMonthsParsed,
    selectShopUuid,
} from "../../features/basketSlice";
import ProductService from "../../services/ProductsService";
import ProductBreadcrump from "./components/ProductBreadcrump";

const CategoryBoxContainer = styled.div`
    margin-bottom: 10px;
`;
const Footer = styled.div`
    margin-top: 32px;
    margin-bottom: 35px;
    display: flex;
    gap: 10px;
    a,
    button {
        flex: 1;
    }
`;
const LoadMoreContainer = styled.div`
    text-align: center;
    margin-top: 20px;
`;
const CompanyRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 32px 0 40px;
    & > div:first-child {
        margin-right: 20px;
    }
`;

const ScrollBox = styled.div`
    max-height: 790px;
    overflow-y: auto;
`

const ProductsCategories = () => {
    const shop_uuid = useSelector(selectShopUuid);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany);
    const [paginationCount, setPaginationCount] = useState(10);
    const [searchQuery, setQuery] = useState("");

    const { data: accessories } = useQuery(
        ["additional-products", "ACCESSORIES", shop_uuid, minimal_months],
        ProductService.getProductsByType(
            "ACCESSORIES",
            shop_uuid,
            minimal_months
        ),
        {
            select: ({ items }) => items,
        }
    );
    const filterProducts = useCallback(
        (product) => {
            if (searchQuery.length === 0) {
                return true;
            }
            return product.details.name
                .toLowerCase()
                .includes(searchQuery.toLowerCase());
        },
        [searchQuery]
    );
    useEffect(() => {
        setPaginationCount(10);
    }, [searchQuery]);

    const getInstallment = (product) => {
        if (isCompany) {
            return (
                product?.rental_options?.company_option?.option_formatted
                    ?.gross_month_cost || "0zł"
            );
        }
        return (
            product?.rental_options?.natural_person_option?.option_formatted
                ?.gross_month_cost || "0zł"
        );
    };

    const parsedAccessories = useMemo(() => {
        if (!accessories) return null;
        // return accessories.filter(filterProducts).slice(0, paginationCount);
        return accessories.filter(filterProducts)
        // eslint-disable-next-line
    }, [accessories, filterProducts, paginationCount]);

    return (
        <Layout>
            <SearchBar onSubmit={setQuery} />

            <Container>
                <ProductBreadcrump
                    list={
                        [
                            // {
                            //     label: "Rekomendowane",
                            //     link: "/products-collections",
                            // },
                            // {
                            //     label: "Kolekcja",
                            //     link: "/products-collections",
                            // },
                            // {
                            //     label: "Wzmacniacze",
                            //     link: "/products-collections/wzmacniacze",
                            // },
                        ]
                    }
                />
                <SectionHeader marginBottom={30}>
                    Kolekcja akcesoriów i produktów dodatkowych
                </SectionHeader>

                <CompanyRow>
                    <div style={{ fontSize: "17px" }}>Najem na firmę</div>
                    <CompanyToggle />
                </CompanyRow>

                {!parsedAccessories && <Loader fixed />}
                {parsedAccessories?.length === 0 ? (
                    <SearchNotFound />
                ) : (
                    <ScrollBox>
                        {
                            parsedAccessories
                                ?.filter(filterProducts)
                                // .slice(0, paginationCount)
                                .map((product) => (
                                    <CategoryBoxContainer key={product.details.id}>
                                        <ProductBox
                                            hidePrice={true}
                                            name={product.details.name}
                                            price={product.details.formattedGrossPrice}
                                            availabilitySymbol={
                                                product.details.availabilitySymbol
                                            }
                                            availability={
                                                product.details.availabilityText
                                            }
                                            status={product.conditionText}
                                            quantity={null}
                                            url={`/products-collections/accesories/${product.details.id}`}
                                            imageSrc={product.details.image_url}
                                            installment={getInstallment(product)}
                                        />
                                    </CategoryBoxContainer>
                                ))
                        }
                    </ScrollBox>
                )}

                {
                    // TODO: dont display if no more product
                    // paginationCount < parsedAccessories?.filter(filterProducts).length && (
                    false && (
                        <LoadMoreContainer>
                            <LoadMore
                                onClick={() =>
                                    setPaginationCount((val) => val + 10)
                                }
                            />
                        </LoadMoreContainer>
                    )
                }

                <Footer>
                    <Link to="/basket">
                        <Button LeftIcon={<BackArrowIcon />}>Wstecz</Button>
                    </Link>

                    {/* <Button disabled RightIcon={<NextArrowIcon />}>
                        Dalej
                    </Button> */}
                </Footer>
            </Container>
        </Layout>
    );
};

export default ProductsCategories;

// import { Link } from "react-router-dom";
// import { useHistory } from "react-router";
import styled from "styled-components";
import CollapseIcon from "../../components/Collapse/CollapseIcon";
import Container from "../../components/Container/Container";
import Layout from "../../components/Layout/Layout";
import { useSelector } from "react-redux";
import { selectMobile } from "../../features/appSlice";
// import FacebookIcon from "../../components/Icons/socialsIcons/FacebookIcon";
// import LinkedinIcon from "../../components/Icons/socialsIcons/LinkedinIcon";
// import CloseIcon from "../../components/Icons/components/CloseIcon";

// const CloseContainer = styled.div`
//     color: ${({ theme }) => theme.primary};
//     margin-bottom: 18px;
//     text-align: right;
//     div {
//         cursor: pointer;
//         transition: all 0.4s;
//         &:hover {
//             opacity: 0.6;
//         }
//     }
// `;
const HowItWorksContainer = styled.div`
    padding: 18px 25px 45px;
    background: ${({ theme }) => theme.white};
`;
const Title = styled.div`
    font-size: 1.4285714285714286em;
    line-height: 26px;
    margin-bottom: 30px;
`;
const Row = styled.div`
    border-bottom: 1px solid #f7f7f7;
    padding: 4px 0;
`;
// const LinksContainer = styled.div`
//     margin: ${(props) => (props.isMobile ? "30px 25px 0" : "0")};
//     a {
//         margin-bottom: 20px;
//         display: block;
//     }
// `;
const VideoContainer = styled.div`
    margin: ${(props) => (props.isMobile ? "40px -50px 40px;" : "0 0 0 10px;")}
        ${(props) => !props.isMobile && "flex: 1;"} iframe {
        width: 100%;
    }
`;
// const Footer = styled.footer`
//     ul {
//         list-style: none;
//         margin-top: 30px;
//         line-height: 22px;
//     }
//     a {
//         text-decoration: underline;
//     }
// `;

const FlexDiv = styled.div`
    display: flex;
    align-items: flex-start;
`;

// const ExtraInfo = styled.div`
//     display: flex;
//     justify-content: space-between;
// `;

// const MiniBox = styled.div`
//     text-align: center;
//     p:first-child {
//         margin-bottom: 20px;
//     }
// `;

const HowItWorks = () => {
    // const history = useHistory();
    const isMobile = useSelector(selectMobile);

    return (
        <Layout desktopTitle="Jak to działa">
            <HowItWorksContainer>
                <Container>
                    {/* <CloseContainer>
                        <div onClick={() => history.goBack()}>
                            <CloseIcon width={30} height={30} />
                        </div>
                    </CloseContainer> */}
                    <Title>
                        <strong>
                            Zasady najmu z minimalnym okresem trwania i
                            możliwością wykupu sprzętu:
                        </strong>
                    </Title>
                    <FlexDiv isMobile={isMobile}>
                        <div style={{ flex: "1" }}>
                            <Row>
                                <CollapseIcon
                                    label="Komu wynajmujemy?"
                                    description={
                                        <ul className="numeric">
                                            <li>
                                                Wynajmujemy zarówno osobom
                                                fizycznym, jednoosobowym
                                                działalnościom gospodarczym,
                                                firmom, instytucjom, szkołom,
                                                etc.
                                            </li>
                                            <li>
                                                W przypadku osób fizycznych
                                                wynajmujemy osobom pełnoletnim,
                                                mającym stałe źródło dochodu i
                                                zameldowanym w Polsce.
                                            </li>
                                        </ul>
                                    }
                                />
                            </Row>
                            <Row>
                                <CollapseIcon
                                    label="Jak wynająć?"
                                    description={
                                        <ul className="numeric">
                                            <li>
                                                Jeśli jesteś osobą fizyczną –
                                                konsumentem, do wypełnienia
                                                umowy będziesz potrzebować:
                                                <ul>
                                                    <li>
                                                        numer NIP (a jeśli nie
                                                        posiadasz NIP, to numer
                                                        PESEL)
                                                    </li>
                                                    <li>
                                                        numer dowodu osobistego
                                                    </li>
                                                    <li>
                                                        numer PESEL (także jeśli
                                                        podałeś NIP)
                                                    </li>
                                                    <li>
                                                        numer NIP Twojego
                                                        Pracodawcy
                                                    </li>
                                                    <li>
                                                        zaświadczenie o
                                                        zatrudnieniu lub
                                                        potwierdzenie wpłaty
                                                        wynagrodzenia na Twoje
                                                        konto bankowe
                                                    </li>
                                                    <li>
                                                        kartę płatniczą, aby
                                                        uruchomić umowę i
                                                        cykliczne płatności rat
                                                        najmu
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                Jeśli jesteś Przedsiębiorcą albo
                                                reprezentujesz Firmę lub
                                                Instytucję, do wypełnienia umowy
                                                najmu będziesz potrzebować:
                                                <ul>
                                                    <li>
                                                        Twój numer NIP (lub
                                                        PESEL)
                                                    </li>
                                                    <li>
                                                        numer dowodu osobistego
                                                    </li>
                                                    <li>
                                                        numer PESEL (także jeśli
                                                        podałeś NIP)
                                                    </li>
                                                    <li>
                                                        numer NIP
                                                        reprezentowanej przez
                                                        Ciebie firmy lub
                                                        instytucji
                                                    </li>
                                                    <li>
                                                        kartę płatniczą, aby
                                                        uruchomić umowę i
                                                        cykliczne płatności rat
                                                        najmu
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                Po wypełnieniu danych do umowy
                                                zatwierdzasz zgody na
                                                przetwarzanie Twoich danych i
                                                wysyłasz wniosek o wynajem
                                            </li>
                                            <li>
                                                W dni robocze analizujemy Twój
                                                wniosek “od ręki”, w dni wolne
                                                od pracy najprawdopodobniej
                                                będziesz musiał poczekać dłużej,
                                                może nawet do pierwszego
                                                kolejnego dnia roboczego, ale na
                                                ogół działamy 24h na dobę przez
                                                7 dni w tygodniu. Pamiętaj
                                                jednak, że czasem musimy
                                                potwierdzić dostępność wybranego
                                                przez Ciebie instrumentu z
                                                naszym Sklepem Partnerskim.
                                            </li>
                                            <li>
                                                Po akceptacji Twojego wniosku
                                                otrzymujesz na Twój adres
                                                mailowy potwierdzenie wraz z
                                                linkiem do zawarcia umowy
                                            </li>
                                            <li>
                                                Zatwierdzasz warunki umowy podając kod SMS wysłany na Twój numer telefonu
                                            </li>
                                            <li>
                                                System przekierowuje Cię na
                                                stronę bezpiecznych płatności,
                                                gdzie podajesz dane swojej karty
                                                płatniczej. W momencie
                                                zawierania transakcji system
                                                pobiera pierwszą ratę wraz z
                                                kaucją zwrotną określoną w
                                                umowie
                                            </li>
                                            <li>
                                                Otrzymujesz potwierdzenie
                                                zawarcia umowy najmu i odbierasz
                                                sprzęt z wybranego sklepu
                                                partnerskiego 🙂
                                            </li>
                                        </ul>
                                    }
                                />
                            </Row>
                            <Row>
                                <CollapseIcon
                                    label="Okres najmu"
                                    description={
                                        <ul className="numeric">
                                            <li>
                                                Wybierz pakiet cenowy
                                                uzależniony od minimalnego okres
                                                najmu.
                                            </li>
                                            <li>
                                                Po upływie minimalnego okresu
                                                kontynuuj wynajem lub zwróć
                                                wypożyczony sprzęt.
                                            </li>
                                            <li>
                                                Możesz wynajmować instrument tak
                                                długo jak chcesz, a po
                                                określonym czasie – ustalanym
                                                odrębnie dla każdego instrumentu
                                                i w zależności od wybranego
                                                minimalnego okresu najmu –
                                                możesz go wykupić za złotówkę
                                            </li>
                                            <li>
                                                Jeśli po upływie wybranego
                                                okresu najmu chcesz zakończyć
                                                wypożyczanie, po prostu zwróć
                                                wynajęty sprzęt.
                                            </li>
                                            <li>
                                                Gdy chcesz zakończyć umowę w
                                                trakcie trwania minimalnego
                                                okresu najmu, zwracasz
                                                wypożyczony sprzęt i dokonujesz
                                                zapłaty rat pozostałych do
                                                zakończenia wybranego okresu
                                                najmu.
                                            </li>
                                        </ul>
                                    }
                                />
                            </Row>
                            <Row>
                                <CollapseIcon
                                    label="Płatności"
                                    description={
                                        <ul className="numeric">
                                            <li>
                                                Płacisz tylko miesięczne raty
                                                oraz jednorazową kaucję zwrotną.
                                                Nie pobieramy przy zawieraniu
                                                umowy najmu żadnych dodatkowych
                                                opłat, ani ukrytych prowizji.
                                            </li>
                                            <li>
                                                Płatności dokonujesz kartą
                                                płatniczą.
                                            </li>
                                            <li>
                                                Pierwszą ratę wraz z kaucją
                                                wpłacasz przy zawieraniu nowy
                                                najmu online. Przygotuj w tym
                                                celu swoją kartę płatniczą.
                                            </li>
                                            <li>
                                                Kolejne raty naliczane są
                                                począwszy od kolejnego miesiąca
                                                po odebraniu przez Ciebie
                                                sprzętu. Raty najmu pobierane są
                                                automatycznie z Twojej karty w
                                                trakcie trwania umowy najmu.
                                            </li>
                                            <li>
                                                Kaucja zwrotna służy m.in.
                                                zabezpieczeniu ew. uszkodzeń i
                                                jest zwracana po zakończeniu i
                                                pełnym rozliczeniu umowy najmu.
                                            </li>
                                        </ul>
                                    }
                                />
                            </Row>
                            <Row>
                                <CollapseIcon
                                    label="Odbiór sprzętu"
                                    description={
                                        <ul className="numeric">
                                            <li>
                                                Odbierasz instrument w sklepie
                                                muzycznym, w którym dostępny
                                                jest wybrany przez Ciebie
                                                instrument. Miej ze sobą
                                                telefon, którego numer podałeś w
                                                trakcie wypełniania danych do
                                                umowy najmu oraz swój dowód
                                                osobisty lub inny dokument
                                                zawierający Twój numer PESEL
                                                oraz Twoje zdjęcie.
                                            </li>
                                            <li>
                                                Dane teleadresowe sklepu
                                                partnerskiego znajdziesz w
                                                formularzu najmu, w umowie
                                                najmu, a także na naszych
                                                stronach www
                                            </li>
                                            <li>
                                                Oferujemy także wysyłkę
                                                kurierską na Twój adres. Wówczas
                                                przygotuj dokument tożsamości
                                                podany w trakcie wypełniania
                                                danych do umowy najmu. Kurier
                                                poprosi Cię o podpisanie
                                                Protokołu Wydania Sprzętu.
                                            </li>
                                        </ul>
                                    }
                                />
                            </Row>
                            <Row>
                                <CollapseIcon
                                    label="Opcja wykupu"
                                    description={
                                        <ul className="numeric">
                                            <li>
                                                W każdej chwili po rozpoczęciu
                                                umowy najmu możesz wykupić
                                                wynajmowany sprzęt.
                                            </li>
                                            <li>
                                                Cena wykupu zmniejsza się z
                                                upływem każdego kolejnego
                                                miesiąca wypożyczania.
                                            </li>
                                            <li>
                                                Wykup produktu nie jest
                                                obligatoryjny, możesz wykupić,
                                                ale nie musisz.
                                            </li>
                                            <li>
                                                Jeśli w trakcie najmu dojdziesz
                                                do wniosku, że chcesz wykupić
                                                swój sprzęt, sprawdź wartości w
                                                tabeli cen wykupu załączonej do
                                                umowy najmu.
                                            </li>
                                            <li>
                                                Cenę wykupu możesz sprawdzić na
                                                karcie każdego produktu,
                                                przesuwając kółko suwaka „Opcja
                                                wykupu”
                                            </li>
                                            <li>
                                                Możesz także skontaktować się z
                                                nami, a my przedstawimy Ci
                                                aktualną cenę wykupu.
                                            </li>
                                        </ul>
                                    }
                                />
                            </Row>
                            <Row>
                                <CollapseIcon
                                    label="Zwrot po wynajmie"
                                    description={
                                        <ul className="numeric">
                                            <li>
                                                Zwracasz instrument do sklepu, z
                                                którego go odbierałeś (aś). W
                                                szczególnych przypadkach możemy
                                                wyrazić zgodę na wysyłkę
                                                kurierską, jednak poprosimy Cię
                                                wcześniej o wypełnienie
                                                formularza zwrotu. Jeśli chcesz
                                                odesłać instrument, skontaktuj
                                                się z nami telefonicznie.
                                            </li>
                                            <li>
                                                Nie obawiaj się dodatkowych
                                                kosztów. Jeśli instrument jest w
                                                stanie wynikającym ze zwykłego
                                                użytkowania, pomimo widocznych
                                                śladów użycia nie naliczamy
                                                żadnych dodatkowych opłat.
                                                Szczegóły znajdziesz w
                                                załączniku do Ogólnych Warunków
                                                Najmu.
                                            </li>
                                        </ul>
                                    }
                                />
                            </Row>
                        </div>
                        {!isMobile && (
                            <VideoContainer isMobile={isMobile}>
                                <iframe
                                    width="560"
                                    height="343"
                                    src="https://www.youtube.com/embed/7Dd2n7r9J2Y"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </VideoContainer>
                        )}
                    </FlexDiv>
                    {/* {!isMobile && (
                        <>
                            <Title
                                isMobile={isMobile}
                                style={{ marginTop: "60px" }}
                            >
                                <strong>Masz więcej pytań?</strong>
                            </Title>
                            <ExtraInfo>
                                <LinksContainer>
                                    <Link
                                        to="//vibe.pl/wp-content/uploads/OWN-G4R.pdf"
                                        target="_blank"
                                    >
                                        Ogólne warunki najmu
                                    </Link>
                                    <Link
                                        to="//vibe.pl/wp-content/uploads/Polityka-Prywatnosci-UniVibe.pdf"
                                        target="_blank"
                                    >
                                        Polityka prywatności
                                    </Link>
                                </LinksContainer>
                                <MiniBox>
                                    <p>Univibe Sp. z o.o.</p>
                                    <p>Sarmacka 1, 02-972 Warszawa</p>
                                </MiniBox>
                                <MiniBox>
                                    <p>NIP: 9512505831</p>
                                    <p>KRS: 0000853684</p>
                                </MiniBox>
                                <MiniBox>
                                    <p>Tel. 510 510 092</p>
                                    <p>Email: info@vibe.pl</p>
                                </MiniBox>
                                <MiniBox>
                                    <p>Znajdź nas</p>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Link to="#">
                                            <FacebookIcon fontSize="30px" />
                                        </Link>
                                        <Link to="#">
                                            <LinkedinIcon fontSize="30px" />
                                        </Link>
                                    </div>
                                </MiniBox>
                            </ExtraInfo>
                        </>
                    )} */}

                    {isMobile && (
                        <VideoContainer isMobile={isMobile}>
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/7Dd2n7r9J2Y"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </VideoContainer>
                    )}

                    {/* {isMobile && (
                        <Footer>
                            <Title>
                                <strong>Masz więcej pytań?</strong>
                            </Title>
                            <ul>
                                <li>Univibe Sp. z o.o.</li>
                                <li>Sarmacka 1, 02-972 Warszawa</li>
                                <li>NIP: 9512505831</li>
                                <li>KRS: 0000853684</li>
                                <li>Tel. 510 510 092</li>
                                <li>
                                    Email:{" "}
                                    <a href="mailto:info@vibe.pl">
                                        info@vibe.pl
                                    </a>
                                </li>
                            </ul>
                        </Footer>
                    )} */}
                </Container>
            </HowItWorksContainer>
        </Layout>
    );
};

export default HowItWorks;

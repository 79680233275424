import AditionalProducts from "./views/AdditionalProducts/AdditionalProducts";
import Product from "./views/AddProduct/Product";
import ProductsCategories from "./views/AddProduct/ProductsCategories";
import ProductsCollection from "./views/AddProduct/ProductsCollection";
import AddService from "./views/AddService/AddService";
import Service from "./views/AddService/Service";
import BasketView from "./views/BasketView/BasketView";
import HowItWorks from "./views/HowItWorks/HowItWorks";
import Home from "./views/Home/Home";
import RentSteps from "./views/RentSteps/RentSteps";
import Location from "./views/Location/Location";
import Finished from "./views/Finished/Finished";
import FinishedRecurring from "./views/Finished/FinishedRecurring";
import ProductLoader from "./views/ProductLoader/ProductLoader";
import ProductLoaderShare from "./views/ProductLoader/ProductLoaderShare";
import ConfirmEmail from "./views/ConfirmEmail/ConfirmEmail";
import LoginAccepted from "./views/LoginAccepted/LoginAccepted";
import MainProductRoute from "./MainProductRoute";
import MissingData from "./views/MissingData/MissingData";
import EmailSent from "./views/EmailSent/EmailSent";
import UploadAgreement from "./views/UploadAgreement/UploadAgreement";
import OnlinePaymentAgain from "./views/RentSteps/OnlinePayment/OnlinePaymentAgain";
import ConfirmPaymentAgainLink from "./views/RentSteps/OnlinePayment/ConfirmPaymentAgainLink";
import ConfirmDocumentsNP from "./views/ConfirmDocuments/ConfirmDocumentsNP";
import ConfirmDocumentsLP from "./views/ConfirmDocuments/ConfirmDocumentsLP";
import ConfirmDocumentsIE from "./views/ConfirmDocuments/ConfirmDocumentsIE";
import LinkInactive from "./views/LinkInactive/LinkInactive";
import UploadConfirmation from "./views/UploadConfirmation/UploadConfirmation";
import CategoryTypesList from "./views/Categories/CategoryTypesList";
import CategoryProductsList from "./views/Categories/CategoryProductsList";
import Maintenance from "./views/Maintenance/Maintenance";
import SkipForm from "./views/SkipForm/SkipForm";

export const Routes = [
    {
        path: "/data-error",
        Component: <MissingData />,
    },
    {
        path: "/email-sent",
        Component: <EmailSent />,
    },
    {
        path: "/how-it-works",
        Component: <HowItWorks />,
    },
    {
        path: "/additional",
        Component: (
            <MainProductRoute>
                <AditionalProducts />
            </MainProductRoute>
        ),
    },
    {
        path: "/basket",
        Component: (
            <MainProductRoute>
                <BasketView />
            </MainProductRoute>
        ),
    },
    {
        path: "/product/:product_code+/shop/:shop_uuid/",
        exact: true,
        Component: <ProductLoader />,
    },
    {
        path: "/product/:product_code+/shop/:shop_uuid/min_months/:min_months/:basket",
        exact: true,
        Component: <ProductLoader />,
    },
    {
        path: "/product/:product_code+/shop/:shop_uuid/min_months/:min_months/additionaProductId/:additionaProductId/servicesId/:servicesId/mainProductId/:mainProductId",
        exact: true,
        Component: <ProductLoaderShare />,
    },
    {
        path: "/",
        exact: true,
        Component: <MainProductRoute>
            <Home />
        </MainProductRoute>,
    },
    {
        path: "/rent/finished/:sesssion_id",
        label: "G",
        Component: <Finished />,
    },
    {
        path: "/umowa-zawarta/:session_id",
        label: "G",
        Component: <Finished />,
    },
    {
        path: "/platnosc-zrealizowana/:session_id",
        label: "G",
        Component: <FinishedRecurring />,
    },
    {
        path: "/rent",
        Component: <RentSteps />,
    },

    {
        path: "/products-collections/:category/:product",
        Component: (
            <MainProductRoute>
                <Product />
            </MainProductRoute>
        ),
    },
    {
        path: "/products-collections/:category",
        Component: (
            <MainProductRoute>
                <ProductsCategories />
            </MainProductRoute>
        ),
    },
    {
        path: "/products-collections",
        Component: (
            <MainProductRoute>
                <ProductsCollection />
            </MainProductRoute>
        ),
    },
    {
        path: "/services-collections/:type/:id",
        Component: (
            <MainProductRoute>
                <Service />
            </MainProductRoute>
        ),
    },
    {
        path: "/services-collections",
        Component: (
            <MainProductRoute>
                <AddService />
            </MainProductRoute>
        ),
    },
    {
        path: "/select-place",
        Component: (
            <MainProductRoute>
                <Location />
            </MainProductRoute>
        ),
    },
    {
        path: "/confirm-email/:token1/:token2",
        Component: <ConfirmEmail />,
    },
    {
        path: "/zaswiadczenie-o-zarobkach/:token1/:token2",
        Component: <ConfirmDocumentsNP />,
    },
    {
        path: "/zaswiadczenie-jdg/:token1/:token2",
        Component: <ConfirmDocumentsIE />,
    },
    {
        path: "/zaswiadczenie-osoba-prawna/:token1/:token2",
        Component: <ConfirmDocumentsLP />,
    },
    {
        path: "/weryfikacja-danych/natural-person/:token1/:token2",
        Component: <LoginAccepted />,
    },
    {
        path: "/weryfikacja-danych/legal-person/:token1/:token2",
        Component: <LoginAccepted />,
    },
    {
        path: "/weryfikacja-danych/individual-entrepreneur/:token1/:token2",
        Component: <LoginAccepted />,
    },
    {
        path: "/application",
        Component: <RentSteps />,
    },
    {
        path: "/upload/:token1/:token2",
        Component: <UploadAgreement />,
    },
    {
        path: "/online-repayment/:schedule_id",
        Component: <OnlinePaymentAgain />,
    },
    {
        path: "/metoda-platnosci/ponowienie/:token1/:token2/:schedule_id",
        Component: <ConfirmPaymentAgainLink />,
    },
    {
        path: "/link-inactive",
        Component: <LinkInactive />,
    },
    {
        path: "/upload-confirmation",
        Component: <UploadConfirmation />,
    },
    {
        path: '/categories/:type',
        Component: <CategoryTypesList />,
        exact: true,
    },
    {
        path: '/categories/:type/:categoryId',
        Component: <CategoryProductsList />,
        exact: true,
    },
    {
        path: "/maintenance",
        Component: <Maintenance />,
    },
    {
        path: "/skip-form/:token1/:token2",
        Component: <SkipForm />,
    },
];

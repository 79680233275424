import { useEffect, useState } from "react";
import styled from "styled-components";
import { useDebounce } from "../../utils/functions";
import SearchIcon from "../Icons/components/SearchIcon";
import TextInput from "./TextInput";

const Container = styled.form`
    display: inline-flex;
    align-items: center;
    background: ${({ theme }) => theme.white};
    border-radius: 22px;
    padding-right: 15px;
`;
const Search = styled.button`
    cursor: pointer;
    appearance: none;
    background: transparent;
    border: none;
`;
const Input = styled(TextInput)`
    border: 1px solid transparent;
`;

const SearchInput = ({
    onSubmit = () => {
        console.error("Search not implemented");
    },
}) => {
    const [value, setValue] = useState("");
    const [handleChange] = useDebounce(() => onSubmit(value), 400);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(value);
    };
    useEffect(handleChange, [handleChange, value]);

    return (
        <Container onSubmit={handleSubmit}>
            <Input
                placeholder={"Wyszukaj..."}
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
            <Search type="submit">
                <SearchIcon width={20} height={20} />
            </Search>
        </Container>
    );
};

export default SearchInput;

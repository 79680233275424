import styled from "styled-components";

const ActiveIndicator = styled.path`
    transition: all 0.2s;
    opacity: ${({ active }) => (!!active ? 1 : 0)};
`;
const CheckboxIcon = ({ active = true, ...props }) => (
    <svg style={{minWidth: '20px'}}
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        {...props}
    >
        <g data-name="Group 841">
            <g data-name="&gt;-ico" fill="currentColor" fillRule="evenodd">
                <path
                    data-name="Path 130"
                    d="M10 1.538c-2.169 0-5.134.2-6.985.343a1.228 1.228 0 00-1.134 1.134C1.738 4.866 1.538 7.831 1.538 10s.2 5.134.343 6.985a1.228 1.228 0 001.134 1.134c1.851.144 4.816.343 6.985.343s5.134-.2 6.985-.343a1.228 1.228 0 001.134-1.134c.144-1.851.343-4.816.343-6.985s-.2-5.134-.343-6.985a1.228 1.228 0 00-1.134-1.134c-1.851-.143-4.816-.343-6.985-.343zM2.9.347C4.748.2 7.765 0 10 0s5.252.2 7.1.347A2.767 2.767 0 0119.653 2.9C19.8 4.748 20 7.765 20 10s-.2 5.252-.347 7.1a2.767 2.767 0 01-2.553 2.553C15.252 19.8 12.234 20 10 20s-5.252-.2-7.1-.347A2.767 2.767 0 01.347 17.1C.2 15.252 0 12.234 0 10s.2-5.252.347-7.1A2.767 2.767 0 012.9.347z"
                />
                <ActiveIndicator
                    active={active}
                    data-name="Path 927"
                    d="M6.448 5.173C7.374 5.1 8.882 5 10 5s2.626.1 3.552.173a1.383 1.383 0 011.274 1.275C14.9 7.374 15 8.882 15 10s-.1 2.626-.174 3.552a1.383 1.383 0 01-1.274 1.274C12.626 14.9 11.117 15 10 15s-2.626-.1-3.552-.174a1.383 1.383 0 01-1.275-1.274C5.1 12.626 5 11.117 5 10s.1-2.626.173-3.552a1.383 1.383 0 011.275-1.275z"
                />
            </g>
        </g>
    </svg>
);

export default CheckboxIcon;

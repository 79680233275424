import { createSlice } from "@reduxjs/toolkit";
import { MOBILE_WIDTH } from "../variables";

const initialState = {
    isMobile: window.innerWidth < MOBILE_WIDTH,
    isDesktopPermament: window.innerWidth > MOBILE_WIDTH,
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setMobile: (state, action) => {
            state.isMobile = action.payload;
        },
        setDesktopPermament: (state, action) => {
            state.isDesktopPermament = action.payload;
        },

    },
});

export const { setMobile, setDesktopPermament } = appSlice.actions;
export const selectMobile = (state) => state.app.isMobile;
export const selectDesktop = (state) => state.app.isDesktopPermament;

export default appSlice.reducer;

import React from 'react'
import { FaFacebookSquare } from 'react-icons/fa'
import styled from 'styled-components'

const StyledFacebookIcon = styled(FaFacebookSquare)`
    color: ${props => props.theme.facebookColor}
`

const FacebookIcon = (props) => {
    return (
        <StyledFacebookIcon {...props} />
    )
}

export default FacebookIcon

import React from 'react';
import Layout from '../../components/Layout/Layout';
import styled from 'styled-components';
import CompletedIcon from '../../components/Icons/components/CompletedIcon';



const Card = styled.div`
    background-color: ${props => !props.isMobile && props.theme.white};
    padding: 25px;
    border-radius: 25px;
    text-align: center;
`

const Header = styled.header`
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
`

const IconContainer = styled.div`
    font-size: 70px;
    color: ${({ theme }) => theme.primary};
    display: flex;
    justify-content: center;
    svg {
        display: block;
    }
`;


const UploadConfirmation = () => {
    return <div>
        <Layout showBasket={false} >
            <Card>
                <Header>
                    Pliki zostały pomyślnie przesłane
                </Header>
                <IconContainer>
                    <CompletedIcon />
                </IconContainer>
            </Card>
        </Layout>
    </div>;
};

export default UploadConfirmation;

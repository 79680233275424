import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Container from "../../components/Container/Container";
import Layout from "../../components/Layout/Layout";
import PlaceBox from "../../components/PlaceBox/PlaceBox";
import SearchBar from "../../components/SearchBar/SearchBar";
import { selectPickupPoints, setLocation } from "../../features/basketSlice";
import FlexBox from "../../components/FlexBox/FlexBox";
import Common from "../Common/Common";

const Title = styled.div`
    font-size: 1.4285714285714286em;
    margin: 15px 0 42px;
`;
const PlaceContainer = styled.div`
    margin-bottom: 10px;
    cursor: pointer;
`;

const Location = () => {
    const pickuPoints = useSelector(selectPickupPoints);

    const dispatch = useDispatch();
    const history = useHistory();

    const handleSetLocation = (location) => {
        dispatch(setLocation(location));
        history.goBack()
    };
    return (
        <Layout>
            <SearchBar />
            <Container>
                <FlexBox gap='1rem' textAlign="left" margin="2rem 0 0 0">
                    <div style={{ flex: 5 }}>
                        <Title>
                            <strong>Wybierz punkt odbioru sprzętu</strong>
                        </Title>
                        {pickuPoints.map((location, index) => (
                            <PlaceContainer key={index}>
                                <PlaceBox
                                    location={location}
                                    onClick={() => handleSetLocation(location)}
                                />
                            </PlaceContainer>
                        ))}
                    </div>
                    <div style={{ flex: 3 }}>
                        <Common showCalculator={false} />
                    </div>
                </FlexBox>
            </Container>
        </Layout>
    );
};

export default Location;

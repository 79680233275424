const Consumer = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="78" height="78.663"
        viewBox="0 0 78 78.663"
        stroke={props.color ? props.color : '#0486D6'}
        {...props}
    >
        <g id="Group_1339" data-name="Group 1339" transform="translate(-28.636 -33.522)">
            <rect id="Rectangle_842" data-name="Rectangle 842" width="74" height="33.255" transform="translate(30.636 76.931)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            <g id="Group_1337" data-name="Group 1337" transform="translate(35.698 35.522)">
                <circle id="Ellipse_387" data-name="Ellipse 387" cx="10.574" cy="10.574" r="10.574" transform="translate(4.589)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <path id="Path_1029" data-name="Path 1029" d="M67.03,76.041a15.162,15.162,0,0,0-30.324,0h0v5.082H67.03V76.041Z" transform="translate(-36.706 -39.731)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            </g>
            <g id="Group_1338" data-name="Group 1338" transform="translate(74.973 41.112)">
                <rect id="Rectangle_843" data-name="Rectangle 843" width="21.771" height="8.188" transform="translate(0 27.615)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <rect id="Rectangle_844" data-name="Rectangle 844" width="21.771" height="18.495" transform="translate(0)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
                <line id="Line_95" data-name="Line 95" y2="8.083" transform="translate(10.886 19.532)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" />
            </g>
        </g>
    </svg>

);

export default Consumer;
const SettingsIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 40 40"
        {...props}
    >
        <g data-name="Group 319">
            <g data-name="Group 317">
                <path
                    data-name="Path 124"
                    d="M20 2.373c.692 0 1.394.012 2.086.035l13.388.454h.08a1.584 1.584 0 011.582 1.582v.08l.454 13.388a61.77 61.77 0 010 4.173l-.454 13.388v.08a1.584 1.584 0 01-1.582 1.582h-.081l-13.268.453a61.5 61.5 0 01-4.17.001l-13.508-.454h-.08a1.584 1.584 0 01-1.587-1.577v-.08L2.406 22.09a61.77 61.77 0 010-4.173L2.86 4.529v-.08a1.584 1.584 0 011.582-1.582h.08l13.388-.454a63.095 63.095 0 012.086-.035m0-2.373q-1.084 0-2.167.037L4.441.496A3.955 3.955 0 00.486 4.451L.032 17.839q-.073 2.166 0 4.333L.486 35.56a3.955 3.955 0 003.955 3.955l13.508.454q1.075.036 2.15.036 1.092 0 2.184-.037l13.268-.453a3.955 3.955 0 003.955-3.955l.454-13.388q.074-2.166 0-4.333l-.454-13.388A3.955 3.955 0 0035.551.496L22.163.042Q21.08.005 19.996.005z"
                    fill="currentColor"
                />
            </g>
            <g data-name="Group 318" fill="currentColor">
                <path
                    data-name="Path 125"
                    d="M9.177 25.766V7.928a1.317 1.317 0 10-2.634 0v17.935a3.816 3.816 0 000 7.064v.024a1.314 1.314 0 002.626.076 3.817 3.817 0 00.008-7.26zM7.992 30.58a1.185 1.185 0 111.185-1.185 1.187 1.187 0 01-1.181 1.185z"
                />
                <path
                    data-name="Path 126"
                    d="M17.183 11.575V7.928a1.317 1.317 0 00-2.634 0v3.683a3.813 3.813 0 000 7.129v14.211a1.317 1.317 0 002.634 0V18.768a3.813 3.813 0 000-7.193zm-1.273 4.782a1.185 1.185 0 111.181-1.186 1.187 1.187 0 01-1.181 1.186z"
                />
                <path
                    data-name="Path 127"
                    d="M25.185 22.67V7.928a1.317 1.317 0 00-2.634 0v14.71a3.813 3.813 0 000 7.193v3.12a1.317 1.317 0 102.634 0v-3.152a3.813 3.813 0 000-7.129zm-1.361 4.75a1.185 1.185 0 111.185-1.185 1.187 1.187 0 01-1.185 1.184z"
                />
                <path
                    data-name="Path 128"
                    d="M35.822 14.645a3.824 3.824 0 00-2.634-3.629V7.928a1.317 1.317 0 00-2.634 0v3.183a3.816 3.816 0 000 7.064v14.776a1.317 1.317 0 102.634 0V18.273a3.824 3.824 0 002.634-3.628zm-3.819 1.185a1.185 1.185 0 111.185-1.185 1.187 1.187 0 01-1.186 1.185z"
                />
            </g>
        </g>
    </svg>
);

export default SettingsIcon;

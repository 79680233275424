
import FinancialDataNaturalPersonDesktop from "./FinancialDataNaturalPersonDesktop";

const FinancialData = ({ navProps }) => {
    return (
        <FinancialDataNaturalPersonDesktop
            navProps={navProps}
        />
    );
};

export default FinancialData;

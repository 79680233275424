const BasketIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 19.999 20"
        {...props}
    >
        <defs>
            <clipPath id="prefix__a">
                <path fill="none" d="M0 0h19.999v20H0z" />
            </clipPath>
        </defs>
        <g data-name="Repeat Grid 23">
            <g data-name="Group 938" clipPath="url(#prefix__a)">
                <g data-name="Group 369">
                    <path
                        data-name="Path 151"
                        d="M3.354 13.582h1.293l10.505.009h1.881a.819.819 0 00.787-.592l2.145-10.17a.822.822 0 00-.785-1.056L2.902 1.759 2.546.046.725-.003a.712.712 0 00-.034 1.424l.73.019L3.65 12.298h-.245a2.007 2.007 0 00-.053 3.988h1.259a2.509 2.509 0 00-.33 1.248 2.285 2.285 0 104.562 0 2.524 2.524 0 00-.328-1.249h4.032a2.519 2.519 0 00-.35 1.281 2.285 2.285 0 104.562 0 2.512 2.512 0 00-.347-1.282h1.668a.646.646 0 000-1.282l-14.7-.013a.716.716 0 01-.026-1.424zM18.513 3.199l-1.892 8.971-11.556-.011-1.866-8.973zM6.56 18.678a1.142 1.142 0 111.074-1.138 1.11 1.11 0 01-1.074 1.138zm7.916.041a1.141 1.141 0 111.075-1.139 1.109 1.109 0 01-1.074 1.136z"
                        fill="currentColor"
                    />
                </g>
                <g data-name="Group 370">
                    <path
                        data-name="Path 152"
                        d="M10.592 10.598a.694.694 0 01-.67-.713V5.47a.672.672 0 111.341 0v4.414a.692.692 0 01-.671.714z"
                        fill="currentColor"
                    />
                </g>
                <g data-name="Group 371">
                    <path
                        data-name="Path 153"
                        d="M7.127 10.604a.683.683 0 01-.657-.571L5.622 5.6a.713.713 0 01.524-.84.674.674 0 01.791.557l.848 4.433a.711.711 0 01-.524.839.619.619 0 01-.134.015z"
                        fill="currentColor"
                    />
                </g>
                <g data-name="Group 372">
                    <path
                        data-name="Path 154"
                        d="M14.06 10.61a.618.618 0 01-.134-.015.712.712 0 01-.523-.84l.855-4.432a.674.674 0 01.792-.555.713.713 0 01.523.841l-.855 4.432a.683.683 0 01-.658.569z"
                        fill="currentColor"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default BasketIcon;

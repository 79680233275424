import { useSelector } from "react-redux";
import { selectMobile } from "../../features/appSlice";
import ServiceDesktop from "./ServiceDesktop";
import ServiceMobile from "./ServiceMobile";

const ProductsCategories = ({ navProps }) => {
    const isMobile = useSelector(selectMobile);

    if (isMobile) {
        return <ServiceMobile navProps={navProps} />;
    }
    return <ServiceDesktop navProps={navProps} />;
};

export default ProductsCategories;

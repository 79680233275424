import BackArrowIcon from "./components/BackArrowIcon";
import BackIcon from "./components/BackIcon";
import NextIcon from "./components/NextIcon";
import BasketIcon from "./components/BasketIcon";
import CalendarIcon from "./components/CalendarIcon";
import CheckboxIcon from "./components/CheckboxIcon";
import CloseIcon from "./components/CloseIcon";
import CurrencyIcon from "./components/CurrencyIcon";
import _DoneIcon from "./components/DoneIcon";
import FilterIcon from "./components/FilterIcon";
import InfoIcon from "./components/InfoIcon";
import NextArrowIcon from "./components/NextArrowIcon";
import QuestionMarkIcon from "./components/QuestionMarkIcon";
import SearchIcon from "./components/SearchIcon";
import SortIcon from "./components/SortIcon";
import DownloadDocsIcon from "./components/DownloadDocsIcon";
import ClockIcon from "./components/ClockIcon";
import SettingsIcon from "./components/SettingsIcon";
import MapPinIcon from "./components/MapPinIcon";
import AlertIcon from "./components/AlertIcon";
import MessangerIcon from "./components/MessangerIcon";
import FacebookIcon from "./components/FacebookIcon";
import LinkedinIcon from "./components/LinkedinIcon";
import CheckedIcon from "./components/CheckedIcon";
import DownIcon from "./components/DownIcon";
import PlusIcon from "./components/PlusIcon";
import UploadIcon from "./components/UploadIcon";
import DiamondIcon from "./components/DiamondIcon";
import CompletedIcon from "./components/CompletedIcon";
import RefreshIcon from "./components/RefreshIcon";
import CardIcon from "./components/CardIcon";
import PaymentCardIcon from "./components/PaymentCardIcon";

export default {
    title: "G4R/components/Icons",
    argTypes: {
        width: { control: "number", default: 20 },
        height: { control: "number", default: 20 },
        color: { control: "color" },
    },
};

export const BackArrow = BackArrowIcon.bind({});
export const Back = BackIcon.bind({});
export const Next = NextIcon.bind({});
export const Basket = BasketIcon.bind({});
export const Calendar = CalendarIcon.bind({});
export const Close = CloseIcon.bind({});
export const Currency = CurrencyIcon.bind({});
export const Info = InfoIcon.bind({});
export const NextArrow = NextArrowIcon.bind({});
export const QuestionMark = QuestionMarkIcon.bind({});
export const Search = SearchIcon.bind({});
export const Sort = SortIcon.bind({});
export const Filter = FilterIcon.bind({});
export const Checkbox = CheckboxIcon.bind({});
export const DoneIcon = _DoneIcon.bind({});
export const DownloadDocs = DownloadDocsIcon.bind({});
export const Clock = ClockIcon.bind({});
export const Settings = SettingsIcon.bind({});
export const MapPin = MapPinIcon.bind({});
export const Alert = AlertIcon.bind({});
export const Messanger = MessangerIcon.bind({});
export const Facebook = FacebookIcon.bind({});
export const Linkedin = LinkedinIcon.bind({});
export const Checked = CheckedIcon.bind({});
export const Down = DownIcon.bind({});
export const Plus = PlusIcon.bind({});
export const Diamond = DiamondIcon.bind({});
export const Upload = UploadIcon.bind({});
export const Completed = CompletedIcon.bind({});
export const Refresh = RefreshIcon.bind({});
export const Card = CardIcon.bind({});
export const PaymentCard = PaymentCardIcon.bind({});

import { Link } from "react-router-dom";
import styled from "styled-components";
import { DESKTOP_CONTAINER_WIDTH, MOBILE_WIDTH } from "../../variables";
import BasketIcon from "../Icons/components/BasketIcon";
import QuestionMarkIcon from "../Icons/components/QuestionMarkIcon";
import logo from "./logo3.svg";
import RentNavigation from "../../views/RentSteps/components/RentNavigation";
import { selectMobile } from "../../features/appSlice";
import { useSelector } from "react-redux";

const Logo = styled.h1`
    position: relative;
    top: 4px;
    @media screen and (max-width: 500px) {
        img {
            width: 90px;
        }
    }
`;
const InfoContainer = styled.a`
    min-width: 140px;
    display: flex;
    height: fit-content;
    align-items: center;
    @media screen and (max-width: 500px) {
        min-width: initial;
        div {
            font-size: 0.85em;
        }
    }
`;
const InfoText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-left: 9px;
    font-size: 0.85em;
`;
const BasketContainer = styled.div`
    position: relative;
    margin-left: 30px;
    transition: ${(props) => (!!props.basketItems ? "all 0.4s" : "none")};
    opacity: ${(props) => (!!props.basketItems ? 1 : 0)};
    @media screen and (max-width: 500px) {
        svg {
            width: 25px;
            height: 25px;
        }
    }
`;
const BasketCount = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    background: ${({ theme }) => theme.primary};
    border-radius: 50%;
    color: ${({ theme }) => theme.white};
    font-size: 0.7em;
    position: absolute;
    top: -6px;
    right: -6px;
    @media screen and (max-width: 500px) {
        width: 14px;
        height: 14px;
    }
`;

const DesktopTitle = styled.div`
    display: none;
    @media screen and (min-width: ${MOBILE_WIDTH}px) {
        display: block;
    }
    h2 {
        font-size: 3.2142857142857144em;
        line-height: 59px;
    }
    h3 {
        font-size: 1.6428571428571428em;
        line-height: 30px;
        font-weight: 400;
    }
`;

const Root = styled.header`
    display: flex;
    position: sticky;
    top: 0;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px 18px;
    background: ${({ theme }) => theme.white};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin: 0 auto 26px;
    @media screen and (min-width: ${MOBILE_WIDTH}px) {
        background: transparent;
        position: static;
        box-shadow: none;
        max-width: ${DESKTOP_CONTAINER_WIDTH}px;
        img {
            width: 220px;
        }
        .info {
            display: none;
        }
        .basket {
            display: none;
        }
    } ;
`;

const RentLayoutContainer = styled.div`
    @media screen and (min-width: ${MOBILE_WIDTH}px) {
        display: flex;
        justify-content: flex-end;
        & > div {
            display: inline-flex;
            width: 380px;
            gap: 10px;
        }
    }
`;

const Header = ({ showBasket = true, basketItems = 1, desktopTitle }) => {
    const isMobile = useSelector(selectMobile);
    return (
    <Root>
        <DesktopTitle>
            <h2>Najem online</h2>
            <h3 id="mainTitle">{desktopTitle}</h3>
        </DesktopTitle>
        {!isMobile && (
            <RentLayoutContainer>
                <RentNavigation />
            </RentLayoutContainer>
        )}
        <div>
            <a href="https://vibe.pl/" target="_blank" rel="noopener noreferrer">
                <Logo>
                    <img src={logo} alt="vibe" />
                </Logo>
            </a>
        </div>
        <div className="info">
            <InfoContainer href="https://vibe.pl/jak-to-dziala/" target="_blank" rel="noopener noreferrer">
                <QuestionMarkIcon width={20} height={20} />
                <InfoText>
                    <div style={{ fontSize: "12px" }}>Wynajem online</div>
                    <div style={{ fontSize: "12px" }}>Jak to działa?</div>
                </InfoText>
            </InfoContainer>
        </div>
        <div className="basket">
            {showBasket && (
                <Link to="/basket">
                    <BasketContainer basketItems={basketItems}>
                        <BasketCount>{basketItems}</BasketCount>
                        <BasketIcon width={37} height={37} />
                    </BasketContainer>
                </Link>
            )}
        </div>
    </Root>)
}

export default Header;

const UploadIcon = (props) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 512 512"
        color={props.color}
        {...props}
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="file-import-solid" fill={props.color} fillRule="nonzero">
                <path d="M16,288 C7.2,288 0,295.2 0,304 L0,336 C0,344.8 7.2,352 16,352 L128,352 L128,288 L16,288 Z M505,105 L407.1,7 C402.6,2.5 396.5,0 390.1,0 L384,0 L384,128 L512,128 L512,121.9 C512,115.6 509.5,109.5 505,105 Z M352,136 L352,0 L152,0 C138.7,0 128,10.7 128,24 L128,288 L256,288 L256,222.8 C256,208.5 273.3,201.4 283.4,211.5 L379,308 C385.6,314.7 385.6,325.4 379,332 L283.3,428.4 C273.2,438.5 255.9,431.4 255.9,417.1 L255.9,352 L128,352 L128,488 C128,501.3 138.7,512 152,512 L488,512 C501.3,512 512,501.3 512,488 L512,160 L376,160 C362.8,160 352,149.2 352,136 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
);

export default UploadIcon;



import React from 'react'
import { FaLinkedin } from 'react-icons/fa'
import styled from 'styled-components'

const StyledLinkedinIcon = styled(FaLinkedin)`
    color: ${props => props.theme.linkedinColor}
`

const LinkedinIcon = (props) => {
    return (
        <StyledLinkedinIcon {...props} />
    )
}

export default LinkedinIcon

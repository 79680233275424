const RefreshIcon = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M19.654 2.896a2.767 2.767 0 00-2.55-2.549C15.252.203 12.235 0 10.004 0s-5.251.2-7.1.347A2.765 2.765 0 00.355 2.896C.211 4.748.007 7.765.007 9.996s.2 5.251.348 7.1a2.767 2.767 0 002.549 2.549c1.852.143 4.869.347 7.1.347s5.252-.2 7.1-.347a2.769 2.769 0 002.55-2.549c.143-1.853.346-4.87.346-7.1s-.203-5.248-.346-7.1zM18.12 16.985a1.23 1.23 0 01-1.134 1.134c-1.852.143-4.816.342-6.986.342s-5.133-.2-6.985-.342a1.228 1.228 0 01-1.133-1.134c-.144-1.852-.343-4.816-.343-6.985s.2-5.134.343-6.985a1.227 1.227 0 011.133-1.134c1.852-.144 4.816-.343 6.985-.343s5.134.2 6.986.343a1.229 1.229 0 011.134 1.134c.143 1.851.342 4.815.342 6.985s-.199 5.133-.342 6.989z"
      fill="currentColor"
    />
    <path
      d="M15.251 6.664a.713.713 0 10.269-1.4l-2.282-.437a.711.711 0 00-.834.834l.446 2.329a.712.712 0 00.7.579.7.7 0 00.135-.012.715.715 0 00.456-.3.678.678 0 00.109-.261 4.7 4.7 0 01-5.215 6.6.712.712 0 00-.828.5.671.671 0 00.061.549.717.717 0 00.478.345 6.126 6.126 0 006.377-9.356zM6.851 11.589a.712.712 0 00-.99.189.733.733 0 00-.105.236 4.737 4.737 0 01.922-5.338 4.685 4.685 0 014.252-1.282.711.711 0 00.811-.482.7.7 0 00-.057-.578.712.712 0 00-.476-.341 6.126 6.126 0 00-6.3 9.407l-.157-.03a.713.713 0 10-.268 1.4l2.279.436a.648.648 0 00.137.014.726.726 0 00.505-.208.713.713 0 00.2-.639l-.446-2.329a.711.711 0 00-.307-.455z"
      fill="currentColor"
    />
  </svg>
);

export default RefreshIcon;

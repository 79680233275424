import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Buttons/Button";
import Card from "../../components/Card/Card";
import CollapseIcon from "../../components/Collapse/CollapseIcon";
import BackArrowIcon from "../../components/Icons/components/BackArrowIcon";
import Layout from "../../components/Layout/Layout";
import ProductDetails from "../../components/Product/ProductTitle/ProductDetails";
import ProductTitle from "../../components/Product/ProductTitle/ProductTitle";
import ImageSwiper from "../../components/Swiper/ImageSwiper";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
    addAdditionalProduct,
    addService,
    selectMinimalMonthsParsed,
    selectShopUuid,
    selectMainProduct,
    selectIsCompany
} from "../../features/basketSlice";
import { useQuery } from "react-query";
import ProductService from "../../services/ProductsService";
import { useMemo } from "react";
import Loader from "../../components/Loader/Loader";
import DataRow from "../../components/DataRow/DataRow";
import CurrencyIcon from "../../components/Icons/components/CurrencyIcon";
import PeriodRow from "../../components/PeriodRow/PeriodRow";
import ProductCalculator from "../../components/ProductCalculator/ProductCalculator";

const DescriptionsContainer = styled.div`
    background-color: ${({ theme }) => theme.white};
    padding: 50px 20px;
    border-radius: 20px;
`;


const SmallText = styled.span`
    font-weight: 400;
    font-size: 14px;
`;

const FeaturesText = styled.span`
    font-size: 17px;
    img {
        max-width: 100%;
        height: auto;
    }
`;

const Product = () => {

    // -------------------------------------------------
    // ---------------- SOME HOOKS ---------------------
    // -------------------------------------------------

    const history = useHistory();
    const { category, product: id } = useParams();
    const dispatch = useDispatch();
    const shop_uuid = useSelector(selectShopUuid);
    const months = useSelector(selectMinimalMonthsParsed);
    const mainProduct = useSelector(selectMainProduct)
    const isCompany = useSelector(selectIsCompany);


    // ---------------------------------------------------------------
    // ---------------- GET PRODUCT DETAILS DATA ---------------------
    // ---------------------------------------------------------------

    const { data, isLoading, isSuccess } = useQuery(
        ["productDetails", id, shop_uuid, months, mainProduct.id],
        ProductService.getProductDetails(id, shop_uuid, months, mainProduct.id),
        {
            keepPreviousData: true
        }
    );


    // ---------------------------------------------------------------
    // PICK CALCULATION DATA DEPEND ON "COMPANY/NO CAMPANY SWITCH"
    // ---------------------------------------------------------------

    const calculationData = useMemo(() => {
        if (isSuccess && data) {
            return isCompany ? data.calculations.company_option.option_formatted : data.calculations.natural_person_option.option_formatted
        }
        else {
            return null
        }
        // eslint-disable-next-line 
    }, [isCompany, data, isSuccess])


    // ---------------------------------------------------------------
    // ---------------- BUTTONS HANDLERS -----------------------------
    // ---------------------------------------------------------------

    const addProduct = () => {
        if (category === 'accessories') {
            dispatch(addAdditionalProduct(data));
        }
        if (category === 'all_services') {
            dispatch(addService(data));
        }
        history.push("/basket");
    };

    const handeleBack = () => {
        history.goBack()
    }

    // ---------------------------------------------------------------
    // ---------------- CHECK IF LOADING VIEW ------------------------
    // ---------------------------------------------------------------

    if (isLoading) {
        return <Loader fixed />;
    }


    return (
        <Layout desktopTitle={category === "main" ? "Produkt główny" : 'Akcesoria i produkty dodatkowe'}>
            <ProductWrapper>
                <Card>
                    <ImageSwiper
                        images={[data.image_url, ...data.imageUrls]}
                    />
                </Card>
                <Card>
                    <ProductTitle
                        label={category === "main" ? "Produkt główny" : 'Akcesoria i produkty dodatkowe'}
                        name={data.name}
                        availabilityText={data.availabilityText}
                        availabilitySymbol={data.availabilitySymbol}
                    />
                    <ProductDetails
                        location={data.localizations[0].city}
                        condition={data.conditionText}
                        type={data.type}
                    />
                    <Box>
                        <PeriodRow />
                        <ProductCalculator data={calculationData} isLoading={isLoading} />
                        {
                            category !== 'main' && (
                                <Button
                                    onClick={addProduct}
                                    disabled={data?.availabilitySymbol === "OUT_OF_STOCK"}
                                >
                                    Dodaj do koszyka
                                </Button>
                            )
                        }
                    </Box>
                </Card>

                <DescriptionsContainer>
                    <CollapseIcon
                        openDefault={true}
                        label={
                            <span><FeaturesText>Opis</FeaturesText> <SmallText>(rozwiń)</SmallText></span>}
                        extraContent={
                            data.type === 'main' && data.formattedGrossPrice && parseInt(data.formattedGrossPrice) > 0 && (
                                <DataRow
                                    Icon={<CurrencyIcon />}
                                    label={<FeaturesText>{"Wartość sprzętu:"}</FeaturesText>}
                                    value={<FeaturesText>{`${data.formattedGrossPrice} zł brutto`}</FeaturesText>}
                                />
                            )
                        }
                        description={
                            <div style={{ fontSize: "17px", marginTop: '2em' }} dangerouslySetInnerHTML={{ __html: data.description }} />
                        }
                    />
                </DescriptionsContainer>
                <Buttons>
                    <Button onClick={handeleBack} buttonType='outline' LeftIcon={<BackArrowIcon />}>
                        Wstecz
                    </Button>
                    <Link to='/basket'>
                        <Button buttonType='outline'>
                            Powrót do koszyka
                        </Button>
                    </Link>
                </Buttons>
            </ProductWrapper>
        </Layout>
    );
};

export default Product;

const ProductWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

import styled from "styled-components";
import Container from "../../../components/Container/Container";
import Loader from "../../../components/Loader/Loader";
import { MOBILE_WIDTH } from "../../../variables";
import FooterNav from "./FooterNav";
const Root = styled.div`
    padding-bottom: 38px;
`;

const DesktopContainer = styled.div`
    @media screen and (min-width: ${MOBILE_WIDTH}px) {
        border-radius: 25px;
        background: white;
        margin: 46px -1.7857142857142858em 0;
        padding: 50px 70px;
    }
`;

const RentLayout = ({
    nextStep,
    prevStep,
    children,
    showNav = true,
    showLoader = false,
    style = {}
}) => {
    return (
        <Root style={style}>
            {showLoader && <Loader fixed />}
            <Container mainContainer>
                <DesktopContainer>{children}</DesktopContainer>
                {showNav && (
                    <FooterNav nextStep={nextStep} prevStep={prevStep} />
                )}
            </Container>
        </Root>
    );
};

export default RentLayout;

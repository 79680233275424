import React, { useMemo } from 'react'
import styled from 'styled-components'
import DiamondIcon from '../../../components/Icons/components/DiamondIcon'
import Badge2 from '../../../components/Badge/Badge2'
import FlexBox from '../../../components/FlexBox/FlexBox'
import { useSelector } from 'react-redux'
import { badgeStatus } from '../../../utils/functions'

import {
    selectIsCompany,
    selectMainProduct,
    selectMinimalMonthsParsed,
    selectShopUuid,
} from "../../../features/basketSlice"
import { useQuery } from 'react-query'
import ProductService from '../../../services/ProductsService'
import Spiner from '../../../components/Spiner/Spiner'
import { Link } from 'react-router-dom'


const MainProduct = () => {
    const mainProduct = useSelector(selectMainProduct);
    const shop_uuid = useSelector(selectShopUuid);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany)

    const { id, image_url, name, availabilitySymbol, availabilityText, conditionText } = mainProduct


    const { data: monthCost, isLoading: isLoadingMonthCost } = useQuery(
        ["product-calculation", mainProduct.id, shop_uuid, minimal_months, isCompany],
        ProductService.getProductCalculation(
            mainProduct.id,
            shop_uuid,
            minimal_months
        ),
        {
            select: (data) => isCompany ?
                data.calculations.company_option.option_formatted
                    .gross_month_cost : data.calculations.natural_person_option.option_formatted
                    .gross_month_cost,
            staleTime: 60 * 1000,
        }
    );

    const availabilityTextMapped = useMemo(() => {
        if (availabilitySymbol === "BACKORDER") {
            return "Na zamówienie";
        }
        return availabilityText;
    }, [availabilitySymbol, availabilityText]);


    return (
        <Link to={`products-collections/main/${id}`}>
            <StyledMainProduct>
                <Header>
                    <IconWrapper>
                        <DiamondIcon width={20} height={20} />
                    </IconWrapper>
                    <HeaderText>Główny przedmiot najmu</HeaderText>
                </Header>
                <Box>
                    <ImageWrapper>
                        <Image src={image_url} />
                    </ImageWrapper>
                    <Info>
                        <Name>{name}</Name>
                        <FlexBox gap='20px' alignItems='center'>
                            <Badge2 status={badgeStatus(availabilitySymbol)}>{availabilityTextMapped}</Badge2>
                            <Condition>{conditionText}</Condition>
                        </FlexBox>
                        <Price>
                            Rata miesięczna:
                            &nbsp;
                            <b>
                                {isLoadingMonthCost ? <Spiner /> : `${monthCost} zł`}
                            </b>
                        </Price>
                    </Info>
                </Box>
            </StyledMainProduct>
        </Link>
    )
}

export default MainProduct

const StyledMainProduct = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Box = styled.div`
    padding: 20px;
    border-radius: 20px;
    background-color: ${props => props.theme.white};
    display: flex;
    gap: 20px;
    flex-direction: column;
`

const ImageWrapper = styled.div`
    border-radius: 10px;
    display: flex;
    align-items: center;
    height: 280px;
`

const Image = styled.img`
    max-height: 100%;
    max-width: 100%;
    margin: 0 auto;

`

const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const Name = styled.div`
    font-size: 21px;
    font-weight: bold;
`

const Condition = styled.div`
    color: #798090;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
`
const Price = styled.div`
    font-size: 17px;
    min-height: 24px;
    display: flex;
    align-items: center;
    b {
        font-size: 21px;
    }
`

const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

`
const IconWrapper = styled.div`
    color: ${props => props.theme.primary};
    display: flex;
`
const HeaderText = styled.div`
    font-size: 17px;
    font-weight: bold;
`
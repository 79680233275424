const SortIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 19.253"
        {...props}
    >
        <path
            data-name="Path 141"
            d="M6.138 13.55L4.5 15.866V.75a.75.75 0 00-1.5 0V15.5l-1.65-2.2a.75.75 0 00-1.2.9l3.789 5.053 3.423-4.841a.75.75 0 10-1.224-.865z"
            fill="currentColor"
        />
        <path
            data-name="Path 142"
            d="M19.25 3h-9a.75.75 0 000 1.5h9a.75.75 0 000-1.5z"
            fill="currentColor"
        />
        <path
            data-name="Path 143"
            d="M17.25 8h-7a.75.75 0 000 1.5h7a.75.75 0 000-1.5z"
            fill="currentColor"
        />
        <path
            data-name="Path 144"
            d="M15.25 13h-5a.75.75 0 000 1.5h5a.75.75 0 000-1.5z"
            fill="currentColor"
        />
    </svg>
);

export default SortIcon;

import React from 'react'
import styled from 'styled-components'
import SearchIcon from '../Icons/components/SearchIcon'
import { debounce } from '../../utils/functions'

const Search = ({ setValue }) => {
    return (
        <SearchWrapper>
            <StyledSearch
                placeholder='Wyszukaj...'
                onChange={debounce(e => setValue(e.target.value))}
            />
            <SearchIcon width={20} height={20} />
        </SearchWrapper>
    )
}

export default Search

const SearchWrapper = styled.div`
    background-color: white;
    border-radius: 20px;
    padding: 14px 22px;
    display: flex;
`

const StyledSearch = styled.input`
    border: none;
    font-weight: bold;
    outline: none;
    font-size: 14px;
    letter-spacing: 0.025em;
    flex: 1;
    padding-right: 22px;

`
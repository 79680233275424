const CloseIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 30.002 30.002"
        {...props}
    >
        <path
            data-name="Path 86"
            d="M11.108 11.108a1.153 1.153 0 011.632 0l2.261 2.261 2.261-2.261a1.154 1.154 0 011.628 1.632l-2.261 2.261 2.261 2.26a1.154 1.154 0 01-1.629 1.629L15 16.629l-2.26 2.261a1.154 1.154 0 01-1.632-1.632l2.261-2.261-2.261-2.257a1.153 1.153 0 010-1.632z"
            fillRule="evenodd"
            fill="currentColor"
        />
        <path
            data-name="Path 87"
            d="M15 2.307c-3.254 0-7.7.3-10.478.515a1.842 1.842 0 00-1.7 1.7C2.607 7.3 2.307 11.747 2.307 15s.3 7.7.515 10.478a1.842 1.842 0 001.7 1.7c2.777.216 7.225.515 10.478.515s7.7-.3 10.478-.515a1.842 1.842 0 001.7-1.7c.216-2.777.515-7.225.515-10.478s-.3-7.7-.515-10.478a1.842 1.842 0 00-1.7-1.7C22.7 2.607 18.255 2.307 15 2.307zM4.344.521C7.122.306 11.648 0 15 0s7.879.306 10.657.521a4.15 4.15 0 013.824 3.824C29.7 7.122 30 11.648 30 15s-.306 7.879-.521 10.657a4.15 4.15 0 01-3.824 3.824C22.88 29.7 18.352 30 15 30s-7.878-.3-10.656-.518A4.15 4.15 0 01.52 25.658C.306 22.88 0 18.352 0 15S.306 7.121.521 4.343A4.15 4.15 0 014.344.521z"
            fillRule="evenodd"
            fill="currentColor"
        />
    </svg>
);

export default CloseIcon;

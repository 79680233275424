import styled from "styled-components";

const Root = styled.div`
    display: flex;
    align-items: center;
`;
const IconContainer = styled.div`
    display: flex;
    margin-right: 10px;
    color: ${({ theme }) => theme.primary};
    font-size: 20px;
`;
const Label = styled.div`
    
    /* margin-right: 1em; */
`;
const Value = styled.div`
    margin-left: 0.5em;
`;

const DataRow = ({ Icon, label, value }) => (
    <Root>
        {Icon && <IconContainer>{Icon}</IconContainer>}
        {label && (
            <Label>
                <strong>{label}</strong>
            </Label>
        )}
        {value && <Value>{value}</Value>}
    </Root>
);

export default DataRow;

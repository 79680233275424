import { useDispatch, useSelector } from "react-redux";
import PeriodInput from "../../components/Inputs/PeriodInput";
import {
    selectMinimalMonths,
    setMinimalMonths,
} from "../../features/basketSlice";

const MinimalMonthPeriod = () => {
    const dispatch = useDispatch();
    const period = useSelector(selectMinimalMonths);

    return (
        <PeriodInput
            value={period}
            onChange={(e) => dispatch(setMinimalMonths(e))}
        />
    );
};

export default MinimalMonthPeriod;

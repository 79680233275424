import { createGlobalStyle } from "styled-components";
import { MOBILE_WIDTH, DESKTOP_CONTAINER_WIDTH } from "./variables";

export const theme = {
    primary: "#FF6C0C",
    secondary: "#2A4DE3",
    success: "#4caf50",
    danger: "#d10032",
    black: "#000000",
    white: "#ffffff",
    grey: "#ABB4B4",
    darkGrey: "#909C9C",

    primaryGradient: "linear-gradient(to right, #FF6C0C, #FF9453)",
    secondaryGradient: "linear-gradient(to right, #2A4DE3, #00B8D2)",
    successGradient: "linear-gradient(to right, #4caf50, #66bb6a)",
    dangerGradient: "linear-gradient(to right, #A51824, #e32536)",

    mobileWidth: `${MOBILE_WIDTH}px`,
    facebookColor: '#1976D2',
    linkedinColor: '#0077B7',
    containerWidth: `${DESKTOP_CONTAINER_WIDTH}px`,
};

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    a {
        text-decoration: none;
        color: black;
    }
    body {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        background-color: #f7f7f7;
     
        
    }
    .grecaptcha-badge {
        display: none !important;
    }
`;

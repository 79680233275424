import styled from "styled-components";

const Header = styled.div`
    display: flex;
    align-items: center
    justify-content: space-between;
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
    margin-top: ${({ marginTop }) => marginTop}px;
    strong {
        font-size: 1.4285714285714286em;
        line-height: 20px;
        white-space: nowrap;
        margin-right: 20px;
        @media screen and (max-width: ${props => props.theme.containerWidth}) {
            white-space: normal ;
        }
    }
    
`;

const SectionHeader = ({ children, Icon, marginBottom = 35, marginTop = 0 }) => (
    <Header marginBottom={marginBottom} marginTop={marginTop}>
        <strong style={{ fontSize: 20 }}>{children}</strong>
        {Icon}
    </Header>
);

export default SectionHeader;

import React from "react";
import { AuthologicContext } from "./AuthologicIntegration";

// Custom hook to provide the context data
function useAuthologicContext(initObject) {
    const context = React.useContext(AuthologicContext);


    React.useEffect(() => {
        if(initObject) {
            context.setDefaultObject(initObject);
        }
        // eslint-disable-next-line
    }, []);

    if (!context) {
        throw new Error(
            "useAuthologicContext must be used within the scope of AuthologicContextProvider"
        );
    }

    return context;
}

export default useAuthologicContext;
import React from "react";
import Container from "../../components/Container/Container";
import Layout from "../../components/Layout/Layout";
import ErrorIcon from "../../components/Icons/components/ErrorIcon";
import styled from "styled-components";


const IconContainer = styled.div`
    font-size: 70px;
    color: ${({ theme }) => theme.primary};
    text-align: center;
    svg {
        display: block;
        margin: auto;
    }
`;


const Header = styled.header`
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom:20px;
`

const Card = styled.div`
    background-color: ${props => !props.isMobile && props.theme.white};
    padding: 25px;
    border-radius: 25px;
    text-align: center;
`

const LinkInactive = () => {

    return <Layout showBasket={false}>
        <Container>
            <Card>
                <Header>Link wygasł</Header>
                <IconContainer>

                    <ErrorIcon />
                </IconContainer>
            </Card>
        </Container>
    </Layout>
};

export default LinkInactive;

// import { useState } from "react";
// import { useSelector } from "react-redux";
// import { selectCustomerType } from "../../../features/transactionSlice";
// import FinancialDataLegalPerson from "./FinancialDataLegalPerson";
import FinancialDataNaturalPerson from "./FinancialDataNaturalPerson";
// import FinancialDataIndividualEntrepreneur from "./FinancialDataIndividualEntrepreneur";

const FinancialData = ({ navProps }) => {

    return (
        <FinancialDataNaturalPerson
            navProps={navProps}
        />
    );

    // if (customerType === "NATURAL_PERSON") {
    //     if (firstStep) {
    //         return (
    //             <FinancialDataNaturalPerson
    //                 navProps={navProps}
    //                 showNextStep={() => setNextStep(false)}
    //             />
    //         );
    //     } else {
    //         return (
    //             <FinancialDataLegalPerson
    //                 navProps={navProps}
    //                 // showNextStep={() => setNextStep(false)}
    //             />
    //         );
    //     }
    // } else {
    //     return <FinancialDataIndividualEntrepreneur navProps={navProps} />;
    // }
};

export default FinancialData;

const MessangerIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        {...props}
    >
        <g data-name="Group 951">
            <path
                data-name="Path 90"
                d="M10 1.539c-2.17 0-5.134.2-6.985.342a1.228 1.228 0 00-1.134 1.134C1.737 4.866 1.538 7.831 1.538 10s.2 5.134.343 6.985a1.228 1.228 0 001.134 1.134c1.851.144 4.815.343 6.985.343s5.133-.2 6.985-.343a1.229 1.229 0 001.134-1.134c.143-1.851.342-4.816.342-6.985s-.2-5.134-.342-6.985a1.229 1.229 0 00-1.134-1.134c-1.852-.143-4.816-.342-6.985-.342zM2.9.347C4.752.204 7.769 0 10 0s5.251.2 7.1.347a2.768 2.768 0 012.549 2.549c.143 1.852.347 4.87.347 7.1s-.2 5.252-.347 7.1a2.767 2.767 0 01-2.549 2.549c-1.853.144-4.87.347-7.1.347s-5.252-.2-7.1-.347a2.766 2.766 0 01-2.549-2.549c-.144-1.852-.347-4.869-.347-7.1s.2-5.252.347-7.1A2.767 2.767 0 012.896.347z"
                fill="currentColor"
                fillRule="evenodd"
            />
            <path
                d="M4.229 9.487a5.232 5.232 0 015.421-5.26 5.232 5.232 0 015.422 5.26 5.232 5.232 0 01-5.422 5.26 5.918 5.918 0 01-1.568-.208.434.434 0 00-.289.023l-1.08.474a.434.434 0 01-.61-.384l-.032-.967a.438.438 0 00-.142-.308 5.147 5.147 0 01-1.7-3.89zm3.759-.99l-1.591 2.53a.255.255 0 00.371.339l1.713-1.3a.319.319 0 01.393 0l1.265.949a.816.816 0 001.175-.217l1.591-2.53a.255.255 0 00-.376-.336L10.82 9.227a.312.312 0 01-.391 0L9.167 8.28a.813.813 0 00-1.179.217z"
                fill="currentColor"
            />
        </g>
    </svg>
);

export default MessangerIcon;
